import moment from 'moment';
import { organiceAddress } from '../../helpers/formCheckoutHelper';
import { calculateDiscountPriceShipping, organiceProductsMP } from '../../helpers/paymentHelper';
import { getPriceDiscountCouponSubscription } from '../../helpers/productHelper';
import { generatePaymentMP, shippingDiscountCoupon } from '../../interfaces/payment';
import { editSubscriptionUserForm, editUserSubscription, FormCreateSubscription, formDisabledSubscription, FormSelectSubscription, getOptionsDisabledSubscription, getSubscription, ShippingFrequencies, ShippingPrice, SubscriptionOrders, SubscriptionStripeResponse, subscriptionUserComplete, TaxData, TaxesResponse } from '../../interfaces/subscription';
import { Address, User } from '../../interfaces/user/user';
import api from '../axios';

interface ApiResponse<T> {
  data: T | null;
  error: string | null;
}
export class SubscriptionService {
  init() {}

  getSubscriptions = ({ country_id = 1 }: { country_id?: number }): Promise<getSubscription[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/subscriptions?country_id=${country_id}`)
        .then(response => {
          resolve(response.data.data.filter((sub: getSubscription) => sub.status === 1));
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  getFrequencies = ({ country_id = 1 }: { country_id?: number }): Promise <ShippingFrequencies[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/shipping-frequecies?country_id=${country_id}`)
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  getTaxesUsa = async (data: TaxData): Promise<ApiResponse<TaxesResponse>> => {
    const authorization = window.localStorage.getItem('token');
    const baseUrl = 'pixie-payments/api';
    const config = {
      headers: { Authorization: authorization ? authorization : '' }
    };

    try {
      const response = await api.post<TaxesResponse>(`${baseUrl}/users-subscriptions/stripe-tax`, data, config);
      return { data: response.data, error: null };
    } catch (error) {
      if (error instanceof Error)
        return { data: null, error: error.message || 'Error desconocido' };

      return { data: null, error: 'Error desconocido' };
    }
  };

  createCustomerMP = (authorization: string): Promise <string> =>
    new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-customers/api/mercado-pago/customers',
        headers: { Authorization: authorization } })
        .then(response => {
          resolve(response.data.customers.id);
        }).catch(error => {
          reject(error.response.data.error.message);
        }
        );
    });

  sendDataSubscription = (data: FormCreateSubscription): Promise <string> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-payments/api/users-subscriptions/pay-subscription', data,
        headers: { Authorization: authorization ? authorization : '' } })
        .then(response => {
          resolve(response.data.subscriptions.internal_id);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  sendDataSubscriptionStripe = async (data: FormCreateSubscription): Promise<ApiResponse<SubscriptionStripeResponse>> => {
    const authorization = window.localStorage.getItem('token');
    const baseUrl = 'pixie-payments/api';
    const config = {
      headers: { Authorization: authorization ? authorization : '' }
    };

    try {
      const response = await api.post<SubscriptionStripeResponse>(`${baseUrl}/users-subscriptions/charge-subscription`, data, config);
      return { data: response.data, error: null };
    } catch (error) {
      if (error instanceof Error)
        return { data: null, error: error.message || 'Error desconocido' };

      return { data: null, error: 'Error desconocido' };
    }
  };

  getDatesShipping = (data: FormSelectSubscription, cityId: string): Promise<number> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-payments/api/users-subscriptions/delivery-dates', data: {
        subscriptions_id: data.subscriptionSelected.id,
        first_shipping_day: data.first_shipping_day,
        shipping_frequencies_id: data.shippingType.id,
        city_id: cityId
      },
      headers: { Authorization: authorization ? authorization : '' } })
        .then(response => {
          resolve(response.data.dates.length);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getDatesShippingSubscription = (data: editSubscriptionUserForm): Promise<number> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-payments/api/users-subscriptions/delivery-dates', data: {
        subscriptions_id: data.subscriptions_id,
        first_shipping_day: data.first_shipping_day,
        shipping_frequencies_id: data.shipping_frequencies_id
      },
      headers: { Authorization: authorization ? authorization : '' } })
        .then(response => {
          resolve(response.data.dates.length);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getMinShippingPrice = (country: 'col' | 'usa' | 'pan' | 'mex'): Promise<ShippingPrice> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/shipping-prices?key=${country}`)
        .then(response => {
          resolve(response.data[0]);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  getMySubscriptions = (): Promise <subscriptionUserComplete[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api({ method: 'get', url: 'pixie-payments/api/users-subscriptions/mine?limit=40',
        headers: { Authorization: authorization ? authorization : '' } })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getordersBySubscription = (idSubscription: string): Promise <SubscriptionOrders[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/users-subscriptions-purchases/users-subscriptions/${idSubscription}`, {
        headers: {
          Authorization: authorization ? authorization : ''
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  };

  editSubscriptionUserinformation = (id: string, form: editUserSubscription, dataSubscriptionOld: subscriptionUserComplete): Promise<subscriptionUserComplete> => {
    const authorization = window.localStorage.getItem('token');
    const { general_order: orderDetail } = dataSubscriptionOld;
    const orderDetailnew : generatePaymentMP = {
      ...orderDetail,
      metadata: {
        ...orderDetail.metadata,
        shippingDetails: {
          ...orderDetail.metadata.shippingDetails,
          address1: organiceAddress(form),
          city: form.city.label,
          cityValue: form.city.value,
          region: form.state.label,
          regionvalue: form.state.value,
        }
      },
    };

    const userNew: User = {
      ...dataSubscriptionOld.user_information,
      name: form.name,
      last_name: form.last_name,
      email: form.email,
      phone: form.phone
    };

    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-payments/api/users-subscriptions/mine/' + id,
        headers: { Authorization: authorization ? authorization : '' },
        data: {
          user_information: userNew,
          general_order: orderDetailnew
        } })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    }
    );
  };

  getAddressSavedUser = (idUser: string): Promise<Address[]> => {
    const authorization = window.localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-customers/api/addresses/users/${idUser}`, {
        headers: {
          Authorization: authorization ? authorization : ''
        }
      })
        .then(res => {
          resolve(res.data.address);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  };

  editSubscriptionData = (idSubscription: string, form: editSubscriptionUserForm, dataSubscriptionOld: subscriptionUserComplete):Promise <subscriptionUserComplete> => {
    let valueDiscountShipping: shippingDiscountCoupon = {
      finalPrice: 0,
      originalValueShipping: 0,
      percentDiscount: 0,
      valueDiscount: 0
    };
    if (form?.coupon_detail?.feature)
      valueDiscountShipping = calculateDiscountPriceShipping(form.coupon_detail.feature, dataSubscriptionOld.general_order.metadata.details_payments.purchaseShippingRef || 0);
    let priceDiscountCoupon = 0;
    if (form.coupon_detail)
      priceDiscountCoupon = getPriceDiscountCouponSubscription(form.products, form.subscriptions.percen_discount, form.coupon_detail, valueDiscountShipping);
    const authorization = window.localStorage.getItem('token');
    const { general_order: orderDetail } = dataSubscriptionOld;
    const dataOrderDetail: generatePaymentMP = {
      ...orderDetail,
      metadata: {
        ...orderDetail.metadata,
        details_payments: form.details_payments,
        products: form.products,
        couponId: form.couponId,
      },
      items: organiceProductsMP(form.products, form.shippingType, form.coupon_detail, undefined, priceDiscountCoupon, valueDiscountShipping),
      total_amount: form.transaction_amount,
      shipments: {
        ...orderDetail.shipments,
        cost: form.shippingType.price,
        mode: form.shippingType.type,
      },
    };

    const newData = {
      subscriptions_id: form.subscriptions_id,
      shipping_frequencies_id: form.shipping_frequencies_id,
      coupon_detail: form.coupon_detail ? form.coupon_detail : null,
      transaction_amount: form.transaction_amount,
      due_date: moment(form.dueDate).format('YYYY-MM-DD'),
    };
    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-payments/api/users-subscriptions/mine/' + idSubscription,
        headers: { Authorization: authorization ? authorization : '' },
        data: {
          ...newData,
          general_order: dataOrderDetail
        } })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  editStatusSubscriptionUser = (idSubscription: string, status: 1 | 0):Promise <subscriptionUserComplete> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-payments/api/users-subscriptions/mine/' + idSubscription,
        headers: { Authorization: authorization ? authorization : '' },
        data: {
          status,
          is_cancelled: status === 1 ? 0 : 1,
          date_cancelled: status === 1 ? null : moment().format('YYYY-MM-DD')
        } })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  editDateOrden = (order: SubscriptionOrders, date: string): Promise<any> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.put(`pixie-payments/api/users-subscriptions-purchases/mine/${order.id}`, {
        delivery_date: date
      }, {
        headers: {
          Authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  editDueDateSubscriptionUser = (idSubscription: string, dueDate: string):Promise <subscriptionUserComplete> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-payments/api/users-subscriptions/mine/' + idSubscription,
        headers: { Authorization: authorization ? authorization : '' },
        data: {
          due_date: dueDate,
        } })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  getOptionsDisabledSubscription = (): Promise<getOptionsDisabledSubscription[]> =>
    new Promise((resolve, reject) => {
      api.get('pixie-payments/api/subscription-disabled-types')
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data);
        }
        );
    }
    );

  sendSubscriptionDisabled = (form: formDisabledSubscription) => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-payments/api/subscription-disabled',
        headers: { Authorization: authorization ? authorization : '' },
        data: {
          ...form
        } })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };
}

export default new SubscriptionService();

