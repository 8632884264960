import { useTranslation } from 'react-i18next';
import { imageLogin, logo } from '../../../assets/images';

interface Props {
  classModal?: boolean;
}

const ImageLoginSection = ({ classModal }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`w-full flex flex-col justify-center items-center bg-white ${classModal ? 'h-full mt-0' : 'mt-16'}`}>
      <img src={imageLogin} alt={imageLogin}/>
      <h2 className='text-pixieLightBlue lg2:text-[35px] lg:text-[30px] lg:px-14 lg2:px-28'>
        {t('LoginImagenText')}
      </h2>
      <img src={logo} className='w-20 h-20 mt-4'/>
    </div>
  );
};

export default ImageLoginSection;
