/* eslint-disable max-params */
import { CartItem } from '../../interfaces/basket';
import { postSendFormCheckout, SubmissionFormInterface } from '../../interfaces/checkout';
import { SendCouponData, couponComplete, couponHistory } from '../../interfaces/coupon';
import { City, Country, State, StateUsaResponse, ZipCode } from '../../interfaces/location';
import api from '../axios';

export class CheckOutService {
  init() {}

  getOneCountry = async (id: number): Promise<Country> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/countries/${id}`)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getCountries = ():Promise<Country[]> =>
    new Promise((resolve, reject) => {
      api.get('pixie-payments/api/countries')
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getStatesUsa = async (): Promise<StateUsaResponse> =>
    new Promise((resolve, reject) => {
      api.get('pixie-payments/api/states/us-states')
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getStatesByCountry = (idCountry: string):Promise<State[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/states/${idCountry}?limit=999999`)
        .then(response => {
          resolve(response.data.states.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getCitiesByState = (idState: string):Promise<City[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/cities/${idState}?limit=999999`)
        .then(response => {
          resolve(response.data.cities.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getCitiesByCountry = (idCountry: string):Promise<City[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/cities/coutry/${idCountry}?limit=999999
      `)
        .then(response => {
          resolve(response.data.cities.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getDelegationByCity = (idCity: string):Promise<City[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/delegations/${idCity}?limit=999999
      `)
        .then(response => {
          resolve(response.data.cities.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getZipCodeByCity = (idCity: string):Promise<string[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/cities-zip-codes/city_id/${idCity}?limit=999999`)
        .then(response => {
          const zipCodes = response.data.cities_zip_codes.data.map(({ code }: { code: string }) => code);
          resolve(zipCodes);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getAllZipCodeByCity = (idCity: string):Promise<ZipCode[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/cities-zip-codes/city_id/${idCity}?limit=999999`)
        .then(response => {
          const zipCodes = response.data.cities_zip_codes.data;
          resolve(zipCodes);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  getAllZipCodeByDelegation = (idDelegation: string):Promise<ZipCode[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/delegation-zip-codes/delegation_id/${idDelegation}?limit=999999`)
        .then(response => {
          const zipCodes = response.data.delegations_zip_codes.data;
          resolve(zipCodes);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });

  sendUserInformation = async (data: SubmissionFormInterface): Promise<postSendFormCheckout> => {
    const defError = 'Error al enviar los datos del checkout.';
    try {
      const response = await api.post('pixie-customers/api/customers', {
        ...data,
        apartment: data.apartment === '' ? data.houseNumber : data.apartment,
        address: data.address.concat(` ${data.houseNumber}`),
        countries_id: data.country.value,
        province: data.state.label,
        city: data.city.value,
        zip_code: data.zip_code.value
      });

      // Handle empty response
      if (!response) throw new Error(defError);

      return { data: response.data };
    } catch (error: any) {
      // Handle response errors
      if (error.response.data.errors) return { error: error.response.data.errors, data: { id: '' } };

      // Handle basic errors
      return { error: [{ msg: (error as Error)?.message || defError, param: 'global', location: 'global' }], data: { id: '' } };
    }
  };

  getCoupon = (claimCode: string, location: string): Promise<couponComplete> => {
    const authorization = window.localStorage.getItem('token');
    const JSONCountry = {
      MEX: 1,
      USA: 2,
      COL: 1
    };

    return new Promise((resolve, reject) => {
      api.post(`pixie-coupons/api/coupons/code/${claimCode}`, {
        location: JSONCountry[location as 'MEX' | 'USA']
      }, {
        headers: {
          Authorization: authorization ? authorization : ''
        }
      }).then(res => {
        resolve(res.data.response);
      }).catch(err => {
        reject(err.response.data);
      });
    });
  };

  getCouponComplete = (claimCode: string, location: string, purchaseType: 'normal' | 'subscription', email: string, products: CartItem[]): Promise<couponComplete> => {
    const authorization = window.localStorage.getItem('token');
    const JSONCountry = {
      MEX: 1,
      USA: 2,
      COL: 1
    };

    const data: SendCouponData = {
      claimCode,
      email,
      location: JSONCountry[location as 'MEX' | 'USA'],
      purchaseType,
      order: {
        items: products.map(car => ({
          id: car.product.id,
          quantity: car.quantity,
          price: car.quantity * car.product.price
        }))
      }
    };

    return new Promise((resolve, reject) => {
      api.post('/pixie-coupons/api/coupons/apply', {
        ...data
      }, {
        headers: {
          Authorization: authorization ? authorization : ''
        }
      }).then(res => {
        resolve(res.data.response);
      }).catch(err => {
        reject(err.response.data.response);
      });
    });
  };

  getCouponHistory = (userEmail: string, couponInfo:couponComplete | undefined): Promise<couponHistory[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-coupons/api/history_coupons?email=${userEmail}&coupon=${couponInfo?.id}`).then(res => {
        resolve(res.data.response.data);
      }).catch(err => {
        reject(err.response.data.message);
      });
    });
}

export default new CheckOutService();

