import useForm from '../../../hooks/useForm';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import TextField from '../../../components/form/textField';
import Button from '../../../components/common/button';

import { recoveryPassword, recoveryPasswordValidate } from '../../../interfaces/auth';

import useValidator from '../../../hooks/useValidator';
import { useAppContext } from '../../../hooks';
import authService from '../../../services/authService';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

const FormRecovery = ({ setView }: FormRecoveryProps) => {
  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showPopupAuth, updateContext, toast } = useAppContext();
  const { i18n: { language } } = useTranslation();

  const handleChangeView = () => {
    if (showPopupAuth.status)
      updateContext(old => ({ ...old, showPopupAuth: { status: old.showPopupAuth.status, view: 1 } }));
    else
      navigate('/autenticacion/ingresar');
  };

  const { form, handleFormChange, onSubmit } = useForm<recoveryPassword>(
    {
      email: '',
    },
    () => validateForm()
  );

  const { handlePutMessageError, validatorBody, resetValidator } = useValidator<recoveryPasswordValidate>({
    email: { message: '' },
  });

  // Methods
  const validateForm = () => {
    // Clear all errors
    resetValidator();
    let error = false;
    if (!validator.isEmail(form.email)) {
      handlePutMessageError('email', 'El texto debe ser un email');
      error = true;
    }

    if (!error)
      handleSubmit(form);
  };

  // Send data to LoginSlice
  const handleSubmit = async (form: recoveryPassword) => {
    await authService.recoveryPassword(form, language)
      .then(() => {
        setView(2);
        toast.fire({
          icon: 'success',
          title: 'Se ha enviado un correo con los pasos a seguir.',
        });
      })
      .catch(() => {
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: 'Correo no encontrado.',
        });
      });
  };

  return (
    <form
      onSubmit={onSubmit}
      className='w-full flex flex-col lg:gap-5 text-sm lg:pr-6 z-10 font-subTitles justify-between items-center'
    >
      <div className='mb-5 lg:mb-8 mt-20 flex flex-col gap-3 lg:pr-10'>
        <h2 className='text-xl font-titles text-primary lg:text-left lg:text-[25px] '>{t('recoveryTitle')}</h2>
        <p className='text-sm text-center leading-tight pt-3 lg:text-lg lg:text-left'>{t('recoveryDescription')}</p>
      </div>
      <TextField
        name='email'
        value={form.email}
        handler={handleFormChange}
        placeholder={t('registeEmail')}
        className='w-full'
        messageError={validatorBody.email.message}
        fieldClassName='py-[0.95rem] px-7 rounded-[16px]'
      />
      <div className='mt-6 flex flex-col items-center justify-center lg:mt-5 gap-5 lg:gap-10'>
        <Button
          type='submit'
          className='bg-primary text-[#fad7b1] px-[3.5rem] lg:py-4 rounded-xl font-sanzBold text-base'
          padding='py-2 lg:py-[0.7rem]'>
          {t('recoverySend')}
        </Button>
        <span
          className='cursor-pointer border-b border-b-gray-900'
          onClick={() => handleChangeView()}
        >
          {t('recoveryReturn')}
        </span>
      </div>
    </form>
  );
};

interface FormRecoveryProps {
  setView: Dispatch<SetStateAction<number>>
}

export default FormRecovery;
