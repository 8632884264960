/* eslint-disable no-await-in-loop */
import { exercises, types } from '../../../../../@fake/calculatorFake';
import Carrousel from '../../../../../components/common/carrousel';
import TextField from '../../../../../components/form/textField';
import { useAppContext } from '../../../../../hooks';
import { Appointment, PetAppointment } from '../../../../../interfaces/appointment';
import Icon from '../../../../../components/common/icon';
import ProductCard from '../../../../../components/common/productCard';
import moment from 'moment';
import CheckField from '../../../../../components/form/checkField';

const PetAppointmentInfo = ({ appointment, isOnePet = false, petData }: PetAppointmentInfoProps) => {
  const { showMinimalNavbar } = useAppContext();

  return (
    <div className={`flex flex-col py-6 gap-4  ${isOnePet ? 'px-0' : 'lg:px-12 px-3'}`}>
      <h3 className='text-left text-pixieLightBlue font-titles text-lg md:text-xl'>
        Datos de la mascota
      </h3>
      <div className='flex flex-col gap-[10px] lg:grid lg:grid-cols-2 lg:gap-5'>
        <TextField
          handler={() => {}}
          name='namePet'
          value={petData.name}
          placeholder='Nombre'
          fieldClassName='py-3 pl-6 '
          border='ring-1 ring-pixieLightBlue rounded-[16px]'
        />
        <TextField
          handler={() => {}}
          name='petAge'
          value={`${moment().diff(petData.date_birth, 'years')} Años`}
          placeholder='Edad'
          fieldClassName='py-3 pl-6 '
          border='ring-1 ring-pixieLightBlue rounded-[16px]'
        />
        <TextField
          handler={() => {}}
          name='type'
          value={types.find(type => type.value.toLocaleLowerCase() === petData.type.toLocaleLowerCase())?.label || ''}
          placeholder='Macota: Perro o gato'
          fieldClassName='py-3 pl-6 '
          border='ring-1 ring-pixieLightBlue rounded-[16px]'
        />
        <TextField
          handler={() => {}}
          name='petWeight'
          value={petData.weight}
          placeholder='Peso'
          fieldClassName='py-3 pl-6 '
          border='ring-1 ring-pixieLightBlue rounded-[16px]'
        />
        <TextField
          handler={() => {}}
          name='petActivity'
          value={exercises.find(exercise => exercise.value === petData.physical_activity)?.label || ''}
          placeholder='Actividad física'
          fieldClassName='py-3 pl-6 '
          border='ring-1 ring-pixieLightBlue rounded-[16px]'
        />
        <TextField
          handler={() => {}}
          name='food'
          value={petData.food}
          placeholder='Alimentación actual'
          fieldClassName='py-3 pl-6 '
          border='ring-1 ring-pixieLightBlue rounded-[16px]'
        />
      </div>

      {petData.have_diseases && (
        <div className='flex gap-4 items-start mt-4'>
          <div className=' pl-4'>
            <p>Enfermedades o alergias: </p>
          </div>
          <div className='grid gap-7 grid-cols-3'>
            <div className='flex flex-col w-1/2 gap-2 lg:w-auto'>
              <CheckField
                onClick={() => {}}
                label={'Hepática'}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1'
                labelClassName='text-xs lg:text-sm'
                checked={petData.diseases.includes('hepatics')}
              />
              <CheckField
                onClick={() => {}}
                label={'Renal'}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 '
                labelClassName='text-xs lg:text-sm'
                checked={petData.diseases.includes('renal')}
              />
              <CheckField
                onClick={() => {}}
                label={'Obesidad'}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1'
                labelClassName='text-xs lg:text-sm'
                checked={petData.diseases.includes('obesity')}
              />
            </div>
            <div className='flex flex-col w-1/2 gap-2 lg:w-auto'>
              <CheckField
                onClick={() => {}}
                label={'Alergia'}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 ml-1 lg:ml-5'
                labelClassName='text-xs lg:text-sm'
                checked={petData.diseases.includes('alergies')}
              />
              <CheckField
                onClick={() => {}}
                label={'Estómago sensible'}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 ml-1 lg:ml-5'
                labelClassName='text-xs lg:text-sm'
                checked={petData.diseases.includes('sensitive_stomach')}
              />
              <CheckField
                onClick={() => {}}
                label={'Otras'}
                border='border border-pixieLightBlue rounded-[0px]'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                className='mt-1 ml-1 lg:ml-5'
                labelClassName='text-xs lg:text-sm'
                checked={petData.diseases.includes('other')}
              />
            </div>
          </div>
        </div>
      )}

      <InfoItem
        description={petData.note}
        title='Información adicional'
      />

      <h3 className='text-left text-pixieLightBlue font-titles text-lg md:text-xl lg:mt-3'>
        Recomendaciones
      </h3>

      <InfoItem
        description={petData.recomendations || 'No hay recomendaciones'}
        title='Recomendaciones'
      />

      <InfoItem
        description={petData.predefinedNote?.value || 'No hay notas predefinidas'}
        title={petData.predefinedNote?.label || 'Notas predefinidas'}
      />

      {(petData.products && petData.products.length > 0) && (
        <>
          <h3 className='text-left text-pixieLightBlue font-titles text-lg md:text-xl mt-3 lg:mt-8'>
            Dieta
          </h3>

          <div className='relative lg:px-20'>
            {/* Carrousel */}
            <Carrousel
              className='pb-10 h-max max-w-[1440px] font-titles'
              navigation={showMinimalNavbar}
              classNameArrow='filter-blue-pixie'
              breakpoints={{
                300: {
                  slidesPerView: 2.1,
                  initialSlide: 1
                },
                460: {
                  slidesPerView: 2.1,
                  initialSlide: 1,
                },
                580: {
                  slidesPerView: 2.2,
                  initialSlide: 1,
                },
                720: {
                  slidesPerView: 3,
                  initialSlide: 1,
                },
                800: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                  initialSlide: 1,
                },
                900: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                  initialSlide: 1,
                },
                1020: {
                  slidesPerView: 1.5,
                  spaceBetween: 5,
                  initialSlide: 1,
                },
                1200: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                  initialSlide: 1,
                },
                1280: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                  initialSlide: 1,
                },
                1366: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                  initialSlide: 1,
                },
              }}
            >
              {petData.productsComplete?.map((product, index) => (
                <div key={`${index}-product.product.id`} className='scale-75 lg2:scale-100 my-4'>
                  <ProductCard
                    product={product.product}
                  />
                </div>
              ))}
            </Carrousel>
          </div>

        </>
      )}
      <h3 className='text-left text-pixieLightBlue font-titles text-lg md:text-xl'>
        Documentos
      </h3>

      <div className='flex flex-col gap-1 lg:w-1/2'>
        {appointment.medical_exams.files.map((file, index) => (
          <FileItem key={index} url={file} />
        ))}
      </div>

    </div>
  );
};

const FileItem = ({ url }: FileItemProps) => {
  const urlSplit = url.split('/');
  return (
    <div
      className='text-sm flex items-center border cursor-pointer rounded-[10px] bg-white border-pixieLightBlue px-1 lg:p-1 '
      onClick={() => window.open(`${process.env.REACT_APP_URL_S3}${url}`, '_blank')}
    >
      <div className='flex-1 truncate'>
        <div className='flex items-center gap-4 font-sanzSemiBold text-xs lg:text-sm'>
          <span>
            <Icon
              name='attach_file'
              className='text-grayText'
              size='sm'
            />
          </span>
          {urlSplit[urlSplit.length - 1]}
        </div>
      </div>
    </div>
  );
};

interface FileItemProps {
  url: string;
}

const InfoItem = ({ description, title }: InfoItemProps) => (
  <div className='flex flex-col border text-sm border-pixieLightBlue py-4 px-5 rounded-[20px] text-[#616161] gap-4 lg:py-5'>
    <p className='font-sanzBold'>{title}</p>
    <p className='text-xs'>{description}</p>
  </div>
);

interface InfoItemProps {
  title: string;
  description: string;
}

interface PetAppointmentInfoProps {
  appointment: Appointment;
  isOnePet?: boolean
  petData: PetAppointment
}

export default PetAppointmentInfo;
