import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/common/button';
import Icon from '../../../components/common/icon';
import { useAppContext } from '../../../hooks';

const EndSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showPopupAuth, updateContext } = useAppContext();

  const handleChangeView = () => {
    const isUnDesktop = window.innerWidth > 800;
    if (isUnDesktop)
      updateContext(old => ({ ...old,
        showPopupAuthWholesaler: { status: false, view: 1 },
        showPopupAuth: { status: true, view: 1 }
      }));
    else
      navigate('/autenticacion/ingresar');
  };

  return (
    <div className='font-sanzSemiBold text-xs w-full flex flex-row justify-between px-4'>
      <span className='text-sm lg:text-lg my-auto'>{t('loginNotWholesaler') }</span>
      <Button className='bg-pixieLightBlue text-white flex gap-3 rounded-[16px] shadow-xl lg:rounded-[20px] h-10' onClick={() => handleChangeView()}>
        <Icon
          name='face'
          type='round'
          className='first-line:mr-4 md:mr-0 text-white cursor-pointer'
          size='3xl'
        />
        <span className='text-lg'>{t('loginHere')}</span>
      </Button>
    </div>
  );
};

export default EndSection;
