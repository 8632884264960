import { useNavigate } from 'react-router-dom';

import IconButton from '../../../components/common/iconButton';
import { useAppContext } from '../../../hooks';

const HeaderSection = () => {
  const navigate = useNavigate();
  const { updateContext } = useAppContext();

  const handleCloseAuth = () => {
    updateContext(old => ({ ...old, showPopupAuth: { status: false, view: 1 }, showNavbar: true }));
  };

  return (
    <div className='w-full flex flex-col gap-3 mt-3 absolute'>
      <div className='flex justify-start lg:justify-end lg:mr-5 z-20'>
        <IconButton.mini
          name='chevron_left'
          onClick={() => navigate(-1)}
          className='text-primary lg:hidden '
          size='3xl'
        />
        <IconButton.mini
          name='close'
          onClick={handleCloseAuth}
          className='hidden lg:block text-black'
          size='2xl'
        />
      </div>
    </div>
  );
};

export default HeaderSection;
