export const subscriptions = {
  subTitleOne: 'Such a real',
  subTitleTwo: 'love',
  subTitleThree: 'deserves',
  subTitleFour: 'real',
  subTitleFive: 'food',
  subSubtitle: 'Crafted meticulously by veterinary doctors and seasoned animal nutritionists, each bite is a mix of fresh, natural ingredients. Because our companions deserve the best, it\'s time to ponder what we truly offer them.',
  subTitleForSubscribe: 'Subscribing is easy',

  subTitleDurationOne: 'Choose Your',
  subTitleDurationTwo: 'Duration',
  subTextStepDurationOne: 'We offer plans with',
  subTextStepDurationTwo: 'monthly debit (5% discount), bimonthly (discount to be determined), quarterly (8% discount), and semi-annually (10% discount),',
  subTextStepDurationThree: 'so you can pick the one that best suits your budget.',

  subTitleFrequencyOne: 'Select Your Delivery',
  subTitleFrequencyTwo: 'Frequency',
  subTitleFrequencyThree: '',
  subTextFrequencyOne: 'Tailor your deliveries to your preference and freezer space – choose from',
  subTextFrequencyTwo: 'weekly, biweekly, monthly, or bimonthly',
  subTextFrequencyThree: 'options',

  subTitleQuantityOne: 'Determine',
  subTitleQuantityTwo: 'Quantity',
  subTextQuantityOne: 'Based on your furry friend\'s age, weight and medical condition, ',
  subTextQuantityTwo: 'select the products you\'d like',
  subTextQuantityThree: 'to receive with each delivery',

  subTextBotonSubscribe: 'I want to subscribe',
  subTitleOptionSubscribe: '... Through:',
  subTextOptionSubscribeCalculator: 'Calculator',
  subTextOptionSubscribeBasket: 'Basket',

  subTitleBenefits: 'What Are the Benefits of Subscribing?',
  subTextBenefitOne: 'Discounts according to the term you choose',
  subTitleBenefitsConditions: '*Terms and conditions apply',

  subTitleQuestions: 'Got Questions About Your Subscription?',
  subSubtitleQuestions: 'Explore these Frequently Asked Questions: Perhaps these commonly asked questions can provide some clarity. If you still have inquiries, don\'t hesitate to',
  subContactUs: 'reach out to us',

  subResumenShippingContact: 'Contact',
  subResumenShippingDelivery: 'Send to',
  subResumenShippingChange: 'Change',

  subCheckoutTitle: 'Payment',
  subCheckoutSubtitle: 'All transactions are secure and encrypted',
  subCheckoutFieldName: 'Owner\'s name*',
  subCheckoutFieldNUmber: 'Card number*',
  subCheckoutFieldDate: 'Expiration date MM/YY*',
  subCheckoutFieldCVV: 'Security code*',
  subCheckourFieldDocument: 'Document number*'
};
