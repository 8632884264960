import { t } from 'i18next';

import { allergies } from '../interfaces/calculator';
import { Product } from '../interfaces/product';

import calculatorES from '../assets/calculator/es';
import calculatorEN from '../assets/calculator/en';

export const getPetFeedData = (
  { name, weight, age, range, type, activity, allergies, lang }: PetInfo,
): PetFeedData | undefined => {
  const calculator = lang === 'USA' ? calculatorEN : calculatorES;

  const data = calculator[type][range];

  if (!data || !age) return;

  const { activities, feedTimes } = data;

  const multiply = (activities[activity] as any)[age] as number;
  const grams = Math.round((multiply / 100) * weight * 1000);

  if (isNaN(grams) || !grams) return;

  return {
    msg: `¡${name.toUpperCase()} ${t('calcResultTitleFrag1')} ${grams} ${t('calcResultTitleFrag2')} ${t('calcResultTitleFrag3')} ${feedTimes} ${t(
      'calcResultTitleFrag4'
    )}`,
    grams,
    type,
    range,
    allergies,
    country: lang === 'USA' ? 2 : 1,
    dogName: name,
    feedTimes,
  };
};

export interface PetInfo {
  name: string;
  weight: number;
  age: number;
  range: 'cachorros' | 'adultos' | 'senior';
  type: 'dog' | 'cat';
  activity: 'low' | 'medium' | 'high';
  allergies: allergies;
  lang: string,
}

export interface PetFeedData {
  msg: string;
  grams: number;
  type: 'dog' | 'cat';
  range: 'cachorros' | 'adultos' | 'senior';
  allergies: allergies;
  country: number,
  dogName: string,
  feedTimes: number
}

export const isValidProduct = (product: Product[]) => {
  let isValid = false;
  product.forEach(item => {
    if (item.status === '1') isValid = true;
  });

  return isValid;
};

export const LAST_CALCULATOR_RESULT_KEY = 'lastCalculatorResult';
