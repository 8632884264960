import { LoginForm } from '../../interfaces/auth';
import { IWholesaler } from '../../interfaces/wholesaler';
import api from '../axios';

class WholesalerAuthService {
  private baseUrl = 'pixie-customers/api/wholesaler/auth';

  login = (formLogin: LoginForm, language: string): Promise<{data: IWholesaler, token:string}> =>
    new Promise((resolve, reject) => {
      api.post(`${this.baseUrl}/login`, formLogin).then(res => {
        const token = res.headers.authorization;
        const { data } = res.data;
        resolve({ data, token });
      }).catch(err => {
        if (language === 'en')
          reject(err.response.data.message_en);
        else
          reject(err.response.data.message);
      });
    });

  verifyToken = (token: string): Promise<IWholesaler> =>
    new Promise((resolve, reject) => {
      api.get(`${this.baseUrl}/me`, {
        headers: {
          Authorization: token
        }
      }).then(res => {
        const { data } = res.data;
        resolve(data);
      }).catch(err => {
        reject(err.message);
      });
    });
}

export default new WholesalerAuthService();
