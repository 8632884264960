export const shippingCity = {
  shippingCityTitleOne: 'Si cambias la ciudad de envío tu carrito se borrará',
  shippingCitySubtitleOne: 'Te mostraremos productos disponibles para esta ciudad y tiempos de entrega más precisos.',
  shippingCityTitleTwo: '¿Vas a realizar el envío para',
  shippingCitySubtitleTwo: 'Te mostraremos productos disponibles para esta ciudad y tiempos de entrega más precisos.',
  shippingCityCancel: 'Cancelar',
  shippingCityChange: 'Cambiar ubicación',
  shippingCitySave: 'Guardar ubicación',
  shippingCityTextNavbar: 'Tu envío se hará para',
};
