import {
  inLeg,
  inChickenMeat,
  bean,
  inMineral,
  inFillet,
  inTurkeyMeat,
  inIntegralRice,
  inPotatoes,
  inCarrot,
  inSpinach,
  inQuinua,
  inLinaza,
  inVegetableOil,
  inEggs,
  inVitamines,
  inBatata,
  inInstarchCassava,
  inTilapia,
  inWater,
  inFishOil,
  inSpirulina,
  inCarobtree,
  inOatmeAlflakes,
  inJelly,
  inWheatFlour,
  inRabbitMeat,
  inFlourOats,
  inPeanutButter,
  inhoneyBees,
  inMango,
  inPeanut,
  inYogurt,
  inBanana,
} from '../assets/vectors';
import { attributesType, infoSelectSPandEn, ingredientesProps } from '../interfaces/product';
import { removeDuplicateByKey } from './array';
import { AppLanguage, getFileFullPath } from './pixieApp';

/// refactor
export const getIngredientsByGroup = (ingredients: string[], allIngredients: infoSelectSPandEn[], language: AppLanguage): ingredientesProps[] => {
  /// get all ingredients of product by keys
  const allIngredientsOfProduct = allIngredients.filter(item => ingredients.includes(item.key));

  /// ingredients with group
  const ingredientsWithGroup = allIngredientsOfProduct.filter(item => item.ingredient_group_id && item.ingredient_group);

  /// ingredients without group objects
  const ingredientsWithOutGroup: ingredientesProps[] = allIngredientsOfProduct.filter(item => !item.ingredient_group_id)?.map(item => ({
    id: item.id,
    name: language === 'en' ? item.name_en : item.name,
    img: getFileFullPath(language === 'en' ? item.url_image_en : item.url_image),
  }));

  /// all gropus of ingredients
  const allGroups = ingredientsWithGroup.map(item => item.ingredient_group!);

  /// generate groups of ingredients objects
  const ingredientsGroups: ingredientesProps[] = removeDuplicateByKey(allGroups, 'id').map(group => {
    const integredientsName = ingredientsWithGroup.filter(ingredient => ingredient?.ingredient_group_id === group.id).reduce((acc, namesItem) => ({
      tooltip: [...acc.tooltip, namesItem.name],
      tooltipEn: [...acc.tooltipEn, namesItem.name_en],
    }), {
      tooltip: [] as string[],
      tooltipEn: [] as string[],
    });

    return {
      name: group.name,
      img: getFileFullPath(group.image),
      tooltip: integredientsName.tooltip?.join(', '),
      tooltipEn: integredientsName.tooltipEn?.join(', '),
    };
  });

  return [...ingredientsWithOutGroup, ...ingredientsGroups];
};

// Order nutrient adjunting with his image
export const organizeIngredients = (ingredients: string[], allIngredients: infoSelectSPandEn[], languaje: string): Array<ingredientesProps> => {
  const ingredientGroup = organizateIngredientsGroup(ingredients, allIngredients, languaje);
  return ingredientGroup;
};

export const organizeAttributes = (attributes: string[], dataAttributes:infoSelectSPandEn[]): infoSelectSPandEn[] => {
  const dataFinal: infoSelectSPandEn[] = [];

  attributes.forEach(item => {
    dataAttributes.forEach(element => {
      if (item === element.key)
        dataFinal.push(element);
    });
  });

  return dataFinal;
};

/// export const organizeAttributes = (attributes: string[]): Array<attributesType> => {
//   // Delete one element because is the same icon
//   if (attributes.find(element => element === 'Fresco') && attributes.find(element => element === 'natural'))
//     attributes = attributes.filter(item => item !== 'Fresco');
//   const objectAttributes: Array<attributesType> = [];
//   attributes.forEach(item => objectAttributes.push(switchOrganiceAttributes(item)));
//   return objectAttributes;
// };

// eslint-disable-next-line complexity
const switchOrganiceIngredients = (ingredient: string, ingredientSPEn: infoSelectSPandEn[], languaje: string) => {
  const name = ingredientSPEn.find(item => item.key.toLocaleLowerCase() === ingredient.toLocaleLowerCase());
  const objectIngredient = {
    name: languaje === 'es' ? name?.name as string : name?.name_en as string,
    img: '',
  };

  switch (ingredient.trim().toLocaleLowerCase()) {
    case 'huevo':
      objectIngredient.img = inEggs;
      break;
    case 'harina-de-quinua':
      objectIngredient.img = inQuinua;
      break;
    case 'espirulina':
      objectIngredient.img = inSpirulina;
      break;
    case 'carne-de-pavo':
      objectIngredient.img = inTurkeyMeat;
      break;
    case 'harina-de-trigo-integral':
      objectIngredient.img = inWheatFlour;
      break;
    case 'huevo-de-gallina':
      objectIngredient.img = inEggs;
      break;
    case 'aceite-de-pescado':
      objectIngredient.img = inFishOil;
      break;
    case 'harina-de-algarrobo':
      objectIngredient.img = inCarobtree;
      break;
    case 'quinoa':
      objectIngredient.img = inQuinua;
      break;
    case 'almidon-de-mandioca':
      objectIngredient.img = inInstarchCassava;
      break;
    case 'carne-de-conejo':
      objectIngredient.img = inRabbitMeat;
      break;
    case 'arroz-integral':
      objectIngredient.img = inIntegralRice;
      break;
    case 'habichuela':
      objectIngredient.img = bean;
      break;
    case 'papa':
      objectIngredient.img = inPotatoes;
      break;
    case 'avena-en-hojuelas':
      objectIngredient.img = inOatmeAlflakes;
      break;
    case 'espinaca-y/o-ejotes':
      objectIngredient.img = inSpinach;
      break;
    case 'batata':
      objectIngredient.img = inBatata;
      break;
    case 'tilapia':
      objectIngredient.img = inTilapia;
      break;
    case 'banano':
      objectIngredient.img = inBanana;
      break;
    case 'yogurt':
      objectIngredient.img = inYogurt;
      break;
    case 'harina-de-avena':
      objectIngredient.img = inFlourOats;
      break;
    case 'linaza':
      objectIngredient.img = inLinaza;
      break;
    case 'miel-de-abejas':
      objectIngredient.img = inhoneyBees;
      break;
    case 'quinua':
      objectIngredient.img = inQuinua;
      break;
    case 'crema-de-cacahuate':
      objectIngredient.img = inPeanutButter;
      break;
    case 'zanahoria':
      objectIngredient.img = inCarrot;
      break;
    case 'harina-de-arroz':
      objectIngredient.img = inIntegralRice;
      break;
    case 'aceite-vegetal':
      objectIngredient.img = inVegetableOil;
      break;
    case 'mango':
      objectIngredient.img = inMango;
      break;
    case 'hígado-de-res':
      objectIngredient.img = inFillet;
      break;
    case 'carne-de-pollo':
      objectIngredient.img = inChickenMeat;
      break;
    case 'carne-de-cordero':
      objectIngredient.img = inLeg;
      break;
    case 'cacahuate':
      objectIngredient.img = inPeanut;
      break;
    case 'agua':
      objectIngredient.img = inWater;
      break;
    case 'gelatina':
      objectIngredient.img = inJelly;
      break;
    default:
      break;
  }

  return objectIngredient;
};

/// const switchOrganiceAttributes = (attribute: string): attributesType => {
//   const objectAttributes: attributesType = {
//     name: attribute,
//     img: '',
//     imgEn: ''
//   };

//   switch (attribute.trim().toLocaleLowerCase()) {
//     case 'digestiva':
//       objectAttributes.img = atSensitiveAllergic;
//       objectAttributes.imgEn = atSensitiveAllergicEn;
//       objectAttributes.name = 'digestiva';
//       break;
//     case 'bajo-en-proteína':
//       objectAttributes.img = atLowProtein;
//       objectAttributes.imgEn = atLowProteinEn;
//       break;
//     case 'refrescante':
//       objectAttributes.img = atRefreshing;
//       objectAttributes.imgEn = atRefreshingEn;
//       break;
//     case 'alta-digestibilidad':
//       objectAttributes.img = atDigestibility;
//       objectAttributes.imgEn = atDigestibilityEn;
//       break;
//     case 'ingredientes-naturales':
//       objectAttributes.img = atNaturalIngredients;
//       objectAttributes.imgEn = atNaturalIngredientsEn;
//       break;
//     case 'rico-en-probioticos':
//       objectAttributes.img = atHightProbiotics;
//       objectAttributes.imgEn = atHightProbioticsEn;
//       break;
//     case 'fresco':
//       objectAttributes.img = atFreshNatural;
//       objectAttributes.imgEn = atFreshNaturalEn;

//       break;
//     case 'cuidado-hepatico-y-renal':
//       objectAttributes.img = atKidneyCare;
//       objectAttributes.imgEn = atKidneyCareEn;
//       break;
//     case 'alto-en-proteina':
//       objectAttributes.img = atHighProtein;
//       objectAttributes.imgEn = atHighProteinEn;
//       break;
//     case 'alta-energia':
//       objectAttributes.img = atHighEnergy;
//       objectAttributes.imgEn = atHighEnergyEn;
//       break;
//     case 'rico-en-vitaminas':
//       objectAttributes.img = atHighVitamine;
//       objectAttributes.imgEn = atHighVitamineEn;
//       break;
//     case 'libre-de-gluten':
//       objectAttributes.img = atGlutenFree;
//       objectAttributes.imgEn = atGlutenFreeEn;

//       break;
//     default:
//       break;
//   }

//   return objectAttributes;
// };

export const calculatePageNutrients = (
  ingredients: ingredientesProps[],
  divideNumber: number
): ingredientesProps[][] => {
  const ingredientsFilter = ingredients.filter(item => item.img);
  const ingredientsTotal: ingredientesProps[][] = [];
  let currentPosition = 0;

  ingredientsFilter?.forEach((ingredient, index) => {
    if (ingredientsFilter.length > currentPosition && currentPosition === index) {
      const page = ingredientsFilter?.slice(currentPosition, currentPosition + divideNumber);
      ingredientsTotal.push(page);
      currentPosition += divideNumber;
    }
  });

  return ingredientsTotal;
};

const organizateIngredientsGroup = (ingredients: string[], ingredientSPEn: infoSelectSPandEn[], languaje: string): Array<ingredientesProps> => {
  const objectIngredients: ingredientesProps[] = [];

  const minerales = ['hierro', 'yodo', 'cobre', 'selenio', 'zinc'];
  const carnes = ['carne-de-res', 'corazon-de-res', 'pulmon-de-res'];
  const vitaminas = ['vitamina-a', 'vitamina-d', 'vitamina-e', 'vitamina-b1', 'vitamina-b2', 'vitamina-b3', 'vitamina-b5', 'vitamina-b6', 'vitamina-b8', 'vitamina-b9', 'vitamina-b12', 'colina'];

  ingredients.forEach(item => {
    const objectIngredient: ingredientesProps = {
      name: '',
      img: '',
      tooltip: '',
      tooltipEn: '',
    };

    if (minerales.includes(item.toLocaleLowerCase().trim())) {
      const index = objectIngredients.findIndex(item => item.name === 'Minerales' || item.name === 'Minerals');
      if (index === -1) {
        objectIngredient.img = inMineral;
        objectIngredient.name = languaje === 'es' ? 'Minerales' : 'Minerals';
        objectIngredient.tooltip += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name}, `;
        objectIngredient.tooltipEn += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name_en}, `;
        objectIngredients.push(objectIngredient);
      } else {
        objectIngredients[index].tooltip += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name}, `;
        objectIngredients[index].tooltipEn += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name_en}, `;
      }
    } else if (carnes.includes(item.toLocaleLowerCase().trim())) {
      const index = objectIngredients.findIndex(item => item.name === 'Carnes' || item.name === 'Meats');
      if (index === -1) {
        objectIngredient.img = inFillet;
        objectIngredient.name = languaje === 'es' ? 'Carnes' : 'Meats';
        // Provitional while the ingredient data is update in the DB
        if (item.toLocaleLowerCase().trim() === 'carne de res') objectIngredient.tooltip += 'Res, ';
        else {
          objectIngredient.tooltip += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name}, `;
          objectIngredient.tooltipEn += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name_en}, `;
        }

        objectIngredients.push(objectIngredient);
        // eslint-disable-next-line brace-style
      }
      // Provitional while the ingredient data is update in the DB
      else if (item.toLocaleLowerCase().trim() === 'carne-de-res') {
        objectIngredients[index].tooltip += 'Res, ';
        objectIngredients[index].tooltipEn += 'Beef, ';
      } else {
        objectIngredients[index].tooltip += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name}, `;
        objectIngredients[index].tooltipEn += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name_en}, `;
      }
    } else if (vitaminas.find(vitaminItem => vitaminItem === item.trim())) {
      const index = objectIngredients.findIndex(item => item.name === 'Vitaminas' || item.name === 'Vitamins');
      if (index === -1) {
        objectIngredient.img = inVitamines;
        objectIngredient.name = languaje === 'es' ? 'Vitaminas' : 'Vitamins';
        objectIngredient.tooltip += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name}, `;
        objectIngredient.tooltipEn += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name_en}, `;
        objectIngredients.push(objectIngredient);
      } else {
        objectIngredients[index].tooltip += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name}, `;
        objectIngredients[index].tooltipEn += `${ingredientSPEn.find(ing => ing.key === item.trim())?.name_en}, `;
      }
    } else objectIngredients.push(switchOrganiceIngredients(item, ingredientSPEn, languaje));
  });
  return objectIngredients;
};

