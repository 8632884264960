import Login from './login';
import RecoveryPassword from './recoveryPassword';
import Register from './register';
import ResetPassword from './resetPassword';

import { useAppContext } from '../../hooks';

interface Props {
  classModal?: boolean;
}

const Auth = ({ classModal = false }: Props) => {
  // Hook
  const { showPopupAuth } = useAppContext();

  return (
    <>
      {showPopupAuth.view === 1 && <Login classModal/>}
      {showPopupAuth.view === 2 && <Register/>}
      {showPopupAuth.view === 3 && <RecoveryPassword />}
      {showPopupAuth.view === 4 && <ResetPassword/>}
    </>
  );
};

export default Auth;
