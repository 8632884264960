import { useEffect, useState } from 'react';

import FormRegisterSection from './FormRegisterSection';
import FormResetCodeSection from './FormResetCodeSection';
import HeaderSection from './HeaderSection';
import ImageRegisterSection from './ImageRegisterSection';
import LoginSocialSection from './loginSocialSection';

import { backgroundLogin } from '../../../assets/images';

const Register = () => {
  const [showValidateCode, setShowValidateCode] = useState(false);
  const [emailValidate, setEmailValidate] = useState('');
  const [isCurrentValidation, setIsCurrentValidation] = useState(false);

  useEffect(() => {
    const existEmailLS = localStorage.getItem('emailValidate');
    if (existEmailLS !== null) {
      setEmailValidate(existEmailLS);
      setShowValidateCode(true);
      setIsCurrentValidation(true);
    }

    return () => {};
  }, []);

  return (
    <div className='bg-sixth w-full h-full pb-4 scroll-gray overflow-y-auto lg:pb-0'>
      <div className={`${showValidateCode ? 'h-screen' : 'h-full'}  lg:h-full flex flex-col w-full md:px-40 lg:w-full lg:px-0 lg:flex-row `}>
        <div className={`flex-col h-[258px] lg:block lg:h-full bg-[#A7C694] lg:w-1/2 lg:pb-16 ${showValidateCode ? 'block' : 'hidden'}`}>
          <HeaderSection
            showValidateCode={showValidateCode}
            className='flex-row lg:hidden'
          />
          <ImageRegisterSection/>
        </div>
        <div className='flex flex-col gap-9 w-full lg:w-1/2 h-full relative z-10 pb-10 bg-sixth'>
          <img
            src={backgroundLogin}
            alt={backgroundLogin}
            className='absolute -z-20 hidden lg:block h-screen w-full'
          />
          <HeaderSection
            showValidateCode={showValidateCode}
            className={`${showValidateCode ? 'hidden' : 'block'} flex-col lg:block`}
          />
          <div className='px-[1.20rem] w-full lg:px-24 z-10'>
            {showValidateCode ?
              <FormResetCodeSection
                emailValidate={emailValidate}
                isCurrentValidation={isCurrentValidation}
                setShowValidateCode={setShowValidateCode}/>
              :
              <>
                <FormRegisterSection
                  setShowValidateCode={setShowValidateCode}
                  setEmailValidate={setEmailValidate}
                />
                <LoginSocialSection/>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
