import Page from '../../components/layout/page';
import AboutVeterinarySection from './AboutVeterinarySection';
import BannerAppointmentSection from './BannerSection';
import DoubtsSection from './DoubtsSection';
import Footer from '../../components/layout/footer';
import CreateAppointment from '../createAppointment';
import { useState } from 'react';

const AppointmentsLanding = () => {
  const [showCreateAppointment, setShowCreateAppointment] = useState(false);

  return (
    <Page className='bg-[#A7C694]'>
      <BannerAppointmentSection
        setShowCreateAppointment={setShowCreateAppointment}
      />
      <AboutVeterinarySection/>
      <DoubtsSection/>
      <Footer />
      {showCreateAppointment && (
        <CreateAppointment handleClose={() => setShowCreateAppointment(false)}/>
      )}
    </Page>
  );
};

export default AppointmentsLanding;
