import { useNavigate } from 'react-router-dom';
import { happyFace } from '../../../assets/vectors';
import Button from '../../../components/common/button';
import IconButton from '../../../components/common/iconButton';
import { useAppContext } from '../../../hooks';

const HeaderSection = ({ showValidateCode, className }:HeaderSectionProps) => {
  const navigate = useNavigate();
  const { showPopupAuth, updateContext } = useAppContext();

  const handleChangeView = () => {
    if (showPopupAuth.status)
      updateContext(old => ({ ...old, showPopupAuth: { status: old.showPopupAuth.status, view: 1 } }));
    else
      navigate('/autenticacion/ingresar');
  };

  const handleCloseAuth = () => {
    updateContext(old => ({ ...old, showPopupAuth: { status: false, view: 1 }, showNavbar: true }));
  };

  return (
    <div className={`w-full flex gap-3 mt-3 ${className && className}`}>
      <div className='flex justify-start lg:justify-end lg:mr-5'>
        <IconButton.mini
          name='chevron_left'
          onClick={() => navigate(-1)}
          className='text-primary lg:hidden'
          size='3xl'
        />
        <IconButton.mini
          name='close'
          onClick={handleCloseAuth}
          className='hidden lg:block text-black'
          size='2xl'
        />
      </div>
      <div className='justify-center w-full flex font-sanzSemiBold px-7 gap-4 items-center lg:px-24 lg:gap-6'>
        <Button
          className='bg-[#F9D864] flex gap-1 rounded-[16px] shadow-xl lg:rounded-[20px]'
          onClick={() => handleChangeView()}
        >
          <img
            src={happyFace}
            className='brightness-0'
          />
          <span className='text-base'>Iniciar sesión</span>
        </Button>
      </div>
    </div>
  );
};

interface HeaderSectionProps {
  showValidateCode: boolean,
  className?: string
}

export default HeaderSection;
