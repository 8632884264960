import FormResetPassword from './FormResetSection';
import HeaderSection from './HeaderSection';
import ImageRecoverySection from './imageResetSection';
import { backgroundLogin } from '../../../assets/images';

const ResetPassword = () => (
  <div className='bg-sixth w-full pb-4 lg:pb-0'>
    <div className='h-screen lg:h-full flex flex-col w-full sm:px-48 lg:w-full lg:px-0 lg:flex-row '>
      <div className='flex-col h-[258px] lg:block lg:h-full bg-[#A7C694] lg:w-1/2 lg:pb-16'>
        <HeaderSection
          showValidateCode={true}
          className='flex-row lg:hidden'
        />
        <ImageRecoverySection />
      </div>
      <div className='flex flex-col gap-9 w-full lg:w-1/2 h-full relative z-10 pb-10 bg-sixth'>
        <img
          src={backgroundLogin}
          alt={backgroundLogin}
          className='absolute -z-20 hidden lg:block h-screen w-full'
        />
        <HeaderSection
          showValidateCode={false}
          className='flex-col hidden lg:block'
        />
        <div className='px-[1.20rem] w-full lg:px-24 lg:flex lg:flex-col lg:justify-center lg:items-center lg:mt-20 h-full'>
          <FormResetPassword />
        </div>
      </div>
    </div>
  </div>
);

export default ResetPassword;
