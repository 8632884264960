import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/common/button';
import Icon from '../../../components/common/icon';
import IconButton from '../../../components/common/iconButton';

import { useAppContext } from '../../../hooks';

const HeaderSection = ({ showValidateCode, className }:HeaderSectionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showPopupAuth, updateContext } = useAppContext();

  const handleChangeView = () => {
    if (showPopupAuth.status)
      updateContext(old => ({ ...old, showPopupAuth: { status: old.showPopupAuth.status, view: 1 } }));
    else
      navigate('/autenticacion/ingresar');
  };

  const handleCloseAuth = () => {
    updateContext(old => ({ ...old, showPopupAuth: { status: false, view: 1 }, showNavbar: true }));
  };

  return (
    <div className={`w-full flex gap-3 mt-3 ${className && className}`}>
      <div className='flex justify-start lg:justify-end lg:mr-5'>
        <IconButton.mini
          name='chevron_left'
          onClick={() => navigate(-1)}
          className='text-primary lg:hidden'
          size='3xl'
        />
        <IconButton.mini
          name='close'
          onClick={handleCloseAuth}
          className='hidden lg:block text-black'
          size='2xl'
        />
      </div>
      <div className={`${showValidateCode && 'justify-center'} w-full flex font-sanzSemiBold px-7 gap-4 items-center lg:px-24 lg:gap-6`}>
        {!showValidateCode &&
         <span className='text-sm lg:text-lg'>{t('registerHasAccount')}</span>
        }
        <Button
          className='bg-[#F9D864] flex gap-1 rounded-[16px] shadow-xl lg:rounded-[20px]'
          onClick={() => handleChangeView()}
        >
          <Icon
            name='person'
            type='round'
            className='first-line:mr-4 md:mr-0 text-black cursor-pointer'
            size='3xl'
          />
          <span className='text-base'>{t('registerSignUp')}</span>
        </Button>
      </div>
    </div>
  );
};

interface HeaderSectionProps {
  showValidateCode: boolean,
  className?: string
}

export default HeaderSection;
