export const subscriptions = {
  subTitleOne: 'Este',
  subTitleTwo: 'amor',
  subTitleThree: 'tan real se merece un alimento igual de',
  subTitleFour: 'real',
  subSubtitle: 'Pixie es un alimento natural, con ingredientes frescos y naturales, hechos por médicos veterinarios y zooctecnistas. Pensemos lo que le damos a nuestras mascotas.',
  subTitleForSubscribe: 'Suscribirte es muy fácil',
  subTitleDurationOne: 'Escoge la',
  subTitleDurationTwo: 'duración',
  subTextStepDurationOne: 'Tenemos planes de débito',
  subTextStepDurationTwo: 'mensual 5% de dto, trimestral (8% de dto.) y semestral (10% de dto.)',
  subTextStepDurationThree: 'para que selecciones la que más se ajuste a tu bolsillo (billetera).',
  subTitleFrequencyOne: 'Selecciona la',
  subTitleFrequencyTwo: 'frecuencia',
  subTitleFrequencyThree: 'de envío',
  subTextFrequencyOne: 'Puedes seleccionar entregas',
  subTextFrequencyTwo: 'semanales, quincenales o mensuales',
  subTextFrequencyThree: 'según tu preferencia y capacidad de congelador.',
  subTitleQuantityOne: 'Determina la',
  subTitleQuantityTwo: 'cantidad',
  subTextQuantityOne: 'De acuerdo a la edad y condición médica de tu peludito',
  subTextQuantityTwo: 'selecciona los productos que quieres',
  subTextQuantityThree: 'que te lleguen en cada entrega',
  subTextBotonSubscribe: 'Quiero suscribirme',
  subTitleOptionSubscribe: '... A través de:',
  subTextOptionSubscribeCalculator: 'Calculadora',
  subTextOptionSubscribeBasket: 'Canasta',
  subTitleBenefits: '¿Qué beneficios tienes por suscribirte?',
  subTextBenefitOne: 'Descuentos según la vigencia que elijas',
  subTitleBenefitsConditions: '*Aplican términos y condiciones',
  subTitleQuestions: '¿Tienes dudas sobre tu suscripción?',
  subSubtitleQuestions: 'Quizás estas preguntas frecuentes te puedan ayudar. Si aún tienes dudas',
  subContactUs: 'contáctanos',

  subResumenShippingContact: 'Contacto',
  subResumenShippingDelivery: 'Enviar a',
  subResumenShippingChange: 'Cambiar',

  subCheckoutTitle: 'Pago',
  subCheckoutSubtitle: 'Todas las transacciones son seguras y encriptadas.',
  subCheckoutFieldName: 'Nombre del propietario*',
  subCheckoutFieldNUmber: 'Número de la tarjeta*',
  subCheckoutFieldDate: 'Fecha de vencimiento MM/AA*',
  subCheckoutFieldCVV: 'Código de seguridad*',
  subCheckourFieldDocument: 'Número de documento*'
};
