import TagManager from 'react-gtm-module';
import { CartItem } from '../interfaces/basket';
import { Product } from '../interfaces/product';
import { calculateTotal } from './productHelper';

export const sendDataLayerCatalogue = (productsMX?: Product[] | undefined, productsST?: Product[]) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item_list',
      ecommerce: {
        items: productsMX ? productsMX.map(item => ({
          item_id: item.id,
          item_name: item.name,
          index: item.id,
          item_list_name: 'Related Products',
          item_list_id: 'related_products',
          item_brand: 'Pixie',
          item_category: item.category,
          /// item_variant: 'black',
          price: Number(item.price),
          currency: 'MXN',
          quantity: item.quantity,
        })) :
          productsST ? productsST.map(item => ({
            item_id: item.id,
            item_name: item.name,
            index: item.id,
            item_list_name: 'Related Products',
            item_list_id: 'related_products',
            item_brand: 'Pixie',
            item_category: item.category,
            /// item_variant: 'black',
            price: Number(item.price),
            currency: 'USD',
            quantity: item.quantity
          })) : [],
      },
    },
  });
};

export const sendDataLayerStartCheckout = (productsCart: CartItem[], location: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'begin_checkout',
      ecommerce: {
        items: productsCart.map(item => ({
          item_id: item.product.id,
          item_name: item.product.name,
          item_brand: 'Pixie',
          item_category: item.product.category,
          /// item_variant: 'black',
          price: Number(item.product.price),
          currency: location === 'MEX' ? 'MXN' : 'USD',
          quantity: item.quantity
        }))
      }
    },
  });
};

export const sendDataLayerAddToCar = (productCart: CartItem, currency: 'MXN' | 'USD', where: 'home' | 'catalogo') => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_to_cart',
      ecommerce: {
        currency,
        value: Number(calculateTotal([productCart], false)),
        items: [
          {
            item_id: productCart.product.id,
            item_name: currency === 'MXN' ? productCart.product.name : productCart.product.name_en,
            index: 0,
            item_brand: 'pixie',
            item_category: productCart.product.category,
            item_list_name: where,
            price: Number(productCart.product.price),
            quantity: productCart.quantity
          }
        ],
      }
    },
  });
};

export const sendDataLayerViewItem = (product: Product, currency: 'MXN' | 'USD', where: 'home' | 'catalogo') => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_item',
      ecommerce: {
        currency,
        value: Number(product.price),
        items: [
          {
            item_id: product.id,
            item_name: currency === 'MXN' ? product.name : product.name_en,
            item_brand: 'pixie',
            item_category: product.category,
            item_list_name: where,
            price: Number(product.price),
            quantity: 1
          }
        ]
      }
    },
  });
};

export const sendDataLayerViewCar = (productsCar: CartItem[], currency: 'MXN' | 'USD') => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'view_cart',
      ecommerce: {
        currency,
        value: Number(calculateTotal(productsCar, false)),
        items: productsCar.map(prod => (
          {
            item_id: prod.product.id,
            item_name: prod.product.name,
            item_brand: 'pixie',
            item_category: prod.product.category,
            price: Number(prod.product.price),
            quantity: prod.quantity
          }
        ))
      }
    },
  });
};
