import { useTranslation } from 'react-i18next';
import { alarm, paper, phonePixie } from '../../assets/vectors';
import { StepSectionItem } from '../subscription/StepSection';

const AboutVeterinarySection = () => {
  const { t } = useTranslation();
  return (
    <div className='bg-sixth w-full -mt-28 md:-mt-10 lg:flex lg:justify-center lg:items-center rounded-t-[46px] xl:-mt-16 z-10'>
      <div className='rounded-[29px] md:-mt-6 z-30 py-11 max-w-[1500px] flex flex-col gap-10 md:gap-14 xl2:gap-24'>
        <h3 className='text-xl lg:text-[30px] mt-5'>¿Cómo es una cita con nuestros veterinarios?</h3>
        <div className='flex flex-col px-6 lg:flex-row gap-10 justify-center items-center w-full xl2:px-40 lg:mb-16'>
          <StepSectionItem
            icon={alarm}
            title={
              <p>{t('appointmentAlarm')}</p>
            }
            size='w-[7.19rem] h-[7.19rem]'
          />
          <StepSectionItem
            icon={phonePixie}
            title={
              <p>{t('appointmentPhone')}</p>
            }
            size='w-[7.19rem] h-[7.19rem]'
          />
          <StepSectionItem
            icon={paper}
            title={
              <p>{t('appointmentPaper')}</p>
            }
            size='w-[7.19rem] h-[7.19rem]'
          />
        </div>
      </div>
    </div>
  );
};

export default AboutVeterinarySection;
