import IconButton from '../../iconButton';

import { tagDogGray } from '../../../../assets/vectors/index';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ProductCardCombo = () => {
  const { t } = useTranslation();
  const [showHoverComingSoon, setShowHoverComingSoon] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className={`
          relative flex flex-col flex-shrink-0 justify-between items-center
          h-[17.5rem] smaller:w-48 w-52 md:w-[16.815rem] md:h-[380px]
          2xl1:w-[19.815rem] 2xl1:h-[414px]
        `}
      onClick={() => navigate('/producto/crear/combo')}
    >
      <div
        className={`
          relative flex flex-col flex-shrink-0 justify-between items-center 
          cursor-pointer p-3 pb-8 rounded-2xl bg-white
          ring-0 ring-pixieLightBlue transform transition-all 
          w-full h-full lg:pb-11 hover:ring-1 
        `}
        onMouseOver={() => setShowHoverComingSoon(true)}
        onMouseLeave={() => setShowHoverComingSoon(false)}
      >
        <div className='flex justify-between w-full mb-2 relative z-30'>
          <div className='flex items-start justify-center gap-1 absolute left-1 pr-9'>
            <div
              className={`
                rounded-full pl-px pr-2 h-[1.18rem]
                flex items-center
                border border-[#FE6706] text-grayText bg-[#FE6706] bg-opacity-60
                font-subTitles font-semibold 
                md:py-px md:gap-1 md:pr-3 md:pl-[6px] md:h-[1.95rem]
              `}
            >
              <span className='text-[11px] md:text-sm capitalize ml-1'>Great variety</span>
            </div>
          </div>
          <IconButton.mini
            img={tagDogGray}
            name={'combo-tag-combo'}
            className='text-pixieLightBlue p-1 shadow-none md:mr-1 absolute right-0 md:right-1 bg-[#F7EBA8]'
            imgClassName='brightness-[0.30] opacity-80'
            onClick={() => {}}
          />
        </div>
        <div className='flex-grow overflow-hidden rounded-md'>
          <img src={`${process.env.REACT_APP_URL_S3}resources/products/CreateCombo.jpg`} className='object-cover' />
        </div>
        <div className={'text-center text-xs md:text-lg w-full'}>
          <h4 className='text-pixieLightBlue mb-1 flex flex-col'>
            {t('productX20')}
            <span className='md:text-base'>{t('productX202')}</span>
          </h4>
        </div>
      </div>
      <div
        className={`
          absolute -bottom-[1rem] cursor-pointer z-40
          text-[#FAD7B1] rounded-[10px] bg-primary shadow-md
          text-[10px] md:text-xs px-3 py-2 md:px-5 md:md:py-4 
          ${showHoverComingSoon ? 'ring-1 ring-pixieLightBlue' : 'ring-0'}
        `}
      >
        <span>{t('productX20Button')}</span>
      </div>
    </div>
  );
};

export default ProductCardCombo;
