import { useNavigate } from 'react-router-dom';

import useAppContext from './useAppContext';
import { StoredAuthTokens } from '../constants/authTokens';
import { useTranslation } from 'react-i18next';

export const useLogout = (redirectTo = '/', callback?: () => void) => {
  // Hooks
  const { updateContext, toast } = useAppContext();
  const navigate = useNavigate();
  const {
    t,
  } = useTranslation();

  // Logout handler
  const handleLogout = () => {
    updateContext(old => ({ ...old,
      isAuthenticated: false,
      user: undefined,
      wholesaler: undefined,
      wholesalerAuthenticated: false
    }));
    localStorage.removeItem('token');
    localStorage.removeItem(StoredAuthTokens.WHOLESALER);

    toast.fire({
      icon: 'success',
      title: t('logoutSuccess'),
    });

    navigate(redirectTo);

    if (callback) callback();
  };

  return handleLogout;
};

export default useLogout;
