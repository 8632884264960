import api from '../axios';

interface ApiResponse<T> {
  data: T | null;
  error: string | null;
}

export interface SectionResponse {
  message: string;
  data: IPointSale[];
}

export interface IPointSale {
  id?: string;
  status: boolean;
  wholesaler_id: string;
  name: string;
  phone_number: string;
  city_id?: string;
  address: string;
  image_urls: string[];
  latitude: string;
  longitude: string;
  has_pixie_freezer: boolean;
  freezer_count: number;
  freezer_details: string;
  freezer_image_urls: string[];
  schedules: ISchedule[];
}

export interface ISchedule {
  day: string | number;
  enabled: boolean;
  start_time: string;
  end_time: string;
}

interface Params {
  cityId?: string;
  address?: string;
}

export const getPointSales = async ({ cityId = '', address = '' }: Params): Promise<ApiResponse<SectionResponse>> => {
  const authorization = window.localStorage.getItem('jwt_access_token');
  const URL = `pixie-customers/api/wholesaler/outlets?city_id=${cityId}&address=${address}&page=1&limit=1000`;
  const config = {
    headers: { Authorization: authorization ? authorization : '' },
  };

  try {
    const response = await api.get<SectionResponse>(URL, config);
    return { data: response.data, error: null };
  } catch (error) {
    if (error instanceof Error)
      return { data: null, error: error.message || 'Error desconocido' };

    return { data: null, error: 'Error desconocido' };
  }
};
