/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable curly */
import { useEffect, useState } from 'react';
import validator from 'validator';

import RadioField from '../../components/form/radioField';
import SelectField, { SelectItem } from '../../components/form/selectField';
import TextCalendarField from '../../components/form/textCalendarField';
import TextField from '../../components/form/textField';
import { useAppContext, useForm } from '../../hooks';
import { FormStepTwoAppointment, UserVet, ValidatorFormStepTwoAppointment, blockSchedule, calendarWorkVet } from '../../interfaces/appointment';
import TextArea from '../../components/form/textArea';
import DragAndDropV2 from '../../components/common/dragAndDropv2';
import moment from 'moment';
import useValidator from '../../hooks/useValidator';
import appointmentService from '../../services/appointmentsService';
import { getDaysDisabledByKeyDay, getOptionsSelectHour } from '../../helpers/appointmentHelper';

const hourFake = [
  { label: '08:00 AM', value: '08:00' },
  { label: '09:00 AM', value: '09:00' },
  { label: '10:00 AM', value: '10:00' },
  { label: '11:00 AM', value: '11:00' },
  { label: '12:00 PM', value: '12:00' },
  { label: '01:00 PM', value: '13:00' },
  { label: '02:00 PM', value: '14:00' },
  { label: '03:00 PM', value: '15:00' },
  { label: '04:00 PM', value: '16:00' },
  { label: '05:00 PM', value: '17:00' },
  { label: '06:00 PM', value: '18:00' },
];

const optionsRadio = [
  { label: 'Meet', value: 'meet' },
  { label: 'Whatsapp / Telegram (USA)', value: 'whatsapp' },
  { label: 'Llamada telefónica', value: 'call' },
];

const StepTwoAppointment = ({ handleSendData }: StepTwoAppointmentProps) => {
  const [meetOption, setMeetOption] = useState('');
  const [files, setFiles] = useState<FileList | null>(null);
  const [counter, setCounter] = useState(0);
  const [veterinaryData, setVeterinaryData] = useState<UserVet[]>([]);
  const [disabledDays, setdisabledDays] = useState<Date[]>([]);
  const [hoursDisabled, setHoursDisabled] = useState<blockSchedule[]>([]);
  const { updateContext, validateFormStepOne, isMobile } = useAppContext();
  const { form, handleFormChange, handleRadioChange, handleChangeDate, handleSelectChange, setForm } = useForm<FormStepTwoAppointment>({
    contact_information: '',
    contact_preference_type: '',
    date: '',
    reason: '',
    vet: { label: 'Seleccione', value: '' },
    vetOptions: [],
    hour: { label: 'Seleccione', value: '' },
    hourOptions: hourFake
  }, () => {});

  const handleSetFile = (files: FileList | null) => {
    setFiles(files);
  };

  useEffect(() => {
    if (validateFormStepOne)
      handleValidate();
  }, [validateFormStepOne]);

  useEffect(() => {
    setCounter(form.reason.length);
  }, [form.reason]);

  useEffect(() => {
    getPreviousData();
  }, []);

  const { handlePutMessageError, resetValidator, validatorBody } = useValidator<ValidatorFormStepTwoAppointment>({
    contact_information: { message: '' },
    contact_preference_type: { message: '' },
    date: { message: '' },
    reason: { message: '' },
    vet: { message: '' },
    hour: { message: '' },
  });

  const getPreviousData = () => {
    appointmentService.getAllVeterinary().then(res => {
      setVeterinaryData(res);
      const vetAvailable = res.filter(vet => vet.calendar_id);
      setForm(old => ({
        ...old,
        vetOptions: vetAvailable.map(vet => ({
          label: `${vet.name}`,
          value: vet.id
        }))
      }));
    });
  };

  const handleValidate = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (form.vet.value === '') {
      handlePutMessageError('vet', 'Debes seleccionar un veterinario');
      error = true;
    }

    if (form.date === '') {
      handlePutMessageError('date', 'Debes seleccionar una fecha');
      error = true;
    }

    if (form.hour.value === '') {
      handlePutMessageError('hour', 'Debes seleccionar una hora');
      error = true;
    }

    if (form.reason === '') {
      handlePutMessageError('reason', 'Debes escribir una razón');
      error = true;
    }

    if (form.contact_preference_type === '') {
      handlePutMessageError('contact_preference_type', 'Debes seleccionar un tipo de contacto');
      error = true;
    }

    if ((form.contact_preference_type === 'meet' && form.contact_information === '') || !validator.isEmail(form.contact_information)) {
      handlePutMessageError('contact_information', 'Debes escribir un correo');
      error = true;
    }

    updateContext(old => ({
      ...old,
      validateFormStepOne: false
    }));

    if (!error)
      handleSendData(form, files);
  };

  const handleGetDataSchedule = (idVet: string) => {
    const veterinaryDataInt = veterinaryData.find(vet => vet.id === idVet);
    if (veterinaryDataInt) {
      // Disabled days
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 365); // Fecha de fin (dentro de 365 días)
      const today = new Date();
      const disabledDates: Date[] = [];

      const initialOptions = veterinaryDataInt.calendar.calendar[0];
      const keys = Object.keys(initialOptions).filter(key => !initialOptions[key as keyof calendarWorkVet].checked);
      const daysDisabled = getDaysDisabledByKeyDay(keys);

      for (const date = today; date <= endDate; date.setDate(date.getDate() + 1))
        if (daysDisabled.includes(date.getDay()))
          disabledDates.push(new Date(date));

      setdisabledDays([
        ...disabledDates
      ]);

      setForm(old => ({
        ...old,
        hourOptions: [],
        hour: { label: 'Seleccione', value: '' }
      }));

      // Get hours disabled
      appointmentService.getBlockedSchedules(veterinaryDataInt.id).then(res => {
        setHoursDisabled(res);
      });
    }
  };

  return (
    <div className='flex flex-col font-subTitles gap-6 lg:gap-10'>
      <div className='flex flex-col gap-5'>
        <PointItemTitle
          number={1}
          title={'Selecciona un veterinario'}
        />
        <SelectField
          onChange={(select, name) => {
            handleSelectChange(select, name);
            const value = select as SelectItem;
            handleGetDataSchedule(value.value);
          }}
          name='vet'
          placeholder='Selecciona un veterinario'
          options={form.vetOptions}
          labelClassName='text-sm'
          borderRadiuspx='20px'
          dropdownIndicatorColor='#33B5A9'
          paddingY='0.4rem'
          messageError={validatorBody.vet.message}
        />
      </div>
      <div className='flex flex-col gap-5'>
        <PointItemTitle
          number={2}
          title='Selecciona la fecha y hora'
        />
        <div className='flex flex-col lg:flex-row gap-[10px] lg:gap-2'>
          <TextCalendarField
            nameField='date'
            placeholder='Fecha'
            setValue={(date, name) => {
              const veterinaryDataInt = veterinaryData.find(vet => vet.id === form.vet.value);
              if (veterinaryDataInt) {
                setForm(old => ({
                  ...old,
                  date: moment(date).format('YYYY-MM-DD'),
                  hourOptions: getOptionsSelectHour(veterinaryDataInt.calendar.calendar[0], moment(date).format('YYYY-MM-DD'), hoursDisabled),
                }));
              } else
                handleChangeDate(date, name);
            }}
            value={form.date}
            textFielClassName='py-3'
            className='lg:w-1/2'
            disabledDays={[...disabledDays, { from: new Date(), to: new Date(1800) },]}
            messageError={validatorBody.date.message}
          />
          <SelectField
            onChange={handleSelectChange}
            name='hour'
            placeholder='Hora'
            options={form.hourOptions}
            labelClassName='text-sm'
            borderRadiuspx='20px'
            dropdownIndicatorColor='#33B5A9'
            paddingY='0.4rem'
            className='md:w-1/2'
            messageError={validatorBody.hour.message}
          />
        </div>
      </div>
      <div className='flex flex-col gap-5'>
        <PointItemTitle
          number={3}
          title='¿Por dónde te gustaría que te atienda nuestro profesional?'
        />
        <div className='flex flex-col gap-[1.3rem]'>
          <RadioField
            changeState={setMeetOption}
            currentState={meetOption}
            handleRadioChange={handleRadioChange}
            name='contact_preference_type'
            value='meet'
            label='Meet'
            iconCheckClassName='text-[24px] text-pixieLightBlue'
            labelClassName='text-sm'
          />
          {form.contact_preference_type === 'meet' && (
            <TextField
              handler={handleFormChange}
              name='contact_information'
              value={form.contact_information}
              placeholder='Email registrado'
              fieldClassName='py-[0.8rem]'
              messageError={validatorBody.contact_information.message}
            />
          )}

          <RadioField
            changeState={setMeetOption}
            currentState={meetOption}
            handleRadioChange={handleRadioChange}
            name='contact_preference_type'
            value='whatsapp'
            label='Whatsapp / Telegram (USA)'
            iconCheckClassName='text-[24px] text-pixieLightBlue'
            labelClassName='text-sm'
          />

          <RadioField
            changeState={setMeetOption}
            currentState={meetOption}
            handleRadioChange={handleRadioChange}
            name='contact_preference_type'
            value='call'
            label='Llamada telefónica'
            iconCheckClassName='text-[24px] text-pixieLightBlue'
            labelClassName='text-sm'
          />
          {validatorBody.contact_preference_type.message && (
            <p className='text-sm text-red-500'>{validatorBody.contact_preference_type.message}</p>
          )}
        </div>
      </div>
      <div className='flex flex-col gap-5'>
        <PointItemTitle
          number={4}
          title='¿Cuál es el motivo de consulta?'
        />

        <div className='relative'>
          <TextArea
            handler={handleFormChange}
            name='reason'
            value={form.reason}
            placeholder='Acá puedes dejarle un comentario a nuestro veterinario antes de la cita para que tenga un contexto general de tu mascota'
            fieldClassName='py-[0.8rem] text-sm min-h-[122px]'
            border='rounded-[20px]'
            maxLength={400}
            messageError={validatorBody.reason.message}
          />
          <p className={`text-sm text-grayText opacity-60 absolute bottom-2 right-2 ${counter === 400 && 'text-primary'}`}>{counter}/400</p>
        </div>

        <div className='flex flex-col gap-4'>
          <p className='pl-5'>Si cuentas con exámenes médicos, puedes adjuntarlos acá (opcional)</p>
          <DragAndDropV2
            setDataFile={handleSetFile}
            selectedFile={files}
          />
        </div>

        <div className='flex flex-col rounded-[10px] py-7 gap-5 bg-white px-5'>
          <p className='text-primary font-titles text-xl'>Resumen de tu cita</p>
          <div className='flex flex-col gap-1 text-sm'>
            <p>Fecha: <strong>{
              form.date
                ?
                <>
                  {moment(form.date).format('D [de] MMMM [del] YYYY')}
                </>
                : <>
                  No seleccionada
                </>
            }</strong></p>
            <p>Hora: <strong>{form.hour.value
              ?
              <>
                {form.hour.label}
              </>
              :
              <>
                No seleccionada
              </>
            }</strong></p>
            <p>Medio: <strong>{form.contact_preference_type ?
              <>
                {optionsRadio.find(item => item.value === form.contact_preference_type)?.label}
              </>
              :
              <>
              No seleccionado
              </>
            }</strong></p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface StepTwoAppointmentProps {
  handleSendData: (data: FormStepTwoAppointment, files: FileList | null) => void;
}

const PointItemTitle = ({ number, title }: PointItemTitleProps) => (
  <div className='flex gap-2 lg:gap-4 text-pixieLightBlue items-center text-lg font-titles'>
    <div className='flex items-center justify-center w-7 h-7 bg-pixieLightBlue text-white rounded-full p-1'>{number}</div>
    <p>{title}</p>
  </div>
);

interface PointItemTitleProps {
  title: string;
  number: number;
}

export default StepTwoAppointment;
