/* eslint-disable no-unused-expressions */
import moment from 'moment';
import { filterList } from '../../../../assets/vectors';
import Button from '../../../../components/common/button';
import { Appointment } from '../../../../interfaces/appointment';
import Tooltiped from '../../../../components/common/tooltiped';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useState } from 'react';
import appointmentService from '../../../../services/appointmentsService';
import { useAppContext } from '../../../../hooks';
import PopupDecisionUser from '../../../../components/layout/popupDecisionUser';

const optionsRadio = [
  { label: 'Meet', value: 'meet' },
  { label: 'Whatsapp / Telegram (USA)', value: 'whatsapp' },
  { label: 'Llamada telefónica', value: 'call' },
];

const MyAppointmentSection = ({ appointments, setAppointments, reloadData }: MyAppointmentSectionProps) => {
  const [orderByRecient, setOrderByRecient] = useState(false);
  const handleOrderList = () => {
    setOrderByRecient(!orderByRecient);
    const appointmentsData = [...appointments];
    if (orderByRecient)
      appointmentsData.sort((a, b) => {
        if (a.report_date && !b.report_date)
          return -1;
        if (!a.report_date && b.report_date)
          return 1;
        return 0;
      });
    else
      appointmentsData.sort((a, b) => {
        if (a.report_date && !b.report_date)
          return 1;
        if (!a.report_date && b.report_date)
          return -1;
        return 0;
      });

    setAppointments(appointmentsData);
  };

  return (
    <div className='flex flex-col rounded-[20px] relative bg-white pt-8 pb-20 gap-5 mx-3 px-[1.40rem] md:pt-5 md:px-9 md:mx-4 lg:mx-0'>
      <div
        className='absolute top-2 right-2 cursor-pointer md:hidden rounded-full bg-fourth w-6 h-6 p-[0.15rem]'
        onClick={handleOrderList}
      >
        <img
          src={filterList}
        />
      </div>
      <div className='md:flex md:justify-between md:items-center'>
        <h3 className='text-left text-pixieLightBlue text-lg md:text-xl'>
          Historial citas con veterinario
        </h3>
        <div
          className='hidden cursor-pointer md:flex rounded-full bg-fourth w-9 h-9 p-[0.15rem]'
          onClick={handleOrderList}
        >
          <img
            src={filterList}
          />
        </div>
      </div>

      <div className='flex flex-col gap-3'>
        {appointments.map(appointment => (
          <AppointmentInfoItem
            appointment={appointment}
            key={appointment.id}
            isDone={Boolean(appointment.report_date)}
            reloadData={reloadData}
          />
        ))}
      </div>

    </div>
  );
};

interface MyAppointmentSectionProps {
  appointments: Appointment[],
  setAppointments: Dispatch<SetStateAction<Appointment[]>>,
  reloadData: () => void
}

const AppointmentInfoItem = ({ appointment, isDone = false, reloadData }: AppointmentInfoItemProps) => {
  const navigate = useNavigate();
  const { toast } = useAppContext();
  const [showDelete, setShowDelete] = useState(false);

  const handleDelete = () => {
    appointmentService.deleteAppointment(appointment.id).then(() => {
      toast.fire({
        icon: 'success',
        title: 'Cita eliminada correctamente',
      });
      reloadData();
    }).catch(err => {
      toast.fire({
        icon: 'error',
        title: 'Error eliminando la cita',
      });
    });
  };

  return (
    <div className={`flex flex-col gap-3 rounded-[12px] py-3 px-3 md:pt-[10px] md:pb-0 lg:px-5 ${isDone ? 'bg-white border border-pixieLightBlue' : 'bg-[#D9EEEF]'} `}>
      <div className='flex gap-3'>
        <div className='flex w-1/3 md:w-1/6'>
          <div className='bg-white rounded-[12px] flex justify-center items-center w-full h-full flex-col md:w-[6.5rem]'>
            <p className='font-titles text-primary text-[38px]'>{moment(appointment.date).format('DD')}</p>
            <p className='text-sm font-sanzSemiBold'>{moment(appointment.date).format('MMMM')}</p>
          </div>
        </div>
        <div className={`flex flex-col gap-1 text-sm md:text-base py-4 w-2/3 md:w-4/6 ${isDone ? 'text-grayText' : 'text-pixieLightBlue'}`}>
          <Tooltiped label={moment(appointment.date).format('D MMMM [del] YYYY')}>
            <p className='truncate'>
              Fecha: {moment(appointment.date).format('D MMMM [del] YYYY')}
            </p>
          </Tooltiped>
          <p>
            Hora: {moment(appointment.date).format('hh:mm a')}
          </p>
          <p>
            Medio: {optionsRadio.find(item => item.value === appointment.contact_preference_type)?.label}
          </p>
        </div>
        <div className='hidden md:flex md:flex-col  md:items-end md:w-1/6'>
          <Button className={`text-white text-base font-sanzBold hidden md:block ${isDone ? 'bg-pixieLightBlue' : 'bg-primary'} w-full`}
            padding='py-[0.3rem]'
            onClick={() => {
              isDone ? navigate(appointment.id) : setShowDelete(true);
            }}
          >
            {isDone ? 'Ver informe' : 'Cancelar'}
          </Button>
        </div>
      </div>
      <div>
        <Button
          className={`text-white text-sm font-sanzBold w-full md:hidden ${isDone ? 'bg-pixieLightBlue' : 'bg-primary'}`}
          padding='py-[0.4rem]'
          onClick={() => {
            isDone ? navigate(appointment.id) : () => {};
          }}
        >
          {isDone ? 'Ver información' : 'Cancelar'}
        </Button>
      </div>
      {showDelete && (
        <PopupDecisionUser
          handleAccept={handleDelete}
          handleDeny={() => setShowDelete(false)}
          textAccept='Eliminar'
          textDeny='Cancelar'
          type='delete'
          title='¿Estas seguro de querer eliminar esta cita?'
        />
      )}
    </div>
  );
};

interface AppointmentInfoItemProps {
  appointment: Appointment,
  isDone?: boolean,
  reloadData: () => void
}

export default MyAppointmentSection;
