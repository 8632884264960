import { useTranslation } from 'react-i18next';
import { useAppContext, useForm } from '../../../hooks';

/// import IconButton from '../../common/iconButton';
import SelectField, { SelectItem } from '../../form/selectField';
import useValidator from '../../../hooks/useValidator';
import { setShippingStore } from '../../../interfaces/location';
import validator from 'validator';
import Button from '../../common/button';
import useShoppingCar from '../../../hooks/useShoppingCar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import storeService from '../../../services/storeService';
import { workCities } from '../../../interfaces/store';
import { Product } from '../../../interfaces/product';
import productService from '../../../services/productService';
import wholesalerStoreService from '../../../services/storeService/wholesaler';

const PopupSelectShippingCity = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locatiom = useLocation();

  const { deleteAllProducts } = useShoppingCar();
  const { updateContext, shippingCityCanChange, countrySelected, products, location, cellarSelected } = useAppContext();

  const [cellars, setCellars] = useState<workCities[]>([]);
  const [states, setStates] = useState<{label: string, value: string}[]>([]);
  const [cities, setCities] = useState<{label: string, value: string}[]>([]);

  /// const [disabledBtn, setDisabledBtn] = useState(false);

  const { form, onSubmit, handleSelectChange, setForm } = useForm<setShippingStore>({
    states: [],
    state: { label: '', value: '' },
    cities: [],
    city: { label: '', value: '' },
  }, () => validateForm());

  const { handlePutMessageError, validatorBody, resetValidator } = useValidator({
    state: { message: '' },
    city: { message: '' },
  });

  useEffect(() => {
    getPreviousData();
  }, [countrySelected]);

  useEffect(() => {
    if (cellarSelected)
      handleRecalculateProducts(cellarSelected.id);
  }, [location]);

  useEffect(() => {
    const currentState = localStorage.getItem('shippingLocation');
    const currentCity = localStorage.getItem('shippingLocationCity');

    if (currentState && states.length > 0) {
      const parseState = JSON.parse(currentState);
      const parseCity = currentCity ? JSON.parse(currentCity) : { label: '', value: '' };

      setForm(old => ({ ...old, state: parseState, city: parseCity }));
      getCities(parseState.value);
    }
  }, [states]);

  const getPreviousData = () => {
    const isWholesalerPath = locatiom.pathname.includes('mayoristas');
    const cellarActiveFunction = isWholesalerPath ? wholesalerStoreService.getCellarsActives : storeService.getCellarsActives;

    cellarActiveFunction(countrySelected.countryNumber)
      .then(res => {
        setCellars(res);

        const idSet = new Set();
        const selectStates: SelectItem[] = [];

        res.forEach(cellar => {
          const stateId = `${cellar?.city?.state?.id}`;
          if (!idSet.has(stateId)) {
            selectStates.push({
              label: cellar?.city?.state?.name,
              value: stateId,
            });
            idSet.add(stateId);
          }
        });

        selectStates.sort((a, b) => a.label.localeCompare(b.label));

        setStates(selectStates);
      });
  };

  const handleClosePopup = () => {
    updateContext(old => ({ ...old, showPopupShippingCity: false, shippingCityCanChange: false }));
  };

  const handleClosePopupForce = () => {
    updateContext(old => ({
      ...old,
      showPopupShippingCity: false,
      shippingCityCanChange: false,
    }));
  };

  const validateForm = () => {
    resetValidator();
    let error = false;

    if (validator.equals(form.state?.value, '')) {
      handlePutMessageError('state', 'Se debe seleccionar un estado');
      error = true;
    }

    if (!error)
      handleSubmit();
  };

  const handleSubmit = () => {
    const locationIn = {
      state: form.state.value,
      city: form.city.value,
    };
    updateContext(old => ({ ...old, locationShipping: locationIn }));
    localStorage.setItem('shippingLocation', JSON.stringify({
      label: form.state.label,
      value: form.state.value,
    }));
    localStorage.setItem('shippingLocationCity', JSON.stringify({
      label: form.city.label,
      value: form.city.value
    }));

    const cellarSelected = cellars.find(item => `${item.city_id}` === form.city.value);
    if (cellarSelected) {
      updateContext(old => ({
        ...old,
        locationShipping: locationIn,
        showPopupShippingCity: false,
        shippingCityCanChange: false,
        cellarSelected: cellarSelected.cellar,
        workCities: cellarSelected,
        dataFormCheckOut: {
          ...old.dataFormCheckOut,
          states,
          /// states: form.states,
          state: location === 'MEX' ? form.city : form.state,
          cities,
          /// cities: form.cities,
          city: location === 'MEX' ? { label: '', value: '' } : form.city,
          country: {
            label: countrySelected.name,
            value: `${countrySelected.countryNumber}`
          },
        }
      }));
      localStorage.setItem('cellarSelected', JSON.stringify(cellarSelected.cellar));
      localStorage.setItem('workCities', JSON.stringify(cellarSelected));

      /// localStorage.setItem('shippingLocation', JSON.stringify(location));
      handleClosePopup();
      handleRecalculateProducts(cellarSelected.cellar_id);
      if (shippingCityCanChange && cellarSelected)
        /// deleteAllProducts();
        // localStorage.removeItem('shoppingCart');
        navigate('/catalogo');
    }
  };

  const handleRecalculateProducts = async (cellarId : number) => {
    let productsTemp = [...products];
    await Promise.all(products.map(async cartItem => {
      let productUpdate: Product;
      try {
        if (location === 'USA')
          productUpdate = await productService.getOneProductByKeyInCellar(cartItem.product.key, cellarId, true);
        else
          productUpdate = await productService.getOneProductByKeyInCellar(cartItem.product.key, cellarId, false);

        console.log(productUpdate);
        // Delete Object if not is avalable
        if (productUpdate.status === '0' || productUpdate.quantity === 0)
          productsTemp = productsTemp.filter(prod => prod.product.id !== productUpdate.id);
        else {
        // Update price and quantity
          const index = productsTemp.findIndex(cart => cart.product.id === productUpdate.id);
          if (index !== -1)
            productsTemp[index] = {
              ...productsTemp[index],
              product: productUpdate
            };
        }
      } catch (error) {
        productsTemp = productsTemp.filter(prod => prod.product.id !== cartItem.product.id);
      }
    }
    ));

    /// console.log(productsTemp);
    updateContext(old => ({
      ...old,
      products: productsTemp
    }));

    // Update Localstorage
    localStorage.setItem('shoppingCart', JSON.stringify(productsTemp));
  };

  const getCities = (stateId: string) => {
    if (!stateId) return;

    const cities = cellars.filter(cel => `${cel.city.states_id}` === stateId).map(item => ({
      label: item.city.name,
      value: `${item.city.id}`
    }));

    cities.sort((a, b) => a.label.localeCompare(b.label));

    setCities(cities);
  };

  return (
    <>
      <div className='w-full h-screen absolute z-1000 flex items-center justify-center top-0 left-0 bg-[#000000b6] lg:items-start lg:pt-32 font-titles'>
        <form
          className='relative w-full h-[420px] flex flex-col items-center justify-center max-w-[378px] overflow-y-hidden scroll-gray rounded-3xl bg-sixth  lg:w-auto lg:rounded-3xl'
          onSubmit={onSubmit}
        >
          <div className='w-full flex justify-end px-8'>
            {/* <IconButton
              name='close'
              size='3xl'
              className='absolute text-grayText top-3'
              onClick={handleClosePopup}
              shadow={false}
              sizeContainer='w-5 h-5'
            /> */}
          </div>
          <div className='flex flex-col gap-8 font-sanzBold px-5'>
            {shippingCityCanChange ?
              <div className='text-sm flex flex-col gap-4'>
                <p className='font-titles'>{t('shippingCityTitleOne')}</p>
                <p>{t('shippingCitySubtitleOne')}</p>
              </div>
              :
              <div className='text-sm flex flex-col gap-4'>
                <p className='font-titles'>{t('shippingCityTitleTwo')} {form.city?.value ? form.city.label : ''}?</p>
                <p>{t('shippingCitySubtitleTwo')}</p>
              </div>
            }
            <div className='flex flex-col font-subTitles gap-3'>
              <SelectField
                placeholder={t('checkSubmissionState')}
                name='state'
                options={states}
                /// options={form.states}
                value={form.state?.value ? form.state : undefined}
                /// onChange={handleSelectChange}
                onChange={(selected, name) => {
                  handleSelectChange(selected, name);
                  getCities((selected as SelectItem).value);
                }}
                borderRadius={true}
                borderColor='#000'
                paddingY='0.43rem'
                messageError={validatorBody.state.message}
                /// isClearable
                dropdownIndicatorColor='#33B5A9'
                maxHeightOptions='150px'
              />

              <SelectField
                placeholder={t('checkSubmissionCity')}
                name='city'
                options={cities}
                /// options={form.cities}
                value={form.city?.value ? form.city : undefined}
                onChange={handleSelectChange}
                borderRadius={true}
                borderColor='#000'
                paddingY='0.43rem'
                messageError={validatorBody.city.message}
                /// isClearable
                dropdownIndicatorColor='#33B5A9'
                maxHeightOptions='100px'
              />
            </div>
            { shippingCityCanChange ?
              <div className='flex gap-5'>
                <Button className={`w-full font-sanzBold mt-4 text-base
                border lg:text-lg border-primary text-primary`}
                type='button'
                padding='py-1'
                onClick={handleClosePopupForce}
                >
                  {t('shippingCityCancel')}
                </Button>
                <Button className={`w-full font-sanzBold mt-4 text-base
                lg:text-lg bg-primary text-[#fad7b1]`}
                type='submit'
                padding='py-1'
                /// disable={disabledButton}
                >
                  {t('shippingCityChange')}
                </Button>
              </div>
              :
              <Button
                type='submit'
                padding='py-1'
                className='w-full font-sanzBold mt-4 text-base lg:text-lg bg-primary text-[#fad7b1]'
              >
                {t('shippingCitySave')}
              </Button>
            }
          </div>
        </form>
      </div>
    </>
  );
};

export default PopupSelectShippingCity;
