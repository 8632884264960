import { Navigate } from 'react-router-dom';

import { useAppContext } from '../hooks';
import Loading from '../components/layout/loading';
import { useEffect, useState } from 'react';

const Protected = ({ children, authType = AUTH_TYPE.USER }: ProtectedProps) => {
  const { isLoadingPreData, isAuthenticated, wholesalerAuthenticated } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoadingPreData)
      setLoading(false);
  }, [isLoadingPreData]);

  return (
    <>
      {(loading && (isAuthenticated || wholesalerAuthenticated)) ?
        <Loading/>
        :
        <>
          {(isAuthenticated && authType === AUTH_TYPE.USER) && children }
          {(!isAuthenticated && authType === AUTH_TYPE.USER) && <Navigate to='/' />}
          {(wholesalerAuthenticated && authType === AUTH_TYPE.WHOLESALER) && children }
          {(!wholesalerAuthenticated && authType === AUTH_TYPE.WHOLESALER) && <Navigate to='/mayoristas/login' />}
        </>
      }
    </>
  );
};

export enum AUTH_TYPE {
  USER = 'user',
  WHOLESALER = 'wholesaler',
}
interface ProtectedProps {
  children: JSX.Element;
  authType?: AUTH_TYPE;
}

export default Protected;
