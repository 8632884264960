import { useTranslation } from 'react-i18next';
import { useLogout } from '../../../hooks';
import Icon from '../../common/icon';
import ItemMenuUser from '../../common/itemMenuUser';

const PopupMenuUser = ({ showMinimalNavbar }: PopupMenuUserProps) => {
  const logout = useLogout();
  const { t } = useTranslation();

  return (
    <div className={`${showMinimalNavbar ? 'fixed top-14 md:top-[3.9rem] animate__fadeInDown animate__faster lg:top-[4rem]' : 'absolute -top-2 md:top-0 animate__fadeIn lg:top-[7rem]'}
     right-14 sm:right-14 md:top-28 md:right-12 lg:right-[5.4rem] z-1000 animate__animated`}>
      <div className='container-menu-user'>
        <ItemMenuUser image='profile' name={t('userMenuMyProfile')} url='/usuario/perfil'/>
        <ItemMenuUser image='pets' name={t('userMenuMyPets')} url='/usuario/mascotas'/>
        <ItemMenuUser image='suscription' name={t('userMenuSubscription')} url='/usuario/suscripciones/historial'/>
        {/* <ItemMenuUser image='suscription' name={t('userMenuSubscription')} url='/usuario/perfil'/> */}
        <ItemMenuUser image='history' name={t('userMenuOrderHistory')} url='/usuario/ordenes/historial'/>
        <ItemMenuUser image='favorites' name={t('userMenuFavorites')} url='/usuario/productos/favoritos'/>
        <ItemMenuUser image='appointments' name={t('userMenuAppointments')} url='/usuario/citas'/>
        <div className='flex justify-center items-center font-sanzSemiBold text-xs mt-5 gap-2 cursor-pointer' onClick={logout}>
          <Icon name='logout' size='xs'/>
          <span> {t('userMenuLogOut')}</span>
        </div>
      </div>
    </div>
  );
};

interface PopupMenuUserProps {
  showMinimalNavbar: boolean
}

export default PopupMenuUser;
