import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCountry } from '../../../@fake/countrySelects';
import { arrow } from '../../../assets/vectors';
import { useAppContext } from '../../../hooks';
import { PixieContries } from '../../../helpers/pixieApp';
import { useLocation, useNavigate } from 'react-router-dom';

const paths = ['/suscripcion/crear', '/pago'];

const SelectCountry = ({ isMobile = false, color = 'text-grayText' }: SelectCountryProps) => {
  const navigate = useNavigate();
  const locationHook = useLocation();
  const { i18n } = useTranslation();

  const { updateContext, location: currentGeoLocation, countrySelected } = useAppContext();

  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    if (currentGeoLocation && selectCountry.find(item => item.name === currentGeoLocation)) {
      const index = selectCountry.findIndex(item => currentGeoLocation === item.name);
      if (index > -1)
        updateContext(old => ({ ...old, countrySelected: selectCountry[index].countryNumber === 3 ? selectCountry[0] : selectCountry[index] }));
    }
  },
  [currentGeoLocation]);

  useEffect(() => {
    // Filter products
    updateContext(old => ({ ...old, products: old.products.filter(item => item.product.country === countrySelected.countryNumber) }));
  }, [countrySelected]);

  const switchLang = (lang: 'es' | 'en' = 'es') => {
    i18n.changeLanguage(lang);
    localStorage.setItem('languaje', lang);
  };

  const handleChangeCountry = (name: PixieContries) => {
    const index = selectCountry.findIndex(item => name === item.name);
    const currentUrl = window.location.href;
    if (index > -1)
      updateContext(old => ({ ...old, countrySelected: selectCountry[index] }));
    switch (name) {
      case 'COL':
        location.href = 'https://pixie.net.co/';
        switchLang('es');
        break;
      case 'USA':
        if (`${process.env.REACT_APP_REDIRECT_PROD}` === 'true' && `${process.env.REACT_APP_REDIRECT_PROD_US}` !== currentUrl)
          // Redirect to the domain immediately
          window.location.href = `${process.env.REACT_APP_REDIRECT_PROD_US}`;
        else {
          localStorage.setItem('languaje', 'en');
          localStorage.setItem('currentCountry', 'USA');
          switchLang('en');
        }

        break;
      case 'MEX':
        if (`${process.env.REACT_APP_REDIRECT_PROD}` === 'true' && `${process.env.REACT_APP_REDIRECT_PROD_MX}` !== currentUrl)
          // Redirect to the domain immediately
          window.location.href = `${process.env.REACT_APP_REDIRECT_PROD_MX}`;
        else {
          localStorage.setItem('languaje', 'es');
          localStorage.setItem('currentCountry', 'MEX');
          switchLang('es');
        }

        break;
      default:
        break;
    }

    updateContext(old => ({ ...old, location: name, showPopupShippingCity: true }));
    localStorage.removeItem('cellarSelected');
    localStorage.removeItem('workCities');
    localStorage.removeItem('shippingLocation');
    localStorage.removeItem('shippingLocationCity');
    if (paths.includes(locationHook.pathname)) {
      localStorage.removeItem('shoppingCart');
      navigate('/catalogo');
    }
  };

  return (
    <div className='relative'>
      <div className='text-sm font-sanzBold flex justify-between items-center gap-2 cursor-pointer text-grayText'
        onClick={() => setOpenSelect(!openSelect)}
      >
        <div className={`flex items-center ${isMobile ? 'gap-3' : 'gap-1 '}`}>
          <img src={countrySelected.icon} className={`${isMobile ? 'w-7 h-7' : 'w-4 h-4'}`}/>
          <span className={`text-${color}`}>{countrySelected.name}</span>
        </div>
        <img src={arrow} className='w-2 h-2 rotate-[270deg] brightness-[1000]'/>
      </div>

      {openSelect &&
      <ul className='bg-white rounded-[10px] flex flex-col gap-4 absolute -top-28 md:top-6 px-2 py-2 font-sanzBold text-sm z-10'>
        {selectCountry.filter(item => item.countryNumber !== countrySelected.countryNumber).map(item =>
          <li className='flex gap-1 items-center cursor-pointer text-grayText'
            key={item.name}
            onClick={() => {
              handleChangeCountry(item.name);
              setOpenSelect(!openSelect);
            }}
          >
            <img src={item.icon} className={`${isMobile ? 'w-7 h-7' : 'w-4 h-4'}`}/>
            <span>{item.name}</span>
          </li>
        )}
      </ul>
      }
    </div>
  );
};

interface SelectCountryProps {
  isMobile?: boolean,
  color?: string
}

export default SelectCountry;
