import { basket, basketWhite } from '../../../assets/vectors';
import { useAppContext } from '../../../hooks';
import Button from '../../common/button';
import IconButton from '../../common/iconButton';

const ButtonBasketFixed = ({ handleClosePopup } : ButtonBasketFixedProps) => {
  const { products } = useAppContext();

  return (
    <div className='flex flex-col justify-center items-center gap-3'>
      <IconButton.mini
        img={basketWhite}
        name={basketWhite}
        type='outlined'
        imgClassName='w-7 h-7'
        sizeContainer={'w-11 h-11 md:w-[42px] md:h-[42px]'}
        className='mr-4 md:mr-0 bg-gray-600'
        size='md'
        shadow={false}
        onClick={() => {}}
      />
      {products.length > 0 && (
        <div
          className={`flex w-5 h-5 font-subTitles text-xs rounded-full
          absolute justify-center items-center text-white top-[1.8rem] right-[45%]
          md:right-[4.5rem] animate__animated animate__bounceIn bg-primary
          `}
        >
          {products.length}
        </div>
      )}
      <Button
        className='bg-primary flex gap-4 w-full lg:w-auto'
        rounded={true}
        onClick={handleClosePopup}
      >
        <img src={basket} className={'h-5 w-5'} />
        <span className='text-base text-amber-100'>Ir a tu canasta</span>
      </Button>
    </div>
  );
};

interface ButtonBasketFixedProps {
  handleClosePopup: () => void
}

export default ButtonBasketFixed;
