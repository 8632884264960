import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import TextField from '../../../components/form/textField';
import Button from '../../../components/common/button';

import useForm from '../../../hooks/useForm';
import useValidator from '../../../hooks/useValidator';
import { useAppContext, useLogin } from '../../../hooks';
import { validateAccount, validateAccountValidate } from '../../../interfaces/auth';
import authService from '../../../services/authService';

const FormResetCodeSection = ({ emailValidate, isCurrentValidation, setShowValidateCode }: FormResetCodeSectionProps) => {
  // Hooks
  const { toast } = useAppContext();

  const { setData } = useLogin({ email: '', password: '' });
  const { form, handleFormChange, onSubmit } = useForm<validateAccount>(
    {
      email: emailValidate,
      code: '',
    },
    () => validateForm()
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { handlePutMessageError, validatorBody, resetValidator } = useValidator<validateAccountValidate>({
    code: { message: '' },

  });

  // Methods
  const validateForm = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (!validator.isLength(form.code, { min: 6 })) {
      handlePutMessageError('code', 'El codigo debe tener 6 caracteres');
      error = true;
    }

    if (!error)
      handleSubmit();
  };

  // Send data
  const handleSubmit = () => {
    authService.validateUser(form, language)
      .then(res => {
        toast.fire({
          icon: 'success',
          title: 'Usuario validado correctamente',
        });
        localStorage.removeItem('emailValidate');
        setData(res);
      })
      .catch(err =>
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: err,
        })
      );
  };

  const handleDeleteValidate = () => {
    localStorage.removeItem('emailValidate');
    setShowValidateCode(false);
  };

  return (
    <form
      onSubmit={onSubmit}
      className='w-full flex flex-col lg:gap-5 text-sm lg:pr-6 z-10 font-subTitles justify-between items-center h-full'
    >
      <div className='mb-5 lg:mb-0 mt-20 flex flex-col'>
        <h2 className='text-lg font-titles text-fourth lg:text-left lg:text-[23px] '>
          {t('verifyTitle')}
        </h2>
        <p className='text-sm text-center leading-tight pt-3 lg:text-lg lg:text-left'>
          {t('verifyDescription')}
        </p>
      </div>
      <TextField
        name='code'
        value={form.code}
        handler={handleFormChange}
        className='w-full'
        fieldClassName='py-[0.95rem] px-7 rounded-[16px]'
        messageError={validatorBody.code.message}
      />
      {isCurrentValidation &&
        <p>{t('verifyDetectedCode')}
          <span
            onClick={handleDeleteValidate}
            className='cursor-pointer hover:text-black font-sanzBold text-left'
          >
            {t('verifyDetectedCode2')}
          </span>
        </p>
      }
      <div className='mt-9 flex flex-col items-center justify-center lg:mt-5 gap-5 lg:gap-10'>
        <Button type='submit'
          className='bg-primary text-[#fad7b1] px-[3.5rem] lg:py-4 rounded-xl font-sanzBold text-base'
          padding='py-2 lg:py-[0.7rem]'
        >
          {t('login')}
        </Button>
      </div>
    </form>
  );
};

interface FormResetCodeSectionProps {
  emailValidate: string,
  isCurrentValidation: boolean,
  setShowValidateCode: Dispatch<SetStateAction<boolean>>,
}

export default FormResetCodeSection;
