import { useTranslation } from 'react-i18next';
import { emptyAppointment } from '../../../../assets/images';
import Button from '../../../../components/common/button';

const EmptyAppointmentSection = ({ setShowCreate }: EmptyAppointmentSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col items-center text-center gap-6 h-screen lg:h-auto lg:justify-center lg:gap-7 lg:mt-10'>
      <div className='bg-[#D9EEEF] rounded-full w-[9.9rem] h-[9.9rem] p-3 lg:w-[14.8rem] lg:h-[14.8rem] lg:p-5 flex justify-start items-center'>
        <img
          src={emptyAppointment}
          className='object-contain w-full h-full opacity-80'
        />
      </div>
      <span className='text-lg text-pixieLightBlue px-8 leading-tight lg:px-0 lg:text-[25px]'>
        Aún no has agendado ninguna cita
      </span>
      <Button
        className='font-sanzBold text-white bg-primary text-lg lg:mt-6'
        padding='px-10 py-2'
        onClick={() => setShowCreate(true)}
      >
        {t('myAppoCreate')}
      </Button>
    </div>
  );
};

interface EmptyAppointmentSectionProps {
  setShowCreate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default EmptyAppointmentSection;
