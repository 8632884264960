/* eslint-disable quotes */
export const checkSubmissionForm = {
  checkSubmissionTitleForm: 'Contact Information',
  checkSubmissionEmail: 'Email*',
  checkSubmissionPhone: 'Cell phone*',
  checkSubmissionNew: 'Send me news and offers via email',
  checkSubmissionDeliveryTitle: 'Delivery address',
  checkSubmissionFirstName: 'First name*',
  checkSubmissionLastName: 'Last name*',
  checkSubmissionStreetName: 'Street name*',
  checkSubmissionExteriorNumber: 'Exterior number, house, or apartment*',
  checkSubmissioninteriorNumber: 'Interior number (floor, office, etc..)',
  checkSubmissionColony: 'Colony*',
  checkSubmissionCountry: 'Country*',
  checkSubmissionAddressSaved: 'Saved addresses',
  checkSubmissionState: 'State*',
  checkSubmissionCity: 'City*',
  checkSubmissionBetweenStreets: 'Between streets',
  checkSubmissionDelegation: 'Delegation / Municipality*',
  checkSubmissionZip: 'Zip code*',
  checkSubmissionSaveinformation: 'Save my information for my next purchase',
  checkUploadPayment: 'Upload proof of payment',
  checkSubmissionTermsAndConditionPart1: 'By continuing, you agree to ',
  checkSubmissionTermsAndConditionPart2: "Pixie's Conditions ",
  checkSubmissionTermsAndConditionPart3: 'of ',
  checkSubmissionTermsAndConditionPart4: 'use and Privacy Policy*',
  checkSubmissionBack: 'Back to cart',
  checkSubmissionContinue: 'Continue with delivery',
  checkSubmissionWholesalerSubmitOrder: 'Create order',
  checkSubmissionsShowResumePart1: 'Show order',
  checkSubmissionsShowResumePart2: 'summary',
  checkConstancyBusinessName: 'Name or Social reason',
  checkConstancyRfc: 'RFC',
  checkConstancyRfcTooltip: 'Federal taxpayer registration (limit of 700kb)',
  checkConstancyPDF: 'Upload the proof of fiscal situation (Optional)',
  checkConstancyPostalCode: 'Postal code',
  checkConstancyRegimen: 'Regime',
  checkQuestionTitleSub: 'Do you want to change your subscription?',
  checkQuestionSubtitleSub: 'No problem, click here to change it',
  checkQuestionChangeSub: 'change subscription',
  deliverySupport: 'Delivery support',
  addDeliverySupport: 'Add delivery support',

};
