import { HolidaysDays, workCities } from '../../interfaces/store';
import api from '../axios';

class WholeStoreService {
  init() {}

  getCellarsActives = (countryId: number): Promise<workCities[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/work-days-cities/for-wholesaler?country=${countryId}&limit=9999`)
        .then(response => {
          resolve(response.data.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  getOneWorkCity = (cityId: string): Promise<workCities> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/work-days-cities/city_id/${cityId}`)
        .then(response => {
          if (response.data.data === null)
            reject();
          else
            resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });

  getHolidaysCity = (cityId: string, countryId: number): Promise<HolidaysDays[]> =>
    new Promise((resolve, reject) => {
      api.get(`pixie-payments/api/holidays-days?country_id=${countryId}&city_id=${cityId}`)
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
}

export default new WholeStoreService();

