import { createContext } from 'react';

import Swal from 'sweetalert2';
import { ReactSweetAlert } from 'sweetalert2-react-content';

import { appProvider as provider } from './provider';
import { Product } from '../interfaces/product';
import { CartItem } from '../interfaces/basket';
import { SubmissionFormInterface } from '../interfaces/checkout';
import { AppContextTypeUser } from './indexUser';
import { couponComplete } from '../interfaces/coupon';
import { LocationShipping } from '../interfaces/location';
import { Cellars, workCities } from '../interfaces/store';
import { PixieContries } from '../helpers/pixieApp';
import { Section } from '../interfaces';
import { IWholesaler } from '../interfaces/wholesaler';
import { FormStepOneAppointment } from '../interfaces/appointment';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppContext = createContext<AppContextType>(null!);

export interface AppContextType extends AppContextTypeUser {
  // Generic
  api: string;
  swal: typeof Swal & ReactSweetAlert;
  toast: typeof Swal;
  updateContext: React.Dispatch<React.SetStateAction<AppContextType>>;

  // Products
  products: CartItem[];

  // Discounts
  discount: number;

  // Productview
  productView: Product;

  // ShowNavbar
  showNavbar: boolean;

  // Add margin button Whatsapp
  marginWhatsApp : boolean;

  // ShowPopup
  showPopup: boolean;

  // DataFormCheck
  dataFormCheckOut: SubmissionFormInterface;
  taxes: number;

  // Provitional data to recomendations section
  productsToShowRecomendation : Product[];

  // Show minimal Navbar
  showMinimalNavbar : boolean;

  // Show loading popup
  showLoading: boolean;

  // Show popup viewer Image
  showPopupViewerImage: {
    url: string,
    show: boolean,
  };

  // Show popup catalogue products
  showPopupMoreProducts: boolean;

  isMobile: boolean

  showPopupGotoSite: boolean;
  showPopupTermsAndConditions: {
    status: boolean,
    type: 1 | 2
  };

  location: PixieContries;
  showPopupAuth: {
    status: boolean,
    view: number
  };
  showPopupAuthWholesaler: {
    status: boolean,
    view: number
  };
  showPopupShippingCity: boolean;
  showPopupShippingCityWholesaler: boolean;
  shippingCityCanChange: boolean;
  showSideBarMobile: boolean,
  countrySelected: { name: string, icon: string, countryNumber: number },
  coupon: couponComplete | undefined,
  languaje: string,
  isChangeCurrentShippingAccesories: boolean,
  isLoginSubscription: boolean,
  locationShipping: LocationShipping,
  cellarSelected?: Cellars,
  workCities?: workCities,
  lastLocationAndScroll: {
    path: string,
    scroll: number
  },
  daySelectNormalPurchase: string,

  // Content Manager
  contentManeger: Section[];

  // Wholesaler
  wholesaler?: IWholesaler;
  wholesalerAuthenticated?: boolean;

  // Appointment
  validateFormStepOne: boolean

  /// modal calculador
  showModalCalculador: boolean
}

export const appProvider: AppContextType = provider;
