const ContainerPopupUser = ({ children }:ContainerPopupProps) => (
  <div className='absolute z-1000 justify-center -top-[6rem] -left-0 -bottom-[10px] bg-[#000000b6] lg:-top-[0px] lg:-bottom-[10px] lg:left-[0px] lg:right-[0px] lg:items-center min-h-[2000px] w-full'>
    <div className={` flex justify-center items-center lg:mx-6 mt-16 rounded-t-3xl lg:rounded-3xl lg:w-auto
    text-[15px] lg:text-xl lg:py-5
    `}>
      {children}
    </div>
  </div>

);

interface ContainerPopupProps {
  children: JSX.Element
}

export default ContainerPopupUser;
