/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import IconButton from '../../components/common/iconButton';
import StepOneAppointment from './StepOneAppointment';
import StepTwoAppointment from './StepTwoAppointment';
import Button from '../../components/common/button';
import { FormStepOneAppointment, FormStepTwoAppointment } from '../../interfaces/appointment';
import { useAppContext } from '../../hooks';
import appointmentService from '../../services/appointmentsService';

const CreateAppointment = ({ handleClose }: CreateAppointmentProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dataStepOne, setDataStepOne] = useState<FormStepOneAppointment>();
  const [loading, setLoading] = useState(false);
  const { updateContext, toast } = useAppContext();

  const handleNextStep = (data: FormStepOneAppointment) => {
    setDataStepOne(data);
    setCurrentStep(currentStep + 1);
  };

  const saveFiles = async (dataStepTwo: FormStepTwoAppointment, files: FileList | null) => {
    const keys: string[] = [];
    if (files)
      for (const file of files)
        try {
          setLoading(true);
          const res = await appointmentService.uploadFileTestimonial(file);
          keys.push(res);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }

    saveData(keys, dataStepTwo);
  };

  const saveData = (keys: string[], dataStepTwo: FormStepTwoAppointment) => {
    if (dataStepOne) {
      setLoading(true);
      appointmentService.sendDataAppointment(dataStepOne, dataStepTwo, keys).then(res => {
        setLoading(false);
        handleClose();
        toast.fire({
          icon: 'success',
          title: 'Cita creada correctamente'
        });
      }).catch(err => {
        toast.fire({
          icon: 'error',
          title: err.message
        });
        setLoading(false);
      });
    }
  };

  return (
    <div className='w-full h-screen absolute z-[9999] flex items-end justify-center top-0 left-0 bg-[#000000b6] lg:items-center font-titles' style={{ overflowY: 'hidden' }}>
      <div className='w-full h-full max-w-[762px] flex flex-col rounded-3xl overflow-y-scroll scroll-gray bg-sixth relative gap-12 px-5 lg:px-[5.7rem] max-h-[90vh] lg:gap-10 pb-20'>
        <IconButton
          size='3xl'
          name='close'
          sizeContainer='w-5 h-5'
          className='absolute top-6 right-6 text-grayText z-30'
          shadow={false}
          onClick={handleClose}
        />
        <h3 className='text-xl lg:text-[30px] text-primary text-left mt-14'>Solicita tu cita </h3>

        {currentStep === 0 && <StepOneAppointment handleNextStep={handleNextStep} />}
        {currentStep === 1 && <StepTwoAppointment handleSendData={saveFiles}/>}

        <div className='flex gap-3 justify-center items-center'>
          {Array.from({ length: 2 }).map((_, index) => (
            <div
              key={index}
              className={`w-[6.2px] h-[6.2px] cursor-pointer rounded-full ${index === currentStep ? 'bg-black' : 'bg-[#969696]'} hover:scale-125`}
              onClick={() => setCurrentStep(index)}
            />
          ))}
        </div>

        <div className='flex justify-center items-center w-full lg:w-auto'>
          <Button
            className={`${currentStep === 0 ? 'bg-pixieLightBlue' : 'bg-primary'}  text-white text-lg font-sanzBold md:rounded-[20px] w-full lg:w-auto`}
            padding='py-3 md:px-16 md:py-3'
            rounded={currentStep === 0}
            disable={loading}
            onClick={() => {
              updateContext(old => ({
                ...old,
                validateFormStepOne: true
              }));
            }}
          >
            {currentStep === 0 ? 'Siguiente' : 'Confirmar cita'}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface CreateAppointmentProps {
  handleClose: () => void
}

export default CreateAppointment;
