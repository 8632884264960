import { useAppContext } from '../../../hooks';

import IconButton from '../../common/iconButton';

import BannerDetail from '../../../views/Detail/bannerDetail';
import BannerDetailDT from '../../../views/Detail/BannerDetailDT';
import { organizeAttributes, organizeIngredients } from '../../../helpers/detailHelper';
import InfoSection from '../../../views/Detail/InfoSection';
import NutritionSection from '../../../views/Detail/NutritionSection';
import ExtraInfoContainer from '../../../views/Detail/ExtraInfoContainer';
import InfoAccordion from '../../../views/Detail/InfoAccordion';
import { useEffect, useState } from 'react';
import productService from '../../../services/productService';
import { infoSelectSPandEn } from '../../../interfaces/product';
import { useTranslation } from 'react-i18next';

const PopupDetailProduct = () => {
  // Hooks
  const { updateContext, productView } = useAppContext();
  const [allIngredients, setallIngredients] = useState<infoSelectSPandEn[]>();
  const [allAttributes, setAllAttributes] = useState<infoSelectSPandEn[]>([]);

  // Methods
  const handleClosePopup = () => {
    updateContext(old => ({ ...old, showPopup: false }));
  };

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    getPreviousData();
    return () => {};
  }, []);

  const getPreviousData = () => {
    productService.getIngredients().then(res => {
      setallIngredients(res);
    }).catch(err => {
      console.log(err);
    });

    productService.getAttributes().then(res => {
      setAllAttributes(res);
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <>
      {allIngredients &&
      // <div className='absolute z-1000 flex items-center justify-center -top-[10px] -left-1 -right-1 -bottom-[10px] bg-[#000000b6] lg:-left-[10px] lg:-right-[10px]'>
      <div className='w-full h-screen absolute z-1000 flex items-center justify-center top-0 left-0 p-4 bg-[#000000b6] '>
        <div className='w-full h-full relative pt-20 pb-10 rounded-3xl bg-sixth scroll-gray overflow-y-scroll md:px-8 md:pb-0 lg:w-auto'>
          <IconButton
            size='3xl'
            name='close'
            shadow={false}
            sizeContainer='w-5 h-5'
            className='absolute top-6 right-6 text-grayText'
            onClick={() => handleClosePopup()}
          />
          <div className='h-auto max-w-[1250px]'>
            <div className='flex flex-col w-full flex-shrink-0 overflow-hidden'>
              <div className='w-full flex-grow flex flex-col flex-shrink-0 md:flex-row md:pb-10 md:gap-1'>
                {/* Banner Detail to mobile */}
                <BannerDetail product={productView} />
                {/* Banner Detail to desktop */}
                <BannerDetailDT />
                <InfoSection product={productView} attributes={organizeAttributes(productView.atributos, allAttributes)} showControls={false}/>
              </div>
            </div>

            {/* Nutrition */}
            <NutritionSection ingredients={organizeIngredients(productView.ingredients, allIngredients, language)} />

            <ExtraInfoContainer product={productView} paddingModal />
          </div>
          {/* Other Info */}
          <InfoAccordion product={productView} />
        </div>
      </div>
      }
    </>
  );
};

export default PopupDetailProduct;
