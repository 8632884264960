/* eslint-disable curly */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { mexicoFlag } from '../assets/vectors';
import { CartItem } from '../interfaces/basket';

import { AppContextType } from './index';
import { appProviderUser } from './providerUser';
import { LocationShipping } from '../interfaces/location';
import moment from 'moment';

const MySwal = withReactContent(Swal);
const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  showCloseButton: true,
  timer: 1500,
  timerProgressBar: true,
  didOpen(toast) {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const api = process.env.REACT_APP_API_URL;

/// Get data saved
const dataFormCheckOutLS = JSON.parse(localStorage.getItem('dataFormCheckOut') as unknown as string);
let shoppingCar: CartItem[] = JSON.parse(localStorage.getItem('shoppingCart') as unknown as string);
const locationShipping: LocationShipping | null = JSON.parse(localStorage.getItem('shippingLocation') as string);

const verifyLocalStorage = () => {
  const expDate = localStorage.getItem('expirateDate');
  if (expDate === null) {
    localStorage.setItem('expirateDate', moment().toString());
  } else
    if (moment().isAfter(expDate, 'day')) {
      shoppingCar = [];
      localStorage.removeItem('shoppingCart');
      localStorage.removeItem('subscriptionData');
      localStorage.setItem('expirateDate', moment().toString());
    }
};

verifyLocalStorage();

export const appProvider: AppContextType = {
  // User Context
  ...appProviderUser,
  // Generic
  swal: MySwal,
  toast: Toast,
  updateContext: old => old,
  api,

  // Products
  products: shoppingCar === null ? [] : shoppingCar,

  // Discount
  discount: 0,
  // ProductView
  productView: {
    age: '',
    category: 'Accesorios',
    description: '',
    id: '',
    ingredients: [],
    kind_pet: '',
    License: '',
    name: '',
    nutrition_information: [],
    presentation: '',
    price: 0,
    quantity: 0,
    status: '',
    url_image: '',
    benefits: [],
    recommendation_for_use: '',
    atributos: [],
    key: '',
    atributos_en: [],
    benefits_en: [],
    country: 0,
    description_en: '',
    dosis: '',
    dosis_en: '',
    ingredients_en: [],
    key_en: '',
    name_en: '',
    nutrition_information_en: [],
    recommendation_for_use_en: '',
  },
  dataFormCheckOut: dataFormCheckOutLS === null ? {
    address: '',
    apartment: '',
    city: { label: '', value: '' },
    cities: [],
    colony: '',
    countries: [],
    country: { label: '', value: '' },
    delegation: '',
    delivery_note: '',
    email: '',
    houseNumber: '',
    last_name: '',
    name: '',
    phone: '',
    receive_information: '0',
    reference: '',
    state: { label: '', value: '' },
    states: [],
    zip_code: { label: '', value: '' },
    zipcodes: [],
  } : dataFormCheckOutLS,
  taxes: 0,
  showNavbar: true,
  marginWhatsApp: false,
  showPopup: false,
  showMinimalNavbar: false,
  showLoading: false,
  showPopupViewerImage: {
    show: false,
    url: ''
  },
  showPopupGotoSite: true,
  // Provitional data to show recomendation
  productsToShowRecomendation: [],
  location: 'MEX',
  showPopupTermsAndConditions: {
    status: false,
    type: 1
  },
  showPopupAuth: {
    status: false,
    view: 1
  },
  showPopupAuthWholesaler: {
    status: false,
    view: 1
  },
  showPopupShippingCity: false,
  showPopupShippingCityWholesaler: false,
  showPopupMoreProducts: false,
  shippingCityCanChange: false,
  showSideBarMobile: false,
  isMobile: false,
  countrySelected: { name: 'MEX', icon: mexicoFlag, countryNumber: 1 },
  coupon: undefined,
  languaje: 'es',
  isChangeCurrentShippingAccesories: false,
  isLoginSubscription: false,
  locationShipping: locationShipping === null ? {
    city: '',
    state: '',
  } : locationShipping,
  cellarSelected: undefined,
  workCities: undefined,
  lastLocationAndScroll: {
    path: '',
    scroll: 0
  },
  daySelectNormalPurchase: '',

  // Content Manager
  contentManeger: [],
  validateFormStepOne: false,
  showModalCalculador: false
};
