import api from '../axios';
import { AllSectionsResponse } from '../../interfaces/contentManager';

interface ApiResponse<T> {
  data: T | null;
  error: string | null;
}

type SectionByCountryProps = {
  country: string;
}

export const getSectionByCountry = async ({ country }: SectionByCountryProps): Promise<ApiResponse<AllSectionsResponse>> => {
  const authorization = window.localStorage.getItem('jwt_access_token');
  const URL = 'pixie-customers/api/content-management';
  const config = {
    headers: { Authorization: authorization ? authorization : '' },
  };

  try {
    // Verificar si los datos ya están en sessionStorage
    const dataStorage = sessionStorage.getItem('dataSections');
    if (dataStorage) {
      const dataSections = JSON.parse(dataStorage);
      return { data: dataSections, error: null };
    }

    // Si no, obtener los datos
    const response = await api.get<AllSectionsResponse>(`${URL}/${country}`, config);

    sessionStorage.setItem('dataSections', JSON.stringify(response.data));

    return { data: response.data, error: null };
  } catch (error) {
    if (error instanceof Error)
      return { data: null, error: error.message || 'Error desconocido' };

    return { data: null, error: 'Error desconocido' };
  }
};
