import { recoveryImage, logo } from '../../../assets/images';

const ImageRecoverySection = () => (
  <div className='w-full h-full flex flex-col lg:justify-center items-center' >
    <img
      src={recoveryImage}
      className='h-[17rem] object-cover sm:w-[400px] w-full lg:h-auto'
    />
    <img
      src={logo}
      className='w-16 absolute top-[15rem] sm:top-[16.5rem] lg:w-20 lg:top-[40rem]'
    />
  </div>
);

export default ImageRecoverySection;
