import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { iconUserDefault, iconMyPets, iconCalendar, iconPixieFood, iconFavorite, iconAppointment } from '../../../assets/vectors';

const ItemMenuUser = ({ image, name, url }:ItemMenuUserProps) => {
  const [imageUrl, setImageUrl] = useState(iconUserDefault);
  const navigate = useNavigate();

  useEffect(() => {
    switch (image) {
      case 'profile':
        setImageUrl(iconUserDefault);
        break;
      case 'pets':
        setImageUrl(iconMyPets);
        break;
      case 'suscription':
        setImageUrl(iconCalendar);
        break;
      case 'history':
        setImageUrl(iconPixieFood);
        break;
      case 'favorites':
        setImageUrl(iconFavorite);
        break;
      case 'appointments':
        setImageUrl(iconAppointment);
        break;
      default:
        break;
    }

    return () => {};
  }, []);

  const handleChangeRoute = () => {
    navigate(url);
  };

  return (
    <div className='flex items-center justify-start gap-7 border-b border-fourth pb-4 w-full pt-4 px-2 cursor-pointer' onClick={handleChangeRoute}>
      <div className='bg-pixieLightBlue rounded-full p-1'>
        <img src={imageUrl} alt={imageUrl} className='w-4 h-4'/>
      </div>
      <div className='text-pixieLightBlue text-base hover:text-fourth'>
        {name}
      </div>
    </div>
  );
};

interface ItemMenuUserProps {
  image: 'profile' | 'pets' | 'suscription' | 'history' | 'favorites' | 'appointments',
  name: string
  url: string
}

export default ItemMenuUser;
