import { SelectItem } from '../components/form/selectField';

export const agesDog: SelectItem[] = [
  { value: 'cachorros', label: 'Cachorro (2 a 12 meses)' },
  { value: 'adultos', label: 'Adulto (1 a 7 años)' },
  { value: 'senior', label: 'Senior (7 años en adelante)' },
];

export const agesDogEn: SelectItem[] = [
  { value: 'cachorros', label: 'Puppy (2 to 12 months)' },
  { value: 'adultos', label: 'Adults (1 to 7 years)' },
  { value: 'senior', label: 'Senior (7 years or more)' },
];

export const agesCat: SelectItem[] = [
  { value: 'cachorros', label: 'Cachorro (2 a 12 meses)' },
  { value: 'adultos', label: 'Adulto (1 a 8 años)' },
  { value: 'senior', label: 'Senior (8 años en adelante)' },
];

export const agesCatEn: SelectItem[] = [
  { value: 'cachorros', label: 'Puppy (2 to 12 months)' },
  { value: 'adultos', label: 'Adults (1 to 8 years)' },
  { value: 'senior', label: 'Senior (8 years or more)' },
];

export const types: SelectItem[] = [
  { value: 'dog', label: 'Perro' },
  { value: 'cat', label: 'Gato' },
];

export const typesEn: SelectItem[] = [
  { value: 'dog', label: 'Dog' },
  { value: 'cat', label: 'Cat' },
];

export const exercises: SelectItem[] = [
  { value: 'low', label: 'Baja' },
  { value: 'medium', label: 'Normal' },
  { value: 'high', label: 'Alta' },
];

export const exercisesEn: SelectItem[] = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Normal' },
  { value: 'high', label: 'High' },
];

export const agesPuppies: SelectItem[] = [
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
];

export const agesAdults: SelectItem[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
];

export const agesSenior: SelectItem[] = [
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
];
