export const createSubscription = {
  createSubTitle: 'Build your subscription',
  stepFrequency: 'Frecuency',
  stepShipping: 'Shipping',
  stepQuantity: 'Quantity',
  paymentFrequency: 'Payment Frequency Options for Your Subscription',
  selectOption: 'Select',
  giftMessageStart: 'With any option you will receive a monthly gift and your shipment will be free if the value of your subscription exceeds',
  giftMessageEnd: 'per month. Keep in mind that the next debit will be made on the day of your last scheduled delivery',
  deliveryFrequency: 'How often would you like to receive your order?',
  deliveryMessageStart: 'You will receive',
  deliveryMessageEnd: 'boxes, each containing 20 units. (You can adjust the quantities in the next step if needed.)',
  choiceOfDayOne: 'Pick the Dates for Your Initial Order Delivery',
  choiceOfDayTwo: 'Pick the Dates for Your Initial Order Delivery',
  choiceDayPlaceholder: 'Choose the day',
  deliveryMessageOnlyDate: 'Your First Delivery will be on',
  deliveryMessageTwoDateStart: '*Your deliveries will be the',
  deliveryMessageTwoDateEnd: 'starting from the',
  quantityOfProductToReceive: 'These are the products you will receive in each delivery:',
  productWithDifferentDelivery: 'Select the products you want to receive on a different frequency or schedule:',
  resumeSeeDetails: 'See Details',
  resumePaymentFrequency: 'Payment Frequency',
  resumePaymentFrequencyOne: 'Every',
  resumePaymentFrequencyMonth: 'month',
  resumePaymentFrequencyMonths: 'months',
  resumeDeliveryFrequency: 'Delivery Frequency',
  resumeDeliveryDay: 'Delivery Day',
  resumneShippingCostPerDelivery: 'Shipping Cost per Delivery',
  resumeTotalDeliveries: 'Total Deliveries',
  resumenTotalPaymentNextStep: 'In the next step you will know the total to pay according to the selected delivery frequency',
  questionSectionTitle: 'Did you need to add any product?',
  questionNoProblemAdd: 'No worries, you can add what\'s missing right here!',
  questionSeeShop: 'See Shop',
  createSubBack: 'Back',
  createSubNext: 'Next',
  prePay: 'Prepaid',
  errorSelectDay: 'You must select a day of the week and the delivery frequency',
};
