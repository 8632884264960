import moment from 'moment';
import TextField from '../../../../../components/form/textField';
import { Appointment } from '../../../../../interfaces/appointment';

const optionsRadio = [
  { label: 'Meet', value: 'meet' },
  { label: 'Whatsapp / Telegram (USA)', value: 'whatsapp' },
  { label: 'Llamada telefónica', value: 'call' },
];

const GeneralInfoAppointmentSection = ({ appointment }: GeneralInfoItemProps) => (
  <div className='flex flex-col gap-2 md:gap-9 font-subTitles text-sm'>
    <h3 className='text-left text-pixieLightBlue text-lg font-titles'>Informacion general</h3>
    <div className='flex flex-col gap-3 md:grid md:grid-cols-2'>
      <TextField
        handler={() => {}}
        name='name'
        value={appointment.vet_name}
        placeholder='Nombre veterinario'
        fieldClassName='py-3.5 pl-6'
      />
      <TextField
        handler={() => {}}
        name='date'
        value={moment(appointment.report_date).format('DD/MM/YYYY')}
        placeholder='Fecha'
        fieldClassName='py-3.5 pl-6'
      />
      <TextField
        handler={() => {}}
        name='hour'
        value={moment(appointment.report_date).format('hh:mm a')}
        placeholder='Hora'
        fieldClassName='py-3.5 pl-6'
      />
      <TextField
        handler={() => {}}
        name='contact_preference_type'
        value={optionsRadio.find(option => option.value === appointment.contact_preference_type)?.label || ''}
        placeholder='Medio'
        fieldClassName='py-3.5 pl-6'
      />
    </div>
  </div>
);

interface GeneralInfoItemProps {
  appointment: Appointment
}

export default GeneralInfoAppointmentSection;
