export const login = {
  loginHasAccount: 'Don\'t you have an account?',
  loginSignUp: 'Sign up',
  login: 'Log in',
  loginHere: 'Log in here',
  loginSocialMedia: 'Log in with ',
  loginTitle: 'HELLO!',
  wholesalerLoginTitle: 'HELLO WHOLESALER!',
  LoginsignIn: 'Sign in to your account',
  LoginImagenText: 'Did you know that a dog\'s nose is equivalent to its fingerprint?',
  loginEmail: 'Email address',
  loginPassword: 'Password',
  loginRemember: 'Remember me',
  loginRecoverPassword: 'I forgot my password',
  loginAlsoText: 'Or you can also',
  loginNotWholesaler: 'If you are not a wholesaler...',
  loginSuccess: 'Successfully logged in',
  logoutSuccess: 'Successfully logged out'
};

export const register = {
  registerHasAccount: 'Already have an account?',
  registerSignUp: 'Sign in',
  registerTitle: 'Let\'s begin',
  registeName: 'Name',
  registeLastName: 'Last name',
  registeEmail: 'Email Address',
  registerPhone: 'Phone number',
  registerBornDate: 'Born date YYYY-MM-DD',
  registerGender: 'Gender',
  registerPassword: 'Password',
  registerConfirmPassword: 'Confirm password',
  registerCreate: 'Create'
};

export const recovery = {
  recoveryTitle: 'Did you forget your password?',
  recoveryDescription: 'Enter your email address and we will send you instructions to recover it.',
  recoverySend: 'Send',
  recoveryReturn: 'Return',
  recoverySenEmail: 'We have sent an email for you to recover your password!'
};

export const verifyCode = {
  verifyTitle: 'Enter the verification code.',
  verifyDescription: 'Enter the 6-digit code that we sent to your email.',
  verifyDetectedCode: 'We have detected that you have a pending activation code, If this is not the case, please ',
  verifyDetectedCode2: 'click here.'
};
