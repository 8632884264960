import moment from 'moment';
import { SelectItem } from '../components/form/selectField';
import { FormAppointmentPetInt, blockSchedule, calendarWorkVet } from '../interfaces/appointment';

const daysCode = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const validatePetForm = (pets: FormAppointmentPetInt[]): boolean => {
  let returnValue = true;
  pets.forEach(pet => {
    if (pet.name === '')
      returnValue = false;

    if (pet.type.value === '')
      returnValue = false;

    if (pet.weight === '')
      returnValue = false;

    if (pet.food === '')
      returnValue = false;

    if (pet.physical_activity.value === '')
      returnValue = false;

    if (pet.date_birth === '')
      returnValue = false;

    if (pet.have_diseases === true)
      if (pet.diseases.length === 0)
        returnValue = false;
  });

  return returnValue;
};

export const getDaysDisabledByKeyDay = (keyDay: string[]) => {
  const daysDisabled: number[] = [];

  keyDay.forEach(item => {
    daysDisabled.push(daysCode[item as keyof typeof daysCode]);
  });

  return daysDisabled;
};

export const getOptionsSelectHour = (hoursWorkData: calendarWorkVet, dateSelected: string, disabledHours: blockSchedule[]): SelectItem[] => {
  const options: SelectItem[] = [];
  const numberDay = moment(dateSelected).day();
  const dayName = Object.keys(daysCode)[numberDay];
  const hourStartInNumber = hoursWorkData[dayName as keyof typeof hoursWorkData].startDate.split(':')[0];
  const hourEndInNumber = hoursWorkData[dayName as keyof typeof hoursWorkData].endDate.split(':')[0];
  // Get block dates between start and end
  const disabledHoursFiltered = disabledHours.filter(item => item.end_datetime >= dateSelected && item.start_datetime <= dateSelected);
  const disabledHourArray = disabledHoursFiltered.map(item => {
    const hourStartInNumber = item.start_hour.split(':')[0];
    const hourEndInNumber = item.end_hour.split(':')[0];
    return {
      startHour: Number(hourStartInNumber),
      endHour: Number(hourEndInNumber)
    };
  });

  for (let hour = Number(hourStartInNumber); hour <= Number(hourEndInNumber); hour++) {
    const hourMilitarToNomal = hour < 10 ? `0${hour}` : `${hour}`;
    const momentTime = moment(hourMilitarToNomal, 'HH:mm');
    const normalTime = momentTime.format('hh:mm A');
    if (!disabledHourArray.some(range => hour >= range.startHour && hour <= range.endHour))
      options.push({
        label: normalTime,
        value: `${hour}:00`
      });
  }

  return options;
};
