import { wholesalerLogin, logo } from '../../../assets/images';

interface Props {
  classModal?: boolean;
}

const ImageLoginSection = ({ classModal }: Props) => (
  <div className={`w-full flex flex-col justify-center items-center bg-white relative ${classModal ? 'h-full mt-0' : 'mt-16'}`}>
    <img src={wholesalerLogin} alt={wholesalerLogin} className='object-fill w-full h-full' />
    <img
      src={logo}
      className='w-20 h-20 mt-4 absolute top-[10%] transform'
    />
  </div>
);

export default ImageLoginSection;
