import { useAppContext } from '../../../hooks';

import IconButton from '../../common/iconButton';
import { useEffect, useState } from 'react';
import productService from '../../../services/productService';
import { Product } from '../../../interfaces/product';
import AnimalFilter from '../../common/animalFilter';
import { filterShop } from '../../../interfaces/filter';
import ProductsSection from '../../../views/wholesaler/catalogueWholesaler/productsSection';
import ButtonBasketFixed from './ButtonBasketFixed';

const PopupCatalogue = ({ handleClosePopup }: PopupCatalogueProps) => {
  // Hooks
  const { location } = useAppContext();
  const [allProducts, setAllProducts] = useState<Product[]>();
  const [filterSelected, setfilterSelected] = useState<filterShop>({
    agePet: [],
    typePet: [],
    typeProduct: ''
  });

  useEffect(() => {
    productService.getAllProducts(location === 'USA' ? 2 : 1, true).then(data => {
      setAllProducts(data.products);
    });
    return () => {};
  }, []);

  return (
    <>
      {allProducts &&
      <div className='w-full h-screen absolute z-1000 flex items-center justify-center top-0 left-0 p-4 bg-[#000000b6]'>
        <div className='w-full h-full max-w-[1200px] flex flex-col justify-center items-center rounded-3xl overflow-y-scroll scroll-gray bg-sixth relative'>
          <IconButton
            size='3xl'
            name='close'
            sizeContainer='w-5 h-5'
            className='absolute top-6 right-6 text-grayText z-30'
            shadow={false}
            onClick={handleClosePopup}
          />

          <div className='w-full h-full my-20 font-titles'>
            <div className='fixed z-20 bottom-24 max-h-[8rem] right-8 lg:bottom-0 lg:top-1/3 lg:right-14 xl:right-[5.3rem] xl2:right-[10rem] 2xl1:right-[18rem]'>
              <ButtonBasketFixed handleClosePopup={handleClosePopup}/>
            </div>

            <AnimalFilter setFilter={setfilterSelected} filter={filterSelected} withOutRedirect={false}/>

            <ProductsSection data={allProducts} filter={filterSelected} />
          </div>
        </div>
      </div>
      }
    </>
  );
};

export interface PopupCatalogueProps {
  handleClosePopup: () => void
}

export default PopupCatalogue;
