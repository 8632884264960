import { useState } from 'react';

import AnswerRecoverySection from './AnswerRecoverySection';
import FormRecovery from './FormRecoverySection';
import HeaderSection from './HeaderSection';
import ImageRecoverySection from './imageRecoverySection';

import { backgroundLogin } from '../../../assets/images';

const RecoveryPassword = () => {
  const [view, setView] = useState(1);
  return (
    <div className='bg-sixth w-full h-full scroll-gray overflow-y-auto relative'>
      <div className='flex-col lg:flex-row lg:flex w-full lg:h-screen  lg:w-full'>
        <div className='flex-col h-[258px] bg-[#ACDDDF] lg:h-full sm:w-full lg:block lg:w-1/2 sm:h-[300px]'>
          <HeaderSection/>
          <ImageRecoverySection />
        </div>
        <div className='flex flex-col w-full lg:w-1/2 h-full md:px-48 lg:px-0'>
          <img
            src={backgroundLogin} alt={backgroundLogin}
            className='absolute -z-20 hidden lg:block h-screen w-1/2'
          />
          <div className='px-[1.20rem] w-full lg:px-24 lg:flex lg:flex-col lg:justify-center lg:items-center h-full'>
            {view === 1
              ?
              <FormRecovery setView={setView}/>
              :
              <AnswerRecoverySection />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoveryPassword;
