import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../hooks';
import useScrolled from '../../../hooks/useScrolled';
import Auth from '../../../views/auth';
/// import ContainerPopup from '../../common/containerPopup';
import ViewerImagePopup from '../../common/viewerImagePopup';

import NavBarWholesaler from '../navBarWholesaler';
import Loading from '../loading';
import PopupDetailProduct from '../popupDetailProduct';
import PopupTermAndConditions from '../popupTermsAndConditions';
import PopupSelectShippingCity from '../popupSelectShippingCity';
import PopupChancePage from '../popupChancePage';
import NewModal from '../../common/NewModal';
import PopupCatalogue from '../popupCatalogue';
import { useTranslation } from 'react-i18next';
import { ItemsBannerHome } from '../../../interfaces';
import BannerDefault from '../../../assets/images/bannerHomeEsp.png';
import PopupSelectShippingCityWholesaler from '../popupSelectShippingCityWholesaler';

const BASE_URL = process.env.REACT_APP_URL_S3;

const ALLOWED_URLS = ['', 'catalogo', 'calculadora'];

export const Page = ({ className, children, color, addPadding = true }: PageProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  const currentUrl = window.location.href;

  // Hooks
  const { pathname } = useLocation();
  const { updateContext,
    showPopup,
    showNavbar,
    showLoading,
    showPopupViewerImage,
    showPopupTermsAndConditions,
    showPopupAuth,
    contentManeger,
    showPopupShippingCityWholesaler,
    showPopupGotoSite,
    showPopupMoreProducts,
    lastLocationAndScroll,
  } = useAppContext();
  const minimalNavbar = useCallback(() => {
    /// if (screen.width < 800) return 20;
    if (window.innerWidth < 800) return 20;

    return 20;
  }, [screen]);

  const {
    scrollTo,
    scrolledData: { isDown },
  } = useScrolled({
    gap: minimalNavbar(),
    callback: () =>
      isDown
        ? updateContext(old => ({ ...old, showMinimalNavbar: true }))
        : updateContext(old => ({ ...old, showMinimalNavbar: false })),
  });

  const changeResize = () => {
    // Screen api doesn't work well for safari
    /// if (screen.width < 800)
    if (window.innerWidth < 800)
      updateContext(old => ({ ...old, isMobile: true }));
    else
      updateContext(old => ({ ...old, isMobile: false }));
  };

  useEffect(() => {
    changeResize();
    window.addEventListener('resize', changeResize);

    return () => {
      window.removeEventListener('resize', changeResize);
    };
  }, []);

  useEffect(() => {
    if (lastLocationAndScroll.path === pathname)
      scrollTo(lastLocationAndScroll.scroll);
    else
      scrollTo(0);

    return () => {};
  }, [pathname, lastLocationAndScroll]);

  const urlBanner = useMemo(() => {
    const path = pathname.split('/')[1];
    const sectionHome = contentManeger.find(section => section.key === 'home');
    if (!sectionHome) return '';
    const subBanners = sectionHome.subsections.find(sub => sub.key === 'initial_banners');
    if (!subBanners) return '';
    const banners = subBanners.items as ItemsBannerHome;
    if (!banners) return '';

    if (ALLOWED_URLS.includes(path))
      if (language === 'es')
        switch (path) {
          case '':
            return banners?.bannerDeskEs1;
          case 'catalogo':
            return banners?.bannerDeskEs2;
          case 'calculadora':
            return banners?.bannerDeskEs3;
          default:
            return banners?.bannerDeskEs1;
        }
      else
        switch (path) {
          case '':
            return banners?.bannerDeskEn1;
          case 'catalogo':
            return banners?.bannerDeskEn2;
          case 'calculadora':
            return banners?.bannerDeskEn3;
          default:
            return banners?.bannerDeskEn1;
        }
  }, [currentUrl, contentManeger, language]);

  return (
    <>
      <section
        className={`
        flex flex-col items-center justify-center 
        flex-grow relative w-full
        animate__animated animate__fadeIn animate__fast
        font-titles bg-fixed bg-cover
        ${showNavbar && 'pt-[5.5rem] md:pt-0'}
        ${className ? className : ''}
        `}
        style={{
          backgroundImage: urlBanner ? `url(${BASE_URL}${urlBanner})` : BannerDefault,
        }}
      >
        <NavBarWholesaler />
        {showLoading && <Loading />}
        <div className={`
          flex flex-col items-center justify-center 
          flex-grow relative w-full
          ${addPadding ? 'md:pt-[7.5rem]' : ''}`}
        >
          {children}
        </div>
      </section>
      {showPopup && <PopupDetailProduct />}
      {showPopupViewerImage.show && <ViewerImagePopup />}
      {showPopupShippingCityWholesaler && <PopupSelectShippingCityWholesaler />}
      {showPopupTermsAndConditions.status && <PopupTermAndConditions />}
      {(showPopupGotoSite && !(currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_US as string) || currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_MX as string)))
          && <PopupChancePage/>
      }
      {showPopupMoreProducts &&
        <PopupCatalogue
          handleClosePopup={() => updateContext(old => ({ ...old, showPopupMoreProducts: false }))}
        />
      }
    </>
  );
};

interface PageProps {
  className?: string;
  children: React.ReactNode;
  color?: string;
  addPadding?: boolean;
  showFlagModal?: boolean
}

export default Page;
