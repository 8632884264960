/* eslint-disable react/display-name */
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useForm } from '../../../hooks';
import { FormAppointmentPetInt, validatorPet } from '../../../interfaces/appointment';
import SelectField from '../../form/selectField';
import TextField from '../../form/textField';
import RadioField from '../../form/radioField';
import CheckField from '../../form/checkField';
import { exercises, exercisesEn, types, typesEn } from '../../../@fake/calculatorFake';
import TextArea from '../../form/textArea';
import useValidator from '../../../hooks/useValidator';
import Button from '../button';
import Icon from '../icon';
import { InputSelect } from '../../form/InputSelect';
import { Pet } from '../../../interfaces/user/pets';
import TextCalendarField from '../../form/textCalendarField';

type FormAppointmentPetProps = {
  handleAddPet: (pet: FormAppointmentPetInt) => void;
  showButton?: boolean;
  setPetsData: Dispatch<SetStateAction<FormAppointmentPetInt[]>>,
  index: number,
  validatePetsInfo: boolean,
  setValidatePetsInfo: Dispatch<SetStateAction<boolean>>,
  petsUser: Pet[]
};

const FormAppointmentPet = ({ handleAddPet, showButton = false, setPetsData, index, setValidatePetsInfo, validatePetsInfo, petsUser }: FormAppointmentPetProps) => {
  const { t, i18n: { language } } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [allergiesST, setAllergiesST] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { form, handleFormChange, handleSelectChange, handleRadioChange, onSubmit, setForm, handleChangeDate } = useForm<FormAppointmentPetInt>({
    name: '',
    nameSelect: { label: 'Selecciona', value: '' },
    petOptions: [],
    type: { label: 'Selecciona', value: '' },
    typeOptions: types,
    weight: '',
    physical_activity: exercises[0],
    have_diseases: false,
    diseases: [],
    food: '',
    note: '',
    exerciseOptions: exercises,
    date_birth: '',
  }, () => handleValidate());

  useEffect(() => {
    setForm(old => ({
      ...old,
      petOptions: petsUser.map(pet => ({
        label: pet.name,
        value: pet.id
      }))
    }));
  }, [petsUser]);

  useEffect(() => {
    setCounter(form.note.length);
  }, [form.note]);

  useEffect(() => {
    setPetsData(old => {
      const updatedData = [...old];
      if (updatedData[index])
        updatedData[index] = form;
      else
        updatedData[index] = form;

      return updatedData;
    });
  }, [form]);

  useEffect(() => {
    if (validatePetsInfo)
      handleValidate(true);
  }, [validatePetsInfo]);

  useEffect(() => {
    handleLoadDatePet();
  }, [form.nameSelect]);

  useEffect(() => {
    if (language === 'en')
      setForm(old => ({
        ...old,
        typeOptions: typesEn,
        ageOptions: [],
        exerciseOptions: exercisesEn,
        age: { label: 'Select', value: '' },
        exercise: exercisesEn[0],
        type: { label: 'Select', value: '' },
      }));
  }, [language]);

  const { handlePutMessageError, resetValidator, validatorBody } = useValidator<validatorPet>({
    name: { message: '' },
    type: { message: '' },
    weight: { message: '' },
    food: { message: '' },
    physical_activity: { message: '' },
    age: { message: '' },
    exact_age: { message: '' },
    date_birth: { message: '' }
  });

  const handleValidate = (externalCall?: boolean) => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (form.name === '') {
      handlePutMessageError('name', 'Debes escribir el nombre de tu mascota');
      error = true;
    }

    if (!validator.isDate(form.date_birth, {
      format: 'YYYY-MM-DD'
    })) {
      handlePutMessageError('date_birth', 'El formato del campo debe ser YYYY-MM-DD');
      error = true;
    }

    if (form.food === '') {
      handlePutMessageError('food', 'Debes escribir la alimentación actual');
      error = true;
    }

    if (form.type.value === '') {
      handlePutMessageError('type', 'Debes seleccionar una opción');
      error = true;
    }

    if (form.weight === '') {
      handlePutMessageError('weight', 'Debes escribir el peso actual');
      error = true;
    }

    if (form.physical_activity.value === '') {
      handlePutMessageError('physical_activity', 'Debes seleccionar una opción');
      error = true;
    }

    setValidatePetsInfo(false);

    if (!error && !externalCall)
      handleAddPet(form);
  };

  const handleCreateState = (value: string) => {
    /// setSelectLoading(true);
    setTimeout(() => {
      const newPet = { label: value, value };
      handleSelectChange(newPet, 'nameSelect');
      setForm(old => ({ ...old, name: value, petOptions: old.petOptions ? [...old.petOptions, newPet] : [newPet], id: '' }));
    }, 1000);
  };

  const handleSetDiseases = (name:string) => {
    if (form.diseases.includes(name)) {
      const diseases = form.diseases.filter(item => item !== name);
      setForm(old => ({ ...old, diseases }));
    } else
      setForm(old => ({ ...old, diseases: [...old.diseases, name] }));
  };

  const handleLoadDatePet = () => {
    if (form.nameSelect?.value) {
      const pet = petsUser.find(item => item.id.toString() === form.nameSelect.value);
      console.log(form.typeOptions.find(item => item.value === pet?.type));
      if (pet)
        setForm(old => ({ ...old,
          weight: `${pet.weight}`,
          physical_activity: form.exerciseOptions.find(item => item.value === pet.activity) || form.exerciseOptions[0],
          diseases: JSON.parse(pet.diseases),
          type: form.typeOptions.find(item => item.value === pet.type) || form.typeOptions[0],
          name: pet.name,
          date_birth: pet.date_birth,
          id: pet.id
        }));
    }
  };

  return (
    <form onSubmit={onSubmit} ref={formRef} className='font-subTitles'>
      <p className='text-lg font-titles text-pixieLightBlue mb-5'>Ahora, llena los datos de tu mascota</p>
      <div className='flex flex-col gap-3 lg:gap-[1.40rem]'>
        <InputSelect
          name='nameSelect'
          loading={false}
          value={form.nameSelect?.value ? form.nameSelect : null}
          options={form.petOptions}
          placeholder='¿Cómo se llama tu mascota?*'
          onCreate={handleCreateState}
          onChange={e => {
            handleSelectChange(e, 'nameSelect');
          }}
          colorText='#4A4A4A'
          dropdownIndicatorColor='#33B5A9'
          messageError={validatorBody.name.message}
          isClearable
        />

        <div className='flex flex-col gap-3 lg:flex-row lg:gap-2 items-end'>
          <SelectField
            onChange={handleSelectChange}
            name='type'
            value={form.type.value ? form.type : undefined}
            placeholder='Perro'
            label='Tu mascota es un...*'
            options={form.typeOptions}
            className='mt-3 lg:mt-0 lg:w-1/2'
            labelClassName='text-sm'
            borderRadiuspx='20px'
            dropdownIndicatorColor='#33B5A9'
            paddingY='0.4rem'
            messageError={validatorBody.type.message}
          />
          <TextCalendarField
            nameField='date_birth'
            placeholder={t('myPetFormBorn')}
            setValue={handleChangeDate}
            textFielClassName='py-4'
            value={form.date_birth}
            messageError={validatorBody.date_birth.message}
            className='lg:w-1/2'
            limitToday={true}
            captionLayout='dropdown'
            required
          />
        </div>

        <div className='flex flex-col gap-3 lg:flex-row lg:gap-2 items-end'>
          <TextField
            name='weight'
            value={form.weight}
            type='number'
            className='w-full lg:w-1/2'
            fieldClassName='py-[0.8rem]'
            handler={e => {
              const { valueAsNumber } = e.target;
              if (valueAsNumber < 1) return;
              handleFormChange(e);
            }}
            placeholder={t('calcFormLabelWeight')}
            min={0}
            handleKeyDown={e => {
              if (e.key === '-')
                e.preventDefault();
            }}
            messageError={validatorBody.weight.message}
          />
        </div>

        <SelectField
          onChange={handleSelectChange}
          name='physical_activity'
          placeholder='Normal'
          label='Actividad física*'
          value={form.physical_activity.value ? form.physical_activity : undefined}
          options={form.exerciseOptions}
          labelClassName='text-sm'
          borderRadiuspx='20px'
          dropdownIndicatorColor='#33B5A9'
          paddingY='0.4rem'
          messageError={validatorBody.physical_activity.message}
        />

        <div className='flex flex-col gap-3 pl-5 text-sm'>
          <label>Enfermedades y alergias</label>
          <div className='flex gap-5'>
            <RadioField
              label={t('calcFormLabelYes')}
              changeState={setAllergiesST}
              currentState={allergiesST}
              name='have_diseases'
              handleRadioChange={handleRadioChange}
              value={true}
            />
            <RadioField
              label=' No'
              changeState={setAllergiesST}
              currentState={allergiesST}
              name='have_diseases'
              handleRadioChange={handleRadioChange}
              value={false}
            />
          </div>
          {allergiesST && (
            <div className='flex gap-5 md:gap-14'>
              <div className='flex flex-col'>
                <CheckField
                  onClick={() => handleSetDiseases('hepatics')}
                  label={t('calcFormLabelHepatic')}
                  border='border border-pixieLightBlue'
                  sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                  className='mt-1'
                  labelClassName='text-xs lg:text-sm'
                  checked={form.diseases.includes('hepatics')}
                />
                <CheckField
                  onClick={() => handleSetDiseases('renal')}
                  label={t('calcFormLabelRenal')}
                  border='border border-pixieLightBlue'
                  sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                  className='mt-1 '
                  labelClassName='text-xs lg:text-sm'
                  checked={form.diseases.includes('renal')}
                />
                <CheckField
                  onClick={() => handleSetDiseases('obesity')}
                  label={t('calcFormLabelObesity')}
                  border='border border-pixieLightBlue'
                  sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                  className='mt-1'
                  labelClassName='text-xs lg:text-sm'
                  checked={form.diseases.includes('obesity')}
                />
              </div>
              <div className='flex flex-col'>
                <CheckField
                  onClick={() => handleSetDiseases('alergies')}
                  label={t('calcFormLabelAlergy')}
                  border='border border-pixieLightBlue'
                  sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                  className='mt-1 ml-1 lg:ml-5'
                  labelClassName='text-xs lg:text-sm'
                  checked={form.diseases.includes('alergies')}
                />
                <CheckField
                  onClick={() => handleSetDiseases('sensitive_stomach')}
                  label={t('calcFormLabelStomach')}
                  border='border border-pixieLightBlue'
                  sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                  className='mt-1 ml-1 lg:ml-5'
                  labelClassName='text-xs lg:text-sm'
                  checked={form.diseases.includes('sensitive_stomach')}
                />
                <CheckField
                  onClick={() => handleSetDiseases('other')}
                  label={t('calcFormLabelOther')}
                  border='border border-pixieLightBlue'
                  sizeContainer='w-4 h-4 lg:w-5 lg:h-5 lg:mr-1'
                  className='mt-1 ml-1 lg:ml-5'
                  labelClassName='text-xs lg:text-sm'
                  checked={form.diseases.includes('other')}
                />
              </div>
            </div>
          )}
        </div>

        <TextField
          handler={handleFormChange}
          name='food'
          value={form.food}
          placeholder='Alimento actual'
          fieldClassName='py-[0.8rem]'
          messageError={validatorBody.food.message}
        />

        <div className='relative'>
          <TextArea
            handler={handleFormChange}
            name='note'
            value={form.note}
            placeholder='Acá puedes dejarle un comentario a nuestro veterinario antes de la cita para que tenga un contexto general de tu mascota'
            fieldClassName='py-[0.8rem] text-sm min-h-[122px]'
            label='¿Deseas agregar nota? opcional'
            labelClassName='text-sm mb-2 lg:mb-5 pl-5'
            border='rounded-[20px]'
            maxLength={400}
          />
          <p className={`text-sm text-grayText opacity-60 absolute bottom-2 right-2 ${counter === 400 && 'text-primary'}`}>{counter}/400</p>
        </div>

        {showButton &&
          <div className='flex justify-end items-end mt-3 lg:mt-0'>
            <Button
              className='bg-primary text-[#FAD7B1] text-base font-sanzBold gap-2 flex justify-center items-center shadow-appointment'
              padding='py-1 px-3'
              type='submit'
            >
              <Icon name='add' size='2xl'/>
              Agregar mascota
            </Button>
          </div>
        }
      </div>
    </form>
  );
};

export default FormAppointmentPet;
