import moment from 'moment';
import { Appointment, CreateAppointment, FormAppointmentPetInt, FormStepOneAppointment, FormStepTwoAppointment, PetAppointment, UserVet, blockSchedule } from '../../interfaces/appointment';
import api from '../axios';

class AppointmentService {
  init() {}

  getAppointmentsByUser = (): Promise<Appointment[]> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.get('pixie-customers/api/appointments/byEmail', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getOneAppointmentById = (id: string): Promise<Appointment> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.get(`pixie-customers/api/appointments/${id}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getAllVeterinary = (): Promise<UserVet[]> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.get('pixie-users/api/users/vets', {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getBlockedSchedules = (id: string): Promise<blockSchedule[]> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.get(`pixie-users/api/veterinary-availibities/veterinary/${id}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      })
        .then(response => {
          resolve(response.data.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  uploadFileTestimonial = (file: File): Promise<string> => {
    const authorization = window.localStorage.getItem('token');
    const formData = new FormData();
    /// const newName = `appointment-${testimonialForm.pet_name.replace(' ', '-')}-${testimonialForm.name.replace(' ', '-')}`;
    formData.append('file', file);
    formData.append('folder', 'appointments');

    return new Promise((resolve, reject) => {
      api({ method: 'post', url: 'pixie-payments/api/uploads',
        headers: {
          Authorization: authorization ? authorization : '',
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      })
        .then(response => {
          resolve(response.data.key);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  sendDataAppointment = (dataStepOne: FormStepOneAppointment, dataStepTwo: FormStepTwoAppointment, filesKeys: string[]): Promise<any> => {
    const authorization = window.localStorage.getItem('token');
    // Ajust hour and date
    const date = moment(dataStepTwo.date);
    const hour = moment(dataStepTwo.hour.value, 'HH:mm');

    const fechaHoraUnida = date.set({
      hour: hour.hour(),
      minute: hour.minute(),
    });

    // Ajust pets data
    const dataPets: PetAppointment[] = dataStepOne.pets.map((pet: FormAppointmentPetInt) => ({
      id: pet.id,
      diseases: JSON.stringify(pet.diseases),
      food: pet.food,
      have_diseases: pet.have_diseases,
      name: pet.name,
      note: pet.note,
      physical_activity: pet.physical_activity.value,
      type: pet.type.value,
      weight: pet.weight,
      date_birth: pet.date_birth
    }));

    const dataSend: CreateAppointment = {
      contact_information: dataStepTwo.contact_information,
      contact_preference_type: dataStepTwo.contact_preference_type,
      date: fechaHoraUnida.format('YYYY-MM-DD HH:mm'),
      email: dataStepOne.email,
      medical_exams: {
        files: filesKeys
      },
      name: dataStepOne.name,
      pets: {
        data: dataPets
      },
      phone_number: dataStepOne.phone_number,
      reason: dataStepTwo.reason,
      vet: dataStepTwo.vet.value,
      vet_name: dataStepTwo.vet.label
    };

    return new Promise((resolve, reject) => {
      api.post('pixie-customers/api/appointments', dataSend, {
        headers: {
          ...(authorization ? { Authorization: authorization } : {})
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
    });
  };

  deleteAppointment = (id: string): Promise<any> => {
    const authorization = window.localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      api.delete(`pixie-customers/api/appointments/${id}`, {
        headers: {
          authorization: authorization ? authorization : ''
        }
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      });
    });
  };
}

const instance = new AppointmentService();

export default instance;
