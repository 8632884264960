import { lazy, Suspense, useEffect, useRef } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FadeScreen from '../components/layout/fadeScreen';

const NotFound = lazy(() => import('../views/notFound'));
const Catalogue = lazy(() => import('../views/Catalogue'));
const CatalogueWholesaler = lazy(() => import('../views/wholesaler/catalogueWholesaler'));
const Home = lazy(() => import('../views/Home'));
const Detail = lazy(() => import('../views/Detail'));
const Calculator = lazy(() => import('../views/Calculator'));
const Basket = lazy(() => import('../views/Basket'));
const WholesalerBasket = lazy(() => import('../views/WholesalerBasket'));
const CheckOut = lazy(() => import('../views/Checkout'));
const CheckOutWholesaler = lazy(() => import('../views/CheckoutWholesaler'));
const FrequentQuestions = lazy(() => import('../views/FrequentQuestions'));
const DataPrivacy = lazy(() => import('../views/dataPrivacy'));
const TermsAndConditions = lazy(() => import('../views/termsAndConditions'));
const ResultPayment = lazy(() => import('../views/resultPayment'));
const ResultPaymentWholesaler = lazy(() => import('../views/resultPaymentWholesaler'));
const CreateCombo = lazy(() => import('../views/createCombo'));
const Login = lazy(() => import('../views/auth/login'));
const Register = lazy(() => import('../views/auth/register'));
const RecoveryPassword = lazy(() => import('../views/auth/recoveryPassword'));
const ResetPassword = lazy(() => import('../views/auth/resetPassword'));
const Subscription = lazy(() => import('../views/subscription'));
const CreateSubscription = lazy(() => import('../views/createSubscription'));
const LoginWholesaler = lazy(() => import('../views/wholesaler/login'));
const WhereBuy = lazy(() => import('../views/whereBuy'));

import { useAppContext, useAuth } from '../hooks';
import Protected, { AUTH_TYPE } from './Protected';
import UserRoutes from './userRoutes';
import { useTranslation } from 'react-i18next';
import subscriptionService from '../services/subscriptionService';
import storeService from '../services/storeService';
import { FormCreateSubscription } from '../interfaces/subscription';
import { selectCountry } from '../@fake/countrySelects';
import { workCities } from '../interfaces/store';
import { getTextCountry } from '../helpers/contentManager';
import { getSectionByCountry } from '../services';
import WholesalerUserRoutes from './wholesalerUserRoutes';
import useAuthWholesaler from '../hooks/useAuthWholesaler';
import AppointmentsLanding from '../views/appointmentLanding';
import ProductDetailWholesaler from '../views/ProductDetailWholesaler';

const AppRouter = () => {
  // Hooks
  const { location, isAuthenticated, updateContext } = useAppContext();
  const { checkToken, old: token, isChecking, user } = useAuth();
  const { user: wholesaler, checkToken: wholesalerCheckToken, old: wholesalerToken, isChecking: wholesalerIsChecking } = useAuthWholesaler();

  const isMounted = useRef(false);
  const currentUrl = window.location.href;
  /// const { getUserLocation } = useLocation();

  // Translate
  const { i18n } = useTranslation();

  // Check if user is authenticated
  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkToken();

      updateContext(old => ({
        ...old,
        isLoadingPreData: true
      }));

      if (isAuth)
        // Update auth state
        return updateContext(old => ({
          ...old,
          isAuthenticated: true,
          user,
        }));

      // Remove data from localStorage
      localStorage.removeItem('token');
    };

    const verifyWholesalerAuth = async () => {
      const isAuth = await wholesalerCheckToken();

      updateContext(old => ({
        ...old,
        isLoadingPreData: true
      }));

      if (isAuth)
        // Update auth state
        return updateContext(old => ({
          ...old,
          wholesalerAuthenticated: true,
          wholesaler
        }));

      // Remove data from localStorage
      localStorage.removeItem('wholesaler_token');
    };

    if (!isAuthenticated && isMounted.current && token) verifyAuth();
    if (!wholesalerIsChecking && isMounted.current && wholesalerToken && !token) verifyWholesalerAuth();

    if (!isMounted.current) {
      isMounted.current = true;

      if (token) verifyAuth();
      if (wholesalerToken && !token) verifyWholesalerAuth();
    }

    return () => { };
  }, [isMounted.current]);

  useEffect(() =>
    updateContext(old => ({
      ...old,
      user,
      wholesaler
    }))
  , [user, wholesaler]);

  useEffect(() => {
    if (currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_US as string)) {
      updateContext(old => ({
        ...old,
        countrySelected: selectCountry[1],
        location: 'USA',
        showPopupGotoSite: false
      }));

      i18n.changeLanguage('en');
      localStorage.setItem('languaje', 'en');
      const workCities = localStorage.getItem('workCities');
      const isSavedCellar = localStorage.getItem('cellarSelected');

      if (isSavedCellar && workCities)
        getDataWorkCities(workCities);

      updateContext(old => ({ ...old, showPopupShippingCity: (!isSavedCellar && !workCities), showPopupShippingCityWholesaler: (!isSavedCellar && !workCities) }));
    } else if (currentUrl.includes(process.env.REACT_APP_REDIRECT_PROD_MX as string)) {
      const isSavedCellar = localStorage.getItem('cellarSelected');
      const workCities = localStorage.getItem('workCities');
      const showPopup = !isSavedCellar && !workCities;

      if (isSavedCellar && workCities) {
        updateContext(old => ({
          ...old,
          countrySelected: selectCountry[0],
          location: 'MEX',
          showPopupShippingCity: showPopup,
          showPopupShippingCityWholesaler: showPopup,
          cellarSelected: JSON.parse(isSavedCellar),
          workCities: JSON.parse(workCities),
          showPopupGotoSite: false
        }));
        if (isSavedCellar && workCities)
          getDataWorkCities(workCities);
      }

      updateContext(old => ({
        ...old,
        countrySelected: selectCountry[0],
        location: 'MEX',
        showPopupShippingCity: showPopup,
        showPopupShippingCityWholesaler: showPopup,
        showPopupGotoSite: false
      }));
    }
  }, []);

  useEffect(() => {
    const prevLanguaje = localStorage.getItem('languaje');
    const currentUrl = window.location.href;
    if (prevLanguaje === null) {
      if (currentUrl === `${process.env.REACT_APP_REDIRECT_PROD_US}`) {
        i18n.changeLanguage('en');
        localStorage.setItem('languaje', 'en');
      }
    } else
      i18n.changeLanguage(prevLanguaje);
    return () => {};
  }, []);

  useEffect(() => {
    setSubscriptions();
    const prevDataSub = localStorage.getItem('subscriptionData');
    if (prevDataSub !== null) {
      const prevDataSubTransform: FormCreateSubscription = JSON.parse(prevDataSub);
      updateContext(old => ({ ...old, subscriptionData: prevDataSubTransform, isSubscriptionSelected: true }));
    }
  }, []);

  const setSubscriptions = () => {
    subscriptionService.getSubscriptions({}).then(res => {
      updateContext(old => ({ ...old, subscriptionsOptions: res }));
    }).catch(error => {
      console.log(error);
    });

    subscriptionService.getMinShippingPrice('mex').then(res => {
      updateContext(old => ({ ...old, minPriceSubscription: res.price }));
    }).catch(error => {
      console.log(error);
    });
  };

  const getDataWorkCities = (workCities: string) => {
    const workData: workCities = JSON.parse(workCities);
    storeService.getOneWorkCity(`${workData.city_id}`).then(res => {
      updateContext(old => ({ ...old, cellarSelected: res.cellar, workCities: res }));
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        country: getTextCountry(location),
        /// section: 'home',
      };

      const { data, error } = await getSectionByCountry(params);

      if (error || !data) {
        console.log(error);
        return;
      }

      updateContext(old => ({
        ...old,
        contentManeger: data.data
      }));
    };

    fetchData();
  }, [location]);

  // Routes
  return (
    <>
      {isChecking && <FadeScreen />}
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Home />
              </Suspense>
            }
          />

          <Route
            path='/catalogo'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Catalogue />
              </Suspense>
            }
          />

          <Route path='/catalogo/:type'>
            <Route index
              element={
                <Suspense fallback={<FadeScreen />}>
                  <Catalogue />
                </Suspense>
              }/>
            <Route path='/catalogo/:type/:cat'>
              <Route index
                element={
                  <Suspense fallback={<FadeScreen />}>
                    <Catalogue />
                  </Suspense>
                }
              />
              <Route path='/catalogo/:type/:cat/detalle/:product'
                element={
                  <Suspense fallback={<FadeScreen />}>
                    <Detail />
                  </Suspense>
                }/>
            </Route>
            <Route path='/catalogo/:type/detalle/:product'
              element={
                <Suspense fallback={<FadeScreen />}>
                  <Detail />
                </Suspense>
              }/>
          </Route>
          <Route path='/catalogo/detalle/:product'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Detail />
              </Suspense>
            }
          />
          <Route
            path='producto/detalle/:product'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Detail />
              </Suspense>
            }
          />
          <Route
            path='producto/crear/combo'
            element={
              <Suspense fallback={<FadeScreen />}>
                <CreateCombo />
              </Suspense>
            }
          />

          <Route
            path='/calculadora'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Calculator />
              </Suspense>
            }
          />
          <Route
            path='/canasta'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Basket/>
              </Suspense>
            }
          />
          <Route
            path='/pago'
            element={
              <Suspense fallback={<FadeScreen />}>
                <CheckOut/>
              </Suspense>
            }
          />
          <Route
            path='/pago/resultado'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ResultPayment/>
              </Suspense>
            }
          />
          <Route
            path='/payment/result'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ResultPayment/>
              </Suspense>
            }
          />
          <Route
            path='/preguntas-frecuentes'
            element={
              <Suspense fallback={<FadeScreen />}>
                <FrequentQuestions/>
              </Suspense>
            }
          />
          <Route
            path='/politica-privacidad'
            element={
              <Suspense fallback={<FadeScreen />}>
                <DataPrivacy/>
              </Suspense>
            }
          />
          <Route
            path='/terminos'
            element={
              <Suspense fallback={<FadeScreen />}>
                <TermsAndConditions />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/ingresar'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Login />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/registro'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Register />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/recuperar'
            element={
              <Suspense fallback={<FadeScreen />}>
                <RecoveryPassword />
              </Suspense>
            }
          />

          <Route
            path='/autenticacion/resetear'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path='/suscripcion'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Subscription />
              </Suspense>
            }
          />

          <Route path='/usuario/*' element={
            <Protected>
              <UserRoutes/>
            </Protected>
          }/>

          <Route
            path='/suscripcion/crear'
            element={
              <Suspense fallback={<FadeScreen />}>
                <CreateSubscription />
              </Suspense>
            }
          />

          <Route
            path='donde-comprar'
            element={
              <Suspense fallback={<FadeScreen />}>
                <WhereBuy />
              </Suspense>
            }
          />

          <Route path='*' element={
            <Suspense fallback={<FadeScreen />}>
              <NotFound />
            </Suspense>
          } />

          <Route
            path='/mayoristas/login'
            element={
              <Suspense fallback={<FadeScreen />}>
                <LoginWholesaler/>
              </Suspense>
            }
          />
          <Route path='/mayoristas/*' element={
            <Protected authType={AUTH_TYPE.WHOLESALER}>
              <WholesalerUserRoutes/>
            </Protected>
          }/>

          <Route
            path='/mayoristas/catalogo'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Protected authType={AUTH_TYPE.WHOLESALER}>
                  <CatalogueWholesaler />
                </Protected>
              </Suspense>
            }
          />
          <Route path='/mayoristas/catalogo/detalle/:product'
            element={
              <Suspense fallback={<FadeScreen />}>
                <ProductDetailWholesaler />
              </Suspense>
            }
          />
          <Route path='/mayoristas/catalogo/:type'>
            <Route index
              element={
                <Suspense fallback={<FadeScreen />}>
                  <Protected authType={AUTH_TYPE.WHOLESALER}>
                    <CatalogueWholesaler />
                  </Protected>
                </Suspense>
              }/>
            <Route path='/mayoristas/catalogo/:type/:cat'>
              <Route index
                element={
                  <Suspense fallback={<FadeScreen />}>
                    <Protected authType={AUTH_TYPE.WHOLESALER}>
                      <CatalogueWholesaler />
                    </Protected>
                  </Suspense>
                }
              />
              <Route path='/mayoristas/catalogo/:type/:cat/detalle/:product'
                element={
                  <Suspense fallback={<FadeScreen />}>
                    <Protected authType={AUTH_TYPE.WHOLESALER}>
                      <ProductDetailWholesaler />
                    </Protected>
                  </Suspense>
                }/>
            </Route>
            <Route path='/mayoristas/catalogo/:type/detalle/:product'
              element={
                <Suspense fallback={<FadeScreen />}>
                  <Protected authType={AUTH_TYPE.WHOLESALER}>
                    <ProductDetailWholesaler />
                  </Protected>
                </Suspense>
              }/>
          </Route>

          <Route
            path='/mayoristas/canasta'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Protected authType={AUTH_TYPE.WHOLESALER}>
                  <WholesalerBasket/>
                </Protected>
              </Suspense>
            }
          />

          <Route
            path='/mayoristas/pago'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Protected authType={AUTH_TYPE.WHOLESALER}>
                  <CheckOutWholesaler/>
                </Protected>
              </Suspense>
            }
          />

          <Route
            path='/mayoristas/pago/resultado'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Protected authType={AUTH_TYPE.WHOLESALER}>
                  <ResultPaymentWholesaler/>
                </Protected>
              </Suspense>
            }
          />
          <Route
            path='/mayoristas/payment/result'
            element={
              <Suspense fallback={<FadeScreen />}>
                <Protected authType={AUTH_TYPE.WHOLESALER}>
                  <ResultPaymentWholesaler/>
                </Protected>
              </Suspense>
            }
          />

          <Route
            path='/citas'
            element={
              <Suspense fallback={<FadeScreen />}>
                <AppointmentsLanding />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
