/* eslint-disable curly */
import React, { useState, useRef } from 'react';
import Icon from '../icon';
import { uploadBox } from '../../../assets/vectors';
import IconButton from '../iconButton';
import { useAppContext } from '../../../hooks';

function DragAndDropV2({ setDataFile, selectedFile, isMultiple = true, accept, placeholder }: DragAndDropProps) {
  const [dragging, setDragging] = useState(false);
  /// const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { toast } = useAppContext();

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files;
    setDataFile(file);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type.includes('video')) {
          if (files[i].size > 25000000) {
            toast.fire({
              icon: 'warning',
              title: 'El maximo tamaño de un video es de 25 MB',
            });
            handleFileDelete(i);
            return;
          }
        } else if (files[i].size > 5000000) {
          toast.fire({
            icon: 'warning',
            title: 'El maximo tamaño de una imagen es de 5 MB',
          });
          handleFileDelete(i);
          return;
        }
      }

      setDataFile(files);
    }
  };

  const handleFileDelete = (index: number) => {
    if (selectedFile) {
      const updatedFiles = Array.from(selectedFile).filter((file, i) => i !== index);
      const updatedFileList = new DataTransfer();

      updatedFiles.forEach(file => {
        updatedFileList.items.add(file);
      });

      setDataFile(updatedFileList.files);
    }

    if (inputRef.current)
      inputRef.current.value = '';
  };

  return (
    <div className='flex flex-col gap-4'>
      <div
        className={`border border-pixieLightBlue rounded-[20px] py-7 cursor-pointer ${dragging ? 'bg-white' : 'bg-white'}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
      >
        <div className='flex flex-col justify-center items-center'>
          <div className={`${dragging ? 'text-gray-600' : 'text-grayText'}`}>
            <div className='flex gap-6 justify-center items-center'>
              <img src={uploadBox} className='xl2:w-8 xl2:h-8'/>
              <span className='text-pixieLightBlue font-titles text-sm'>{placeholder}</span>
              {dragging &&
                <span>
                  Suelta tu documento aquí
                </span>
              }
            </div>
          </div>
          <input
            type='file'
            className='hidden'
            ref={inputRef}
            accept={accept}
            onChange={handleFileSelect}
            multiple={isMultiple}
          />
        </div>
      </div>

      {selectedFile && (
        <div className='flex flex-col gap-2 lg:gap-3'>
          {Array.from(selectedFile).map((file, index) => (
            <div
              className='text-sm flex items-center border rounded-[10px] bg-white border-pixieLightBlue px-1 lg:p-1'
              key={file.name + index}
            >
              <div className='flex-1 truncate'>
                <div className='flex items-center gap-4 font-sanzSemiBold text-xs lg:text-sm'>
                  <span>
                    <Icon
                      name='attach_file'
                      className='text-grayText rotate-45'
                    />
                  </span>
                  {file.name}
                </div>
              </div>
              <IconButton
                name='close'
                onClick={() => handleFileDelete(index)}
                shadow={false}
                color='#C9C9C9'
                sizeContainer='w-4 h-4 lg:w-5 lg:h-5'
                className='text-black'
                size='sm'
                classNameIcon='lg:text-xl'
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

interface DragAndDropProps {
  setDataFile: (file: FileList | null) => void;
  selectedFile: FileList | null
  isMultiple?: boolean
  accept?: string
  placeholder?: string
}

DragAndDropV2.defaultProps = {
  accept: 'image/png, image/jpeg, video/*',
  isMultiple: true,
  placeholder: 'Sube un nuevo archivo',
};

export default DragAndDropV2;
