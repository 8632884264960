import { useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { useTranslation } from 'react-i18next';

import TextField from '../../../components/form/textField';
import Button from '../../../components/common/button';

import useForm from '../../../hooks/useForm';
import useValidator from '../../../hooks/useValidator';
import { useAppContext } from '../../../hooks';
import authService from '../../../services/authService';
import { resetPassword, resetPasswordValidate } from '../../../interfaces/auth';

const FormResetPassword = () => {
  // Hooks
  const [searchParams] = useSearchParams();
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useAppContext();
  const { form, handleFormChange, onSubmit } = useForm<resetPassword>(
    {
      confirmPasword: '',
      password: ''
    },
    () => validateForm()
  );

  const { validatorBody, resetValidator, handlePutMessageError } = useValidator<resetPasswordValidate>({
    confirmPasword: { message: '' },
    password: { message: '' }
  });

  // Methods
  const validateForm = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (!validator.isLength(form.password, { min: 4 })) {
      handlePutMessageError('password', 'La contraseña debe tener mas de 4 digitos');
      error = true;
    }

    if (!validator.equals(form.confirmPasword, form.password)) {
      handlePutMessageError('confirmPasword', 'Las contraseñas no coinciden');
      error = true;
    }

    if (!error)
      handleSubmit(form);
  };

  const handleSubmit = async (form: any) => {
    const token = searchParams.get('token');
    if (token)
      await authService.changePassword(form, token, language)
        .then(() => {
          toast.fire({
            icon: 'success',
            title: 'Se cambió la contraseña correctamente.',
          });
          navigate('/');
        })
        .catch(() => {
          toast.fire({
            icon: 'error',
            title: 'Ocurrio un error cambiando la contraseña.',
          });
        });
    else
      toast.fire({
        icon: 'error',
        title: 'No se encontro el token.',
      });
  };

  return (
    <form
      onSubmit={onSubmit}
      className='w-full flex flex-col lg:gap-5 text-sm lg:pr-6 z-10 font-subTitles justify-between items-center'
    >
      <div className='mb-5 lg:mb-8 mt-20 flex flex-col gap-3 '>
        <h2 className='text-lg font-titles text-fourth lg:text-left lg:text-[25px] '>
          Ingresa tu nueva contraseña
        </h2>
      </div>
      <div className='flex flex-col gap-3 w-full'>
        <TextField
          name='password'
          value={form.password}
          handler={handleFormChange}
          placeholder='Contraseña'
          className='w-full'
          type='password'
          fieldClassName='py-[0.95rem] px-7 rounded-[16px]'
          messageError={validatorBody.password.message}
        />
        <TextField
          name='confirmPasword'
          value={form.confirmPasword}
          handler={handleFormChange}
          placeholder='Confirmar Contraseña'
          className='w-full'
          type='password'
          fieldClassName='py-[0.95rem] px-7 rounded-[16px]'
          messageError={validatorBody.confirmPasword.message}
        />
      </div>
      <div className='mt-9 flex flex-col items-center justify-center lg:mt-5 gap-5 lg:gap-10'>
        <Button
          type='submit'
          className='text-xs bg-primary text-[#fad7b1] rounded-xl font-sanzBold lg:text-base'
          padding='px-10 py-[0.6rem] lg:py-[0.7rem]'
        >
          Guardar
        </Button>
      </div>
    </form>
  );
};

export default FormResetPassword;
