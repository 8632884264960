import { useTranslation } from 'react-i18next';

import { send } from '../../../assets/vectors';
import Button from '../../../components/common/button';
import { useAppContext } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

const AnswerRecoverySection = () => {
  const { updateContext, isMobile } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClosePopup = () => {
    updateContext(old => ({ ...old, showPopupAuth: {
      status: false,
      view: 1
    } }
    ));
    if (isMobile)
      navigate(-1);
  };

  return (
    <div className='mt-20 text-fourth flex flex-col justify-center items-center gap-10 lg:mt-0'>
      <div className='rounded-full w-[86px] h-[86px] bg-fourth p-1 flex justify-center items-center lg:w-[103px] lg:h-[103px]'>
        <img src={send} className='w-[5rem]'/>
      </div>
      <p className='text-xl lg:text-[25px] text-center'>{t('recoverySenEmail')}</p>
      <Button className='bg-primary font-sanzBold text-base text-[#FAD7B1]' padding='px-10 py-2' onClick={handleClosePopup}>
        {t('recoveryReturn')}
      </Button>
    </div>
  );
};

export default AnswerRecoverySection;
