
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/common/button';

import HeaderUser from '../../../components/common/headerUser';
import PageUser from '../../../components/layout/pageUser';

import { iconAppointment } from '../../../assets/vectors';
import EmptyAppointmentSection from './emptyAppointmentSection';
import MyAppointmentSection from './MyAppointmentsSection';
import { Appointment } from '../../../interfaces/appointment';
import appointmentService from '../../../services/appointmentsService';
import CreateAppointment from '../../createAppointment';

const Appointments = () => {
  const { t } = useTranslation();
  const [showCreate, setShowCreate] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  useEffect(() => loadDataAppointment(), []);

  const loadDataAppointment = () => {
    appointmentService.getAppointmentsByUser()
      .then(res => {
        setAppointments(res);
      }).catch(err => {
        console.log(err);
      });
  };

  return (
    <PageUser
      className='bg-[#D9EEEF] lg:bg-sixth'
      classNameChildren=' relative flex flex-col justify-center items-center pt-5 relative lg:pr-[5rem] lg:pl-[6rem] lg:bg-sixth'
    >
      <div className='flex justify-between w-full'>
        <HeaderUser
          imageURL={iconAppointment}
          title={t('userMenuAppointments')}
          classNameArrow='w-1/6'
          classNameTitle='w-5/6'
        />
        {appointments.length > 0 &&
          <div className='hidden w-full lg:flex justify-end py-2'>
            <Button
              className='font-sanzBold text-white bg-primary text-lg'
              padding='px-10'
              onClick={() => setShowCreate(true)}
            >
              {t('myAppoCreate')}
            </Button>
          </div>
        }
      </div>
      <div className='bg-sixth w-full rounded-t-[20px] py-7 px-2 flex flex-col gap-4 lg:py-5 '>
        {appointments.length === 0 &&
          <EmptyAppointmentSection
            setShowCreate={setShowCreate}
          />
        }
        {appointments.length > 0 && (
          <>
            <div className='md:hidden flex justify-center items-center'>
              <Button
                className='font-sanzBold text-white bg-primary text-lg'
                padding='px-20 py-2.5'
                onClick={() => setShowCreate(true)}
              >
                {t('myAppoCreate')}
              </Button>
            </div>

            <MyAppointmentSection
              appointments={appointments}
              setAppointments={setAppointments}
              reloadData={() => loadDataAppointment()}
            />
          </>
        )}
      </div>
      {showCreate && (
        <CreateAppointment handleClose={() => setShowCreate(false)}/>
      )}
    </PageUser>
  );
};

export default Appointments;
