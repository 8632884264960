export const navBar = {
  navCatalogue: 'Store',
  navCalculator: 'Calculator',
  navSubscriptions: 'Subscriptions',
  navAppointments: 'Appointments',
  navWhereToBuy: 'Where to Buy',
  navDates: 'Dates',
  navBlog: 'Blog',
  navWhereBuy: 'Where Buy',
  navBasket: 'Your basket',
  navSearchPlaceholder: 'Search your fav pixie',
  loginWholesale: 'Wholesale Login',

};
