import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import FadeScreen from '../components/layout/fadeScreen';
import PetProfile from '../views/user/petProfile';
import SubscriptionHistory from '../views/user/subscriptionHistory';
import UserTestimonials from '../views/user/testimonials';
import Appointments from '../views/user/appointments';
import AppointmentDocument from '../views/user/appointments/appointmentDocument';

const MyProfile = lazy(() => import('../views/user/myProfile'));
const MyPets = lazy(() => import('../views/user/myPets'));
const Favorite = lazy(() => import('../views/user/favorites'));
const OrdersHistory = lazy(() => import('../views/user/ordersHistory'));

const UserRoutes = () => (
  <>
    <Routes>
      <Route path='/perfil' element={
        <Suspense fallback={<FadeScreen />}>
          <MyProfile />
        </Suspense>}
      />
      <Route path='/mascotas' element={
        <Suspense fallback={<FadeScreen />}>
          <MyPets />
        </Suspense>}
      />
      <Route path='/mascotas/perfil' element={
        <Suspense fallback={<FadeScreen />}>
          <PetProfile />
        </Suspense>}
      />
      <Route path='/productos/favoritos' element={
        <Suspense fallback={<FadeScreen />}>
          <Favorite />
        </Suspense>}
      />
      <Route path='/ordenes/historial' element={
        <Suspense fallback={<FadeScreen />}>
          <OrdersHistory />
        </Suspense>}
      />
      <Route path='/suscripciones/historial' element={
        <Suspense fallback={<FadeScreen />}>
          <SubscriptionHistory />
        </Suspense>}
      />
      <Route path='/testimonios' element={
        <Suspense fallback={<FadeScreen />}>
          <UserTestimonials />
        </Suspense>}
      />
      <Route path='/citas' element={
        <Suspense fallback={<FadeScreen />}>
          <Appointments />
        </Suspense>}
      />
      <Route path='/citas/:id' element={
        <Suspense fallback={<FadeScreen />}>
          <AppointmentDocument />
        </Suspense>}
      />
    </Routes>
  </>
);

export default UserRoutes;
