import { useTranslation } from 'react-i18next';
import { facebookIcon, GoogleIcon } from '../../../assets/vectors';
import Button from '../../../components/common/button';
import { useAppContext } from '../../../hooks';

const LoginSocialSection = () => {
  const { t } = useTranslation();
  const { location } = useAppContext();
  const openLoginSocialMedia = (type: 'google' | 'facebook') => {
    /// window.location.href = `https://pixie-login.auth.us-east-2.amazoncognito.com/login?client_id=14hal52tveiitsi69v05l2o3o&response_type=token&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_REDIRECT_PROD === 'false' ? process.env.REACT_APP_URL_BASE : location === 'USA' ? process.env.REACT_APP_REDIRECT_PROD_US : process.env.REACT_APP_REDIRECT_PROD_MX}&identity_provider=Google`;
    if (type === 'google')
      window.location.href = `https://pixie-login.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_PROD === 'false' ? process.env.REACT_APP_URL_BASE : location === 'USA' ? process.env.REACT_APP_REDIRECT_PROD_US : process.env.REACT_APP_REDIRECT_PROD_MX}&response_type=TOKEN&client_id=14hal52tveiitsi69v05l2o3o&scope=email+openid+phone+profile`;
      /// window.location.href = `https://pixie-login.auth.us-west-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_PROD === 'false' ? process.env.REACT_APP_URL_BASE : location === 'USA' ? process.env.REACT_APP_REDIRECT_PROD_US : process.env.REACT_APP_REDIRECT_PROD_MX}&response_type=TOKEN&client_id=1jgk22kbn0t2vic1chvbr72u8&scope=email+openid+phone+profile`;
      /// window.location.href = `https://pixie-login.auth.us-west-1.amazoncognito.com/oauth2/authorize?client_id=1jgk22kbn0t2vic1chvbr72u8&amp;response_type=token&amp;scope=email+openid+phone+profile&amp;redirect_uri=${process.env.REACT_APP_REDIRECT_PROD === 'false' ? process.env.REACT_APP_URL_BASE : location === 'USA' ? process.env.REACT_APP_REDIRECT_PROD_US : process.env.REACT_APP_REDIRECT_PROD_MX}`;
    else
      window.location.href = `https://pixie-login.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=${process.env.REACT_APP_REDIRECT_PROD === 'false' ? process.env.REACT_APP_URL_BASE : location === 'USA' ? process.env.REACT_APP_REDIRECT_PROD_US : process.env.REACT_APP_REDIRECT_PROD_MX}&response_type=TOKEN&client_id=14hal52tveiitsi69v05l2o3o&scope=email+openid+phone+profile`;
  };

  return (
    <div className='font-sanzSemiBold text-xs w-full flex flex-col gap-3 pb-20'>
      <h2 className='font-titles text-pixieLightBlue text-sm mb-4'>{t('loginAlsoText')}</h2>
      <Button
        className='flex justify-center items-center gap-3 bg-white shadow-lg w-full'
        rounded={true}
        padding='py-2'
        onClick={() => openLoginSocialMedia('google')}
      >
        <img src={GoogleIcon} alt={GoogleIcon} className='w-7 h-7'/>
        <span> {t('loginSocialMedia')} Google</span>
      </Button>
      <Button
        className='flex justify-center items-center gap-3 bg-white shadow-lg w-full'
        rounded={true}
        padding='py-2'
        onClick={() => openLoginSocialMedia('facebook')}
      >
        <img src={facebookIcon} alt={facebookIcon} className='w-7 h-7'/>
        <span> {t('loginSocialMedia')} Facebook</span>
      </Button>
    </div>
  );
};

export default LoginSocialSection;
