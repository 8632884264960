import { Dispatch, HTMLInputTypeAttribute, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import validator from 'validator';

import Button from '../../../components/common/button';
import TextField from '../../../components/form/textField';
import Tooltiped from '../../../components/common/tooltiped';
import TextCalendarField from '../../../components/form/textCalendarField';

import { useAppContext, useForm } from '../../../hooks';
import useValidator from '../../../hooks/useValidator';
import { RegisterForm, RegisterFormValidate } from '../../../interfaces/auth';
import authService from '../../../services/authService';
import IconButton from '../../../components/common/iconButton';

const FormRegisterSection = ({ setShowValidateCode, setEmailValidate }:FormRegisterSectionProps) => {
  // Hooks
  const [showPassword, setShowPassword] = useState<{
    password: HTMLInputTypeAttribute,
    confirmPassword: HTMLInputTypeAttribute
  }>({
    password: 'password',
    confirmPassword: 'password'
  });
  const { toast } = useAppContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { form, handleFormChange, onSubmit, setForm } = useForm<RegisterForm>({
    date_birth: '',
    confirmPassword: '',
    email: '',
    last_name: '',
    name: '',
    password: '',
    phone: '',
    role_id: { label: 'Minorista', value: 'a407ce41-e780-431e-a3e2-65fb5f92f2a6' },
    roleIdOptions: []
  },
  () => handleValidate());

  const { handlePutMessageError, validatorBody, resetValidator } = useValidator<RegisterFormValidate>({
    confirmPassword: { message: '' },
    date_birth: { message: '' },
    email: { message: '' },
    last_name: { message: '' },
    name: { message: '' },
    password: { message: '' },
    phone: { message: '' },
    role_id: { message: '' }
  });

  const handleValidate = () => {
    // Clear all errors
    resetValidator();
    let error = false;
    if (!validator.isEmail(form.email)) {
      handlePutMessageError('email', 'El texto debe ser un email');
      error = true;
    }

    if (!validator.isNumeric(form.phone)) {
      handlePutMessageError('phone', 'Solo se pueden escribir números');
      error = true;
    }

    if (!validator.isLength(form.phone, { min: 10, max: 10 })) {
      handlePutMessageError('phone', 'El celular solo debe tener 10 digitos');
      error = true;
    }

    if (!validator.isAlpha(form.name, 'es-ES', { ignore: ' ' })) {
      handlePutMessageError('name', 'Solo se debe escribir letras');
      error = true;
    }

    if (!validator.isAlpha(form.last_name, 'es-ES', { ignore: ' ' })) {
      handlePutMessageError('last_name', 'Solo se debe escribir letras');
      error = true;
    }

    if (validator.equals(form.role_id.value, '')) {
      handlePutMessageError('role_id', 'Se debe seleccionar un tipo de usuario');
      error = true;
    }

    if (!validator.isLength(form.password, { min: 4 })) {
      handlePutMessageError('password', 'La contraseña debe tener mas de 4 digitos');
      error = true;
    }

    if (!validator.equals(form.confirmPassword, form.password)) {
      handlePutMessageError('confirmPassword', 'Las contraseñas no coinciden');
      error = true;
    }

    if (!validator.isDate(form.date_birth, {
      format: 'YYYY-MM-DD'
    })) {
      handlePutMessageError('date_birth', 'El formato del cambo debe ser YYYY-MM-DD');
      error = true;
    }

    if (!error)
      handleSubmit();
  };

  const handleSubmit = () => {
    authService.sendDataRegister(form, language)
      .then(res => {
        toast.fire({
          icon: 'success',
          title: res,
        });
        setShowValidateCode(true);
        localStorage.setItem('emailValidate', form.email);
        setEmailValidate(form.email);
      })
      .catch(err =>
        toast.fire({
          icon: 'error',
          title: err,
        })
      );
  };

  const setvalue = (date?: Date) => {
    const dateConvert = date ? format(date, 'yyyy-MM-dd') : '';
    setForm(old => ({ ...old, date_birth: dateConvert }));
  };

  return (
    <form onSubmit={onSubmit} className='flex flex-col w-full gap-3'>
      <h1 className='text-pixieLightBlue flex flex-col gap-3'>
        <span className='text-xl'>{t('registerTitle')}</span>
      </h1>

      <div className='font-subTitles flex flex-col gap-3 border-b pb-8 mb-8 border-[#4A4A4A]'>
        <TextField
          handler={handleFormChange}
          name='name'
          value={form.name}
          className='w-full'
          fieldClassName='rounded-[16px] py-3 text-sm'
          placeholder={t('registeName')}
          messageError={validatorBody.name.message}
          required
        />
        <TextField
          handler={handleFormChange}
          name='last_name'
          value={form.last_name}
          className='w-full'
          fieldClassName='rounded-[16px] py-3 text-sm'
          placeholder={t('registeLastName')}
          messageError={validatorBody.last_name.message}
          required
        />
        <TextField
          handler={handleFormChange}
          name='email'
          value={form.email}
          className='w-full'
          fieldClassName='rounded-[16px] py-3 text-sm'
          placeholder={t('registeEmail')}
          messageError={validatorBody.email.message}
          required
        />
        <TextField
          handler={handleFormChange}
          name='phone'
          value={form.phone}
          className='w-full'
          fieldClassName='rounded-[16px] py-3 text-sm'
          placeholder={t('registerPhone')}
          messageError={validatorBody.phone.message}
          required
        />
        <Tooltiped label='YYYY-MM-DD'>
          <div>
            <TextCalendarField
              nameField='date_birth'
              placeholder='Fecha de nacimiento'
              setValue={setvalue}
              value={form.date_birth}
              textFielClassName='py-3'
              className='text-sm'
              limitToday={true}
              captionLayout='dropdown'
              border='rounded-[10px]'
              required
            />
          </div>
        </Tooltiped>
        <div className='relative'>
          <TextField
            handler={handleFormChange}
            name='password'
            value={form.password}
            className='w-full'
            fieldClassName='rounded-[16px] py-3 text-sm'
            placeholder={t('registerPassword')}
            messageError={validatorBody.password.message}
            type={showPassword.password}
            required
          />
          <IconButton
            name={showPassword.password === 'password' ? 'visibility' : 'visibility_off'}
            onClick={() => {
              setShowPassword(old => ({
                ...old,
                password: showPassword.password === 'password' ? 'text' : 'password'
              }));
            }}
            className='absolute right-0 top-1/2 -translate-y-1/2 text-pixieLightBlue'
            shadow={false}
            typeButton='button'
          />
        </div>
        <div className='relative'>
          <TextField
            handler={handleFormChange}
            name='confirmPassword'
            value={form.confirmPassword}
            className='w-full'
            fieldClassName='rounded-[16px] py-3 text-sm'
            placeholder={t('registerConfirmPassword')}
            messageError={validatorBody.confirmPassword.message}
            type={showPassword.confirmPassword}
            required
          />
          <IconButton
            name={showPassword.confirmPassword === 'password' ? 'visibility' : 'visibility_off'}
            onClick={() => {
              setShowPassword(old => ({
                ...old,
                confirmPassword: showPassword.confirmPassword === 'password' ? 'text' : 'password'
              }));
            }}
            className='absolute right-0 top-1/2 -translate-y-1/2 text-pixieLightBlue'
            shadow={false}
            typeButton='button'
          />
        </div>

        <div className='flex justify-center items-center mt-4'>
          <Button className='bg-primary text-[#FAD7B1] font-sanzBold text-xs rounded-[8px] lg:text-base' padding='py-2 px-10 lg:px-16 lg:py-3' type='submit' >
            {t('registerCreate')}
          </Button>
        </div>
      </div>

    </form>
  );
};

interface FormRegisterSectionProps {
  setShowValidateCode: Dispatch<SetStateAction<boolean>>,
  setEmailValidate: Dispatch<SetStateAction<string>>
}

export default FormRegisterSection;
