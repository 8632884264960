type DiscountTagProps = {
  className?: string;
  discount: number;
};
const DiscountTag = ({ className, discount }: DiscountTagProps) => (
  <div
    className={`h-[21px] md:h-[31px] lg:h-[33px] w-full max-w-[6.5625rem] rounded-[7px] py-[7px] bg-primary text-white flex justify-center items-center ${className}`}
  >
    <span className='text-[10px] md:text-[12px] lg:text-[16px] font-sanzBold leading-4 lg:leading-6'>{discount}% OFF</span>
  </div>
);

export default DiscountTag;

