import ReactDOM from 'react-dom/client';
import './i18n';

import App from './app';

/// import reportWebVitals from './reportWebVitals';

import './styles/index.css';
import './styles/custom.css';

/// if ('serviceWorker' in navigator)
//   navigator.serviceWorker.register('./js/service-worker.js', { type: 'classic' })
//     .then(registration => {
//       console.log('Service Worker registrado con éxito:', registration);
//     })
//     .catch(error => {
//       console.error('Error al registrar el Service Worker:', error);
//     });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
