import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';

import useShoppingCar from '../../hooks/useShoppingCar';

import Button from '../../components/common/button';
import Tag from '../../components/common/productCard/tag';
import ProductCounter from './productCounter';
import AttributesItem from '../../components/common/attributesItem';

import {Product, infoSelectSPandEn } from '../../interfaces/product';
import { transformAge } from '../../helpers/productHelper';
import { capitalize } from '../../helpers/capitalize';
import { sendDataLayerAddToCar, sendDataLayerViewItem } from '../../helpers/datalayerHelper';
import { basket } from '../../assets/vectors';
import { useAppContext } from '../../hooks';

const InfoSection = ({ product, attributes, showControls = true }: InfoSectionProps) => {
  // Hooks
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const { addRemoveProduct } = useShoppingCar();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { location, toast } = useAppContext();

  // Methods
  const handlePriceChange = (quantity: number, totalPrice: number) => {
    setQuantity(quantity);
  };

  useEffect(() => {
    const lastLocation = pathname.includes('catalogo');
    sendDataLayerViewItem(product, location === 'USA' ? 'USD' : 'MXN', lastLocation ? 'catalogo' : 'home');
  }, []);

  const ages = transformAge(product);

  const handleAddProduct = () => {
    if (product.status === '1' && product.quantity > 0) {
      addRemoveProduct(product, quantity);
      const lastLocation = pathname.includes('catalogo');
      sendDataLayerAddToCar({ product, quantity }, location === 'USA' ? 'USD' : 'MXN', lastLocation ? 'catalogo' : 'home');
      toast.fire({
        icon: 'success',
        title: t('successAdd'),
      });
    }
  };

  return (
    <div className='flex flex-col w-full mt-4 md:mt-0 md:h-full md:w-[37%] md:pr-9'>
      <Helmet>
        <title>pixie - {capitalize(product.name)}</title>
        <meta name='description' content={product.description.slice(0, 150)} />
        <meta name='keywords' content={`${product.age}, ${product.category}`}/>
        <meta name='Robots' content='index,follow'/>
      </Helmet>
      <div className='flex-shrink-0 px-7 mb-2 md:px-0 md:mb-4'>
        <div className='hidden gap-3 mb-3 md:flex'>
          {ages.map(age => (
            <Tag
              key={`${product.id}-age-${age}`}
              name={age}
              className='mb-1 mt-2 md:mt-0'
              sizeTags={ages.length}
            />
          ))}
        </div>
        <h1 className='text-2xl font-bold mb-3 md:mb-2 md:text-3xl text-pixieLightBlue text-left gt-title-detail-product'>
          {language === 'es' ? product.name : product.name_en ? product.name_en : ''}
        </h1>
        <h2 className='mb-2 font-subTitles text-left md:text-lg'>
          {language === 'es' ? product.description : product.description_en}
        </h2>
        { location !== 'USA' && <div className='text-sm text-fourth	font-paragraph'>{t('productSalesText')} {product.License}</div>}
      </div>

      {/* Product Counter */}
      <ProductCounter
        product={product}
        price={product.price}
        onPriceChange={handlePriceChange}
        productQuantity={product.quantity}
        showGr
      />

      {/* attributesProduct */}
      <div className='grid grid-cols-5 my-4 mx-2 pl-6 gap-4 opacity-60 lg:mx-0 lg:pl-0'>
        {attributes.map(att => (
          <AttributesItem img={`${process.env.REACT_APP_URL_S3}${language === 'es' ? att.url_image : att.url_image_en}`} key={att.name} />
        ))}
      </div>
      {showControls && (
        <div>
          {/* Cart Button */}
          <Button
            className={`
              fixed bottom-0 bg-primary gap-4 rounded-t-2xl py-4 rounded-b-none w-full z-20 md:rounded-b-2xl
              ${(product.quantity === 0 || product.status === '0') && 'cursor-not-allowed'} 
              md:relative md:py-3 hover:bg-grayText focus:outline-none focus:ring focus:ring-fourth
            `}
            onClick={handleAddProduct}
          >
            <img src={basket} />
            {(product.quantity === 0 || product.status === '0') ? (
              <span className='text-base text-[#fad7b1]'>{t('productsSoonButton')}</span>
            ) : (
              <span className='text-base text-[#fad7b1]'>{t('productsAddButton')}</span>
            )}
          </Button>

          {/* Calculator */}
          <div className='hidden justify-between mt-5 md:flex md:px-1'>
            <span className='font-subTitles text-sm pr-5'>{t('productsCalcText')}</span>
            <Button
              rounded
              className='ring-1 ring-primary text-primary h-10 font-paragraph text-sm'
              onClick={() => navigate('/calculadora')}
            >
              {t('productsCalcButton')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

interface InfoSectionProps {
  product: Product;
  attributes: infoSelectSPandEn[];
  showControls?: boolean;
}

export default InfoSection;
