export const shippingCity = {
  shippingCityTitleOne: 'If you change the shipping city your cart will be deleted',
  shippingCitySubtitleOne: 'We will show you products available for this city and more precise delivery times.',
  shippingCityTitleTwo: 'Where are you going to ship to?',
  shippingCitySubtitleTwo: 'We will show you products available for this city and more precise delivery times.',
  shippingCityCancel: 'Cancel',
  shippingCityChange: 'Change location',
  shippingCitySave: 'Save location',
  shippingCityTextNavbar: 'Your shipment will be made for',
};
