export const basket = {
  baskHeader: 'Canasta',
  baskDescription: 'Revisa tu canasta Pixie. Productos seleccionados para el bienestar de tu mascota. ¡Listo para finalizar!',
  baskTitle: 'Tu canasta',
  baskCanonical: 'https://mx.pixie.pet/canasta',
  baskResumeOrder: 'RESUMEN DE TU PEDIDO',
  baskResumeOrderSubscription: 'RESUMEN DE TU PEDIDO (SUSCRIPCIÓN)',
  baskTaxes: 'Impuestos',
  baskShipping: 'Envio',
  baskSubscription: 'Descuento suscripción',
  baskSubscriptionnNumber: 'Numeros de envíos',
  basketContinueShopping: 'Seguir comprando',
  basketNextStep: 'Siguiente',
  basketSteps1: 'Canasta',
  basketSteps2: 'Información',
  basketSteps3: 'Envíos',
  basketSteps4: 'Pago',
  basketDiscount: 'Descuento',
  basketDiscountReferred: 'Descuento referido',
  basketUnity: 'unidad',
  basketUnits: 'unidades',
  basketEmail: 'Ingresa el correo electronico',
  basketValidateEmail: 'Tienes que ingresar el correo electronico.',
  basketTextTaxes: 'Por determinar',
  basketTotalText: 'Total estimado',
  basketTaxes: 'Impuestos',
  basketRecommended: 'A tu peludo también le puede encantar',
  basketEmptyMessage: 'Selecciona los productos que quieres agregar a tu suscripción',
  basketShowStore: 'Ver tienda',
};
