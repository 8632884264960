import { useState } from 'react';
import useLogout from './useLogout';
import wholesalerAuthServices from '../services/wholesalerServices';
import { IWholesaler } from '../interfaces/wholesaler';
import { StoredAuthTokens } from '../constants/authTokens';

export const useAuthWholesaler = () => {
  const [isChecking, setIsChecking] = useState(false);
  const [user, setUser] = useState<IWholesaler | undefined>(undefined);

  const wholesaler_token = localStorage.getItem(StoredAuthTokens.WHOLESALER) || false;

  const handleCheck = async () => {
    try {
      if (!wholesaler_token) return false;
      let response = false;

      setIsChecking(true);
      // Implement check wholesaler_token service
      await wholesalerAuthServices.verifyToken(wholesaler_token)
        .then(res => {
          setUser(res);
          response = true;
          localStorage.setItem(StoredAuthTokens.WHOLESALER, wholesaler_token);
        })
        .catch(err => {
          response = false;
          localStorage.removeItem(StoredAuthTokens.WHOLESALER);
          localStorage.removeItem('wholesalerAuth');
          useLogout();
        });

      return response;
    } catch (error: any) {
      return false;
    } finally {
      setIsChecking(false);
    }
  };

  return { checkToken: handleCheck, old: wholesaler_token, isChecking, user };
};

export default useAuthWholesaler;
