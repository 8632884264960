import { useEffect } from 'react';
import { useAppContext } from '../../../hooks';
import IconButton from '../iconButton';
import useScrolled from '../../../hooks/useScrolled';

const ViewerImagePopup = () => {
  const { updateContext, showPopupViewerImage } = useAppContext();
  const { scrollTo } = useScrolled();

  const hadleClosePopup = () => {
    updateContext(old => ({ ...old, showPopupViewerImage: { show: false, url: '' } }));
  };

  useEffect(() => {
    scrollTo(0);
  }, []);

  return (
    <div
      onClick={hadleClosePopup}
      className='w-full h-screen absolute z-[9999] flex items-center justify-center top-0 left-0 p-4 bg-[#000000b6]'
    >
      <div className='w-full h-full relative'>
        <IconButton
          name='close'
          size='3xl'
          className='absolute text-primary top-0 right-0'
          onClick={hadleClosePopup}
          shadow={false}
          sizeContainer='w-5 h-5'
        />
        <div className='w-full h-full flex items-center justify-center'>
          <img src={showPopupViewerImage.url} className='w-[90%] h-[90%] object-contain' />
        </div>
      </div>
    </div>
  );
};

export default ViewerImagePopup;
