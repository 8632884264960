import { useEffect, useState } from 'react';
import FormAppointmentPet from '../../components/common/formAppointmentPet';
import { FormAppointmentPetInt, FormStepOneAppointment, ValidatePersonData } from '../../interfaces/appointment';
import TextField from '../../components/form/textField';
import useValidator from '../../hooks/useValidator';
import { useAppContext } from '../../hooks';
import { validatePetForm } from '../../helpers/appointmentHelper';
import { Pet } from '../../interfaces/user/pets';
import petsService from '../../services/userService/petsService';
import { AllDataUser } from '../../interfaces/user/user';

const StepOneAppointment = ({ handleNextStep }: StepOneAppointmentProps) => {
  const [petsCounter, setPetsCounter] = useState(1);
  const [petsUser, setPetsUser] = useState<Pet[]>([]);
  const [petsData, setPetsData] = useState<FormAppointmentPetInt[]>([]);
  const [validatePetsInfo, setValidatePetsInfo] = useState(false);
  const [dataPerson, setDataPerson] = useState({
    name: '',
    email: '',
    phone_number: ''
  });
  const { validateFormStepOne, updateContext, toast, user } = useAppContext();

  useEffect(() => {
    if (validateFormStepOne)
      handleValidate();
  }, [validateFormStepOne]);

  useEffect(() => {
    if (user)
      handleGetPreviousData(user);
  }, [user]);

  const handleGetPreviousData = async (user: AllDataUser) => {
    setDataPerson({
      email: user.user.email,
      name: `${user.user.name} ${user.user.last_name}`,
      phone_number: user.user.phone
    });

    petsService.getPetsByUser()
      .then(res => {
        setPetsUser(res);
      }).catch(err => {
        console.log(err);
      });
  };

  const handleAddPet = (pet: FormAppointmentPetInt) => {
    setPetsData(old => [...old, pet]);
    setPetsCounter(old => old + 1);
    const newPetArray = [...petsUser].filter(item => item.id !== pet.nameSelect.value);

    setPetsUser(newPetArray);
  };

  const { handlePutMessageError, resetValidator, validatorBody } = useValidator<ValidatePersonData>({
    name: { message: '' },
    email: { message: '' },
    phone_number: { message: '' }
  });

  const handleValidate = () => {
    // Clear all errors
    resetValidator();
    let error = false;

    if (dataPerson.name === '') {
      handlePutMessageError('name', 'Debes escribir tu nombre');
      error = true;
    }

    if (dataPerson.email === '') {
      handlePutMessageError('email', 'Debes escribir tu correo');
      error = true;
    }

    if (dataPerson.phone_number === '') {
      handlePutMessageError('phone_number', 'Debes escribir tu número de teléfono');
      error = true;
    }

    updateContext(old => ({
      ...old,
      validateFormStepOne: false
    }));

    setValidatePetsInfo(true);

    if (!error)
      if (validatePetForm(petsData))
        handleNextStep({
          ...dataPerson,
          pets: petsData
        });
      else
        toast.fire({
          icon: 'error',
          title: 'Debes completar todos los datos de tu mascota'
        });
  };

  return (
    <>
      <div className='flex flex-col gap-3 lg:gap-[1.40rem] font-subTitles'>
        <p className='text-lg font-titles text-pixieLightBlue'>Primero, llena tus datos</p>
        <TextField
          handler={value => {
            setDataPerson(old => ({ ...old, name: value.target.value }));
          }}
          name='name'
          value={dataPerson.name}
          placeholder='Nombre*'
          fieldClassName='py-[0.8rem]'
          messageError={validatorBody.name.message}
        />
        <div className='flex flex-col gap-3 lg:flex-row lg:gap-2'>
          <TextField
            handler={value => {
              setDataPerson(old => ({ ...old, email: value.target.value }));
            }}
            name='email'
            value={dataPerson.email}
            placeholder='Correo*'
            fieldClassName='py-[0.8rem]'
            className='lg:w-1/2'
            messageError={validatorBody.email.message}
          />
          <TextField
            handler={value => {
              setDataPerson(old => ({ ...old, phone_number: value.target.value }));
            }}
            name='phone_number'
            value={dataPerson.phone_number}
            placeholder='Teléfono*'
            fieldClassName='py-[0.8rem]'
            className='lg:w-1/2'
            messageError={validatorBody.phone_number.message}
          />
        </div>
      </div>

      {Array.from({ length: petsCounter }).map((_, index) => (
        <FormAppointmentPet
          handleAddPet={handleAddPet}
          key={`pet-${index}`}
          showButton={index === petsCounter - 1}
          setPetsData={setPetsData}
          index={index}
          validatePetsInfo={validatePetsInfo}
          setValidatePetsInfo={setValidatePetsInfo}
          petsUser={petsUser}
        />
      ))}
    </>
  );
};

interface StepOneAppointmentProps {
  handleNextStep: (data: FormStepOneAppointment) => void;
}

export default StepOneAppointment;
