/* eslint-disable complexity */
import { homeCardCats, homeCardFood, homeCardHold, homeCardHouse, homeCardPoop } from '../assets/json';
import { catIconCard, dogIconCard, foodIconCard, homeIconCard, poopIconCard } from '../assets/vectors';
import { FooterSection, ItemsBenefitsHome, ItemsContactFooter, ItemsLinkFooter, ItemsSocialFooter, Subsection } from '../interfaces';

/// import {
//   UrlBanners,
//   CardsBenefitsHome,
//   FinalSectionHome,
//   SectionResponse,
//   SectionHome,
//   SectionFooter,
//   ContactFooter,
//   LinkFooter,
//   SocialFooter
// } from '../interfaces';

// export const transformedHomeData = (data: SectionResponse) => {
//   const sectionHome = {} as SectionHome;

//   data.data.subsections.forEach(item => {
//     const nameField = item.key;

//     if (nameField === 'initial_banners')
//       sectionHome.urlBanners = item.items as UrlBanners;

//     if (nameField === 'benefits_cards')
//       sectionHome.cardsBenefits = item.items as CardsBenefitsHome;

//     if (nameField === 'final_banners')
//       sectionHome.finalSection = item.items as FinalSectionHome;
//   });

//   return sectionHome;
// };

export const transformedFooterData = (data: Subsection[], indicative: string) => {
  const dataForm = {} as FooterSection;

  if (!data) return dataForm;

  data.forEach(item => {
    const nameField = item.key;

    if (nameField === 'contact_information')
      dataForm.contact_information = {
        ...item.items,
        indicative
      } as ItemsContactFooter;

    if (nameField === 'links_interest')
      dataForm.links_interest = item.items as ItemsLinkFooter;

    if (nameField === 'social_networks')
      dataForm.social_networks = item.items as ItemsSocialFooter;
  });

  return dataForm;
};

export const getTextCountry = (country: string) => {
  switch (country) {
    case 'MEX':
      return 'mexico';
    case 'USA':
      return 'usa';
    case 'COL':
      return 'colombia';
    default:
      return 'mexico';
  }
};

export const getIndicative = (country: string) => {
  switch (country) {
    case 'MEX':
      return '(+52)';
    case 'USA':
      return '(+1)';
    case 'COL':
      return '(+57)';
    default:
      return '(+52)';
  }
};

export const getCardsBenefits = (cardsBenefits: ItemsBenefitsHome, language: string) => {
  let data = [];

  if (language === 'es')
    data = [
      {
        title: cardsBenefits?.cardTitleEs1 || '',
        description: cardsBenefits?.cardDescriptionEs1 || '',
        img: homeIconCard,
        imgSelected: homeCardHouse,
      },
      {
        title: cardsBenefits?.cardTitleEs2 || '',
        description: cardsBenefits?.cardDescriptionEs2 || '',
        img: poopIconCard,
        imgSelected: homeCardPoop,
      },
      {
        title: cardsBenefits?.cardTitleEs3 || '',
        description: cardsBenefits?.cardDescriptionEs3 || '',
        img: foodIconCard,
        imgSelected: homeCardFood,
      },
      {
        title: cardsBenefits?.cardTitleEs4 || '',
        description: cardsBenefits?.cardDescriptionEs4 || '',
        img: dogIconCard,
        imgSelected: homeCardHold,
      },
      {
        title: cardsBenefits?.cardTitleEs5 || '',
        description: cardsBenefits?.cardDescriptionEs5 || '',
        img: catIconCard,
        imgSelected: homeCardCats,
      },
    ];
  else
    data = [
      {
        title: cardsBenefits?.cardTitleEn1 || '',
        description: cardsBenefits?.cardDescriptionEn1 || '',
        img: homeIconCard,
        imgSelected: homeCardHouse,
      },
      {
        title: cardsBenefits?.cardTitleEn2 || '',
        description: cardsBenefits?.cardDescriptionEn2 || '',
        img: poopIconCard,
        imgSelected: homeCardPoop,
      },
      {
        title: cardsBenefits?.cardTitleEn3 || '',
        description: cardsBenefits?.cardDescriptionEn3 || '',
        img: foodIconCard,
        imgSelected: homeCardFood,
      },
      {
        title: cardsBenefits?.cardTitleEn4 || '',
        description: cardsBenefits?.cardDescriptionEn4 || '',
        img: dogIconCard,
        imgSelected: homeCardHold,
      },
      {
        title: cardsBenefits?.cardTitleEn5 || '',
        description: cardsBenefits?.cardDescriptionEn5 || '',
        img: catIconCard,
        imgSelected: homeCardCats,
      },
    ];

  return data;
};
