/* eslint-disable no-prototype-builtins */
/* eslint-disable no-else-return */
/* eslint-disable curly */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable max-params */
/* eslint-disable operator-assignment */
import { CartItem } from '../interfaces/basket';
import { shippingTypeForm } from '../interfaces/checkout';
import { couponComplete } from '../interfaces/coupon';
import { shippingDiscountCoupon } from '../interfaces/payment';
import { Product, ProductsFavoriteResponse } from '../interfaces/product';
import { getSubscription } from '../interfaces/subscription';
import { ReferenceConfiguration } from '../interfaces/user/references';
import { getMountDescount } from './paymentHelper';

export enum ProductStatus {}

export const transformAge = (product: Product) => {
  const ages = product.age;
  const agesArray = ages.split(',');
  const lower = agesArray.map(element => element.toLowerCase().trim());
  return lower;
};

export const transUrlImages = (product: Product) => {
  const urls = product.url_image;
  const urlsArray = [urls];
  /// const urlsArray = urls.split(',');
  return urlsArray;
};

export const calculateGrs = (item: CartItem) => {
  const { product } = item;
  const split = product.presentation.split(' ');
  const gr = parseInt(split[0].trim(), 10);
  return `${gr * item.quantity} ${split[1]}`;
};

export const calculateTotal = (arrayProducts: CartItem[], withoutIva: boolean) => {
  let total = 0;
  arrayProducts.forEach(item => {
    total += (item.quantity * item.product.price);
  });

  if (withoutIva)
    return roundToXDigits(total - calculateIvaProduct(arrayProducts), 2);

  return total < 0 ? 0 : total;
};

export const calculateIvaProduct = (arrayProducts: CartItem[]): number => {
  const total = calculateTotal(arrayProducts, false);

  return roundToXDigits(total * 0.16, 2);
};

export const calculateDiscountPrice = (
  productsCar: CartItem[],
  coupon?: couponComplete,
  percentSubscriptionDiscount?: number,
  discount?: number,
  referredConfiguration?: ReferenceConfiguration,
  shippingNumber?: number,
  valueDiscountShipping?: shippingDiscountCoupon
): number => {
  let discountPriceCoupon = 0;
  let totalProduts = calculateTotal(productsCar, false);
  // Features shipping discounts
  // const finalValueShippingPrice = valueDiscountShipping ? valueDiscountShipping.valueDiscount : 0;
  let valueDiscountShippingInside: shippingDiscountCoupon = {
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  };

  if (valueDiscountShipping && valueDiscountShipping?.finalPrice > 0) {
    valueDiscountShippingInside = valueDiscountShipping;
    totalProduts += valueDiscountShippingInside.originalValueShipping;
  }

  if (shippingNumber)
    totalProduts = totalProduts * shippingNumber;

  if (coupon)
    discountPriceCoupon = getMountDescount(productsCar, coupon, valueDiscountShippingInside);

  /// switch (coupon.couponType.key) {
  //   case 'percent':
  //     discountPriceCoupon = roundToXDigits((totalProduts * coupon.discount / 100), 2);
  //     break;
  //   case 'discount':
  //     discountPriceCoupon = roundToXDigits(coupon.discount, 2);
  //     break;
  //   default:
  //     break;
  // }
  else if (referredConfiguration)
    switch (referredConfiguration.type_reference_discount_data.key) {
      case 'percent':
        discountPriceCoupon = roundToXDigits((totalProduts * referredConfiguration.discount_referred / 100), 2);
        break;
      case 'discount':
        discountPriceCoupon = roundToXDigits(referredConfiguration.discount_referred, 2);
        break;
      default:
        break;
    }

  const iva = roundToXDigits((totalProduts - discountPriceCoupon) * 0.16, 2);
  const subscriptionDiscount = percentSubscriptionDiscount ? percentSubscriptionDiscount : 0;
  /// const totalDiscount = discount ? discount : 0;
  /// 100 - 0 - 10 - 14.4 - 90
  const discountedPrice = totalProduts - (totalProduts * subscriptionDiscount / 100) - discountPriceCoupon - iva;

  const subtotal = discountedPrice < 0 ? 0 : discountedPrice;

  return roundToXDigits(subtotal, 2);
};

export const roundToXDigits = (value: number, digits: number): number => {
  const powerOfTen = Math.pow(10, digits);
  value = value * powerOfTen;
  value = Math.round(value);
  value = value / powerOfTen;
  return value;
};

export const roundToXDigitsString = (value: number, digits: number): string => {
  const powerOfTen = Math.pow(10, digits);
  value = value * powerOfTen;
  value = Math.round(value);
  value = value / powerOfTen;
  const formattedValue = value.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });
  return formattedValue.endsWith('.00') ? formattedValue.slice(0, -3) : formattedValue;
};

export const calculateWithDiscountSubscription = (
  arrayProducts: CartItem[],
  porcentDiscount: number,
  shippingData: shippingTypeForm,
  subscription: getSubscription,
  coupon?: couponComplete,
  shippingnumber?: number,
  valueDiscountShipping?: shippingDiscountCoupon
) => {
  const totalAccesories = calculateTotalAccesories(arrayProducts, porcentDiscount, subscription);
  const productTemp = arrayProducts.filter(prod => !prod.product.subscriptionShippingOneTime || (prod.product.subscriptionShippingOneTime === 'allShipping'));
  let total = calculateTotal(productTemp, false) - ((calculateTotal(productTemp, false) * porcentDiscount) / 100);
  const shippingNumber = shippingnumber ? shippingnumber : 1;
  let discountCupon = 0;
  let shippingPrice = shippingData;
  total = total * shippingNumber;

  // Features shipping discounts
  let valueDiscountShippingInside: shippingDiscountCoupon = {
    finalPrice: 0,
    originalValueShipping: 0,
    percentDiscount: 0,
    valueDiscount: 0
  };

  if (valueDiscountShipping && valueDiscountShipping?.finalPrice > 0) {
    valueDiscountShippingInside = valueDiscountShipping;
    total += valueDiscountShippingInside.originalValueShipping;
  }

  shippingPrice = { ...shippingData, price: shippingPrice.price * shippingNumber };

  if (coupon)
    discountCupon = getPriceDiscountCouponSubscription(arrayProducts, porcentDiscount, coupon, valueDiscountShipping ?? valueDiscountShippingInside);

  const totalProductWithDiscount = total - discountCupon;
  const finalValue = ((totalProductWithDiscount + shippingPrice.price) + totalAccesories);

  return roundToXDigits(finalValue < 0 ? 0 : finalValue, 2);
};

export const calculateTotalAccesories = (arrayProducts: CartItem[], porcentDiscount: number, subscription: getSubscription) => {
  const productsAccesories = arrayProducts.filter(prod => prod.product.subscriptionShippingOneTime && prod.product.subscriptionShippingOneTime !== 'allShipping');
  let totalAccesories = 0;
  if (productsAccesories)
    productsAccesories.forEach(prod => {
      switch (prod.product.subscriptionShippingOneTime) {
        case 'oneTime':
          totalAccesories += calculateTotal([prod], false) - ((calculateTotal([prod], false) * porcentDiscount) / 100);
          break;
        case 'allmonths':
          totalAccesories += (((calculateTotal([prod], false)) - ((calculateTotal([prod], false) * porcentDiscount) / 100)) * subscription.monts);
          break;
        default:
          return 0;
      }
    });

  return totalAccesories;
};

export const calculateOnlyDiscountSubscription = (productTemp: CartItem[], porcentDiscount: number) => {
  const totalProduct = calculateTotal(productTemp, false);

  return roundToXDigits(((porcentDiscount * totalProduct / 100)), 2);
};

export const getPriceDiscountCouponSubscription = (
  arrayProducts: CartItem[],
  porcentDiscount: number,
  coupon: couponComplete,
  valueDiscountShipping: shippingDiscountCoupon
): number => {
  const productsAvalables: CartItem[] = [];
  let totalProduct = calculateTotal(arrayProducts, false);
  // Features shipping discounts
  const finalValueShippingPrice = valueDiscountShipping ? valueDiscountShipping.valueDiscount : 0;

  if (coupon.order?.items && coupon.order?.items?.some(objeto => objeto.hasOwnProperty('couponAvailable'))) {
    arrayProducts.forEach(carProduct => {
      const product = coupon.order?.items.find(item => (item.id === carProduct.product.id && item.couponAvailable));
      if (product)
        productsAvalables.push(carProduct);
    });

    totalProduct = calculateTotal(productsAvalables, false) - ((calculateTotal(productsAvalables, false) * porcentDiscount) / 100);
  }

  switch (coupon.couponType.key) {
    case 'percent':
      const valueCoupon = (totalProduct * coupon.discount / 100);
      const value = (valueCoupon > totalProduct ? totalProduct : valueCoupon) + finalValueShippingPrice;

      return roundToXDigits(value, 2);
    case 'discount':
      const valueDis = (coupon.discount > totalProduct ? totalProduct : coupon.discount) + finalValueShippingPrice;

      return roundToXDigits(valueDis, 2);
    default:
      return 0;
  }
};

export const separateByCommas = (text : string) => {
  const array = text.split(',' || '(');
  return array;
};

export const separateByCommasAndParenthesis = (text : string) => {
  const array = text.split(/[(),.]+/);
  return array;
};

export const pricex20 = (product: Product): Product => {
  const productNew = { ...product };
  try {
    if (productNew.kind_pet.toLocaleLowerCase() === 'cat' || productNew.category === 'Snacks') {
      productNew.price = formatter(productNew.price * 40, 'USD')as unknown as number;
      productNew.originalPrice = formatter(productNew.originalPrice! * 40, 'USD')as unknown as number;
    } else {
      productNew.price = formatter(productNew.price * 20, 'USD')as unknown as number;
      productNew.originalPrice = formatter(productNew.originalPrice! * 20, 'USD')as unknown as number;
    }
  } catch (err) {
    console.log(err);
  }

  return productNew;
};

export const isFavorite = (product: Product, favorites: ProductsFavoriteResponse[]): Product => {
  favorites.forEach(({ product: favorite }) => {
    if (favorite.id === product.id)
      product.isFavorite = true;
  });

  return product;
};

export const translateAgeSPToEn = (age: string) => {
  const ages = age.trim().split(',');
  const translateAge = ages.map(item => {
    switch (item) {
      case 'cachorros':
        return 'puppies';
      case 'adultos':
        return 'adults';
      case 'senior':
        return 'senior';
      default:
        return '';
    }
  });

  return `${translateAge}`;
};

export const productCombo:Product = {
  age: 'cachorros,adultos,senior',
  atributos: [],
  atributos_en: [],
  benefits: [],
  benefits_en: [],
  category: 'Alimentos',
  country: 2,
  description: '',
  description_en: '',
  dosis: '',
  dosis_en: '',
  id: '-9999',
  ingredients: [],
  ingredients_en: [],
  key: '',
  key_en: '',
  kind_pet: '',
  License: '',
  name: 'Caja x20 rollos diferentes sabores',
  name_en: 'Box x20 rolls of different recipes',
  nutrition_information: [],
  nutrition_information_en: [],
  presentation: '1 lb',
  price: 0,
  quantity: 999,
  recommendation_for_use: '',
  recommendation_for_use_en: '',
  status: '1',
  url_image: '',
  productsInside: []
};

export const formatter = (value: number, currency: string, minimumFractionDigits = 2) => {
  const valueFinal = value;

  const formatterInt = new Intl.NumberFormat('en-US', {
    currency,
    minimumFractionDigits
  });

  return formatterInt.format(valueFinal);
};

export const organiceProducts = (products: Product[]) => {
  const newArray = products.sort((a, b) => {
    if (a.status === b.status) {
      if (a.status === '1' && b.status === '1') {
        return b.quantity - a.quantity;
      }

      return 0;
    } else if (a.status === '1') {
      return -1;
    } else if (b.status === '1') {
      return 1;
    } else if (a.status === '0') {
      return -1;
    } else if (b.status === '0') {
      return 1;
    } else {
      return 0;
    }
  });

  return newArray;
};

export const getPriceWithDiscount = (price: number, discount: number) => {
  const discountValue = price * discount / 100;
  return roundToXDigits(price - discountValue, 2);
};
