import { useState } from 'react';

import IconButton from '../../components/common/iconButton';
import { formatter, roundToXDigitsString } from '../../helpers/productHelper';
import { useAppContext } from '../../hooks';
import Tooltiped from '../../components/common/tooltiped';
import { useTranslation } from 'react-i18next';
import { Product } from '../../interfaces/product';

export const ProductCounter = ({ price = 0, onPriceChange, productQuantity, className, showGr, product }: ProductCounterProps) => {
  // Hooks
  const [count, setCount] = useState(productQuantity > 0 ? 1 : 0);
  const { location } = useAppContext();
  const { t } = useTranslation();

  // Methods
  const handleCount = (value: number) => {
    const newCount = count + value;

    if (newCount < 1 || newCount > productQuantity) return;

    setCount(newCount);
    onPriceChange(newCount, (newCount * price));
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newCount = parseInt(e.target.value, 10);
    if (newCount > productQuantity)
      newCount = productQuantity;
    setCount(count => newCount);
    onPriceChange(newCount, (newCount * price));
  };

  // Constants
  const formatPrice = parseFloat(price.toString().replace(/,/g, ''));
  const totalPrice = count * formatPrice;
  const showTotal = totalPrice > price;
  const originalTotalPrice = Number(product?.originalPrice) * count;
  // Component
  return (
    <div className={`w-full flex justify-between items-center  gap-1 px-8 my-2 md:px-0 ${className && className}`}>
      <div className='flex items-center gap-6 rounded-3xl h-14  bg-grayText bg-opacity-60 text-white w-2/5'>
        <IconButton.mini
          name='remove'
          onClick={() => handleCount(-1)}
          className='shadow-none pl-2 w-1/3'
          typeButton='button'
        />
        <input
          value={count}
          type='number'
          className='w-1/3 bg-transparent text-center inputWithoutArrow'
          onChange={onChangeValue}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        />
        {count < productQuantity
          ?
          <IconButton.mini name='add' onClick={() => handleCount(1)} className='shadow-none pr-2 w-1/3' typeButton='button'/>
          :
          <Tooltiped
            label={t('productsNoMore')}
          >
            <div className='w-1/3'>
              <IconButton.mini name='add' onClick={() => handleCount(1)} className='w-full h-full opacity-50' typeButton='button'/>
            </div>
          </Tooltiped>
        }
      </div>
      <div className='flex flex-col justify-center flex-grow items-end  h-auto transform transition-all w-3/5'>
        <div className='flex gap-3 items-end text-primary font-paragraph'>
          {/* <h2 className={`
              animate__animated animate__faster
              ${showTotal ? ' text-gray-500 animate__fadeIn' : 'text-3xl font-bold animate__bounceIn'}
              ${showTotal ? '' : ''}
            `}>
              one uniti
            {location === 'USA'
              ?
              <>
                {formatter(totalPrice, 'USD')}
              </>
              :
              <>
                ${roundToXDigitsString(price, 2)}
              </>
            }
          </h2> */}
          {<h2 className='text-3xl font-bold animate__animated animate__bounceIn gt-detail-product-price'>
            {location === 'USA'
              ?
              <>
                {formatter(totalPrice, 'USD')}
              </>
              :
              <>
                ${roundToXDigitsString(totalPrice, 2)}
              </>
            }
          </h2>}
          {
            product?.productHasDiscount ? (
              <span
                className='line-through text-[#616161]'>
                { location === 'USA' ?
                  formatter(originalTotalPrice || 0, 'USD')
                  : `$${roundToXDigitsString(originalTotalPrice || 0, 2)}` }
              </span>
            ) : null
          }
          {/* {
            !product?.productHasDiscount && showTotal && (
              <span
                className='line-through animate__animated animate__faster text-gray-500 animate__fadeIn'>
                {location === 'USA'
                  ?
                  <>
                    {formatter(totalPrice, 'USD')}
                  </>
                  :
                  <>
              ${roundToXDigitsString(price, 2)}
                  </>
                }
              </span>
            )
          } */}
        </div>
        {/* {showGr && <p className='text-pixieLightBlue font-paragraph text-sm'>{(count * 500) + ' gr'}</p>} */}
        {showGr && product?.presentation && <p className='text-pixieLightBlue font-paragraph text-sm'>{product?.presentation}</p>}
      </div>
    </div>
  );
};

interface ProductCounterProps {
  price?: number;
  onPriceChange: (quantity: number, price: number) => void;
  productQuantity: number;
  className?: string,
  showGr?: boolean,
  product?: Product
}

export default ProductCounter;
