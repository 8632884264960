export const navBar = {
  navCatalogue: 'Tienda',
  navCalculator: 'Calculadora',
  navSubscriptions: 'Suscripciones',
  navAppointments: 'Citas',
  navWhereToBuy: 'Donde Comprar',
  navDates: 'Citas',
  navBlog: 'Blog',
  navWhereBuy: 'Dónde comprar',
  navBasket: 'Tu canasta',
  navSearchPlaceholder: 'Busca tu pixie favorito',
  loginWholesale: 'Ingreso Mayoristas',
};
