import { useState, useEffect } from 'react';

import Icon from '../../common/icon';

export const RadioField = ({
  className,
  label,
  value,
  name,
  changeState,
  handleRadioChange,
  currentState,
  labelClassName,
  iconCheckClassName,
  disabled
}: RadioFieldProps) => {
  // Hooks
  const [_checked, setChecked] = useState(false);

  useEffect(() => {
    if (value === currentState) {
      setChecked(true);
      handleRadioChange(currentState, name);
    } else
      setChecked(false);
  }, [currentState]);

  // Component
  return (
    <div
      onClick={() => disabled ? null : changeState(value)}
      className={`flex items-center gap-2 ${className} ${disabled && 'opacity-50'}`}
    >
      {_checked
        ?
        <Icon
          name='radio_button_checked'
          className={`${iconCheckClassName ? iconCheckClassName : 'text-fourth'} cursor-pointer`}
        />
        :
        <Icon
          name='radio_button_unchecked'
          className={`${iconCheckClassName ? iconCheckClassName : 'text-fourth'} cursor-pointer`}
        />
      }
      <span className={`${labelClassName}`}>{label}</span>
    </div>
  );
};

interface RadioFieldProps {
  value : string | boolean | number;
  currentState: string | boolean | number;
  changeState: React.Dispatch<React.SetStateAction<any>>;
  handleRadioChange: (selected: string | boolean | number, name: string) => void;
  name: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  iconCheckClassName?:string,
  disabled?: boolean;
}

export default RadioField;
