import { useState } from 'react';

import MiniImageDT from '../../components/common/miniImagenDT/MiniImageDT';

import { transUrlImages } from '../../helpers/productHelper';
import { notImage } from '../../assets/vectors/index';
import { Product } from '../../interfaces/product';
import { useAppContext } from '../../hooks';
import DiscountTag from '../../components/common/productCard/DiscountTag';

const BannerDetailDT = ({ product }: BannerDetailDTProps) => {
  // Hooks
  const [image, setImage] = useState(0);
  const { productView } = useAppContext();
  const arrayUrlImages = transUrlImages(product ? product : productView);
  const noProductImage = arrayUrlImages[0] === '';
  return (
    <div className='hidden lg:flex w-full overflow-hidden lg:h-full md:w-[63%] '>
      <div className='flex flex-col w-1/5 items-start'>
        {arrayUrlImages.map((url, index) =>
          index < 3 ? (
            <MiniImageDT
              key={url}
              src={`${process.env.REACT_APP_URL_S3}${url}`}
              handleChangeImage={setImage}
              index={index}
            />
          ) : (
            ''
          )
        )}
      </div>
      <div
        className={`relative flex justify-center ${noProductImage ? 'w-3/5' : ' w-3/4'}`}
      >
        {
          product?.productHasDiscount ? (
            <div className='absolute right-0'>
              <DiscountTag discount={product?.discount || 0} />
            </div>
          ) : null
        }
        <div>
          <img
            src={
              noProductImage
                ? notImage
                : `${process.env.REACT_APP_URL_S3}${arrayUrlImages[image]}`
            }
            className={`w-[529px] h-[446px] ${noProductImage ? '' : 'object-contain'}`}
          />
        </div>
      </div>
      {/* {arrayUrlImages[0] === '' ? (
        <div className='flex w-3/5 justify-center'>
          <div>
            <img src={notImage} className='w-[529px] h-[446px]' />
          </div>
        </div>
      ) : (
        <div className='flex w-3/4 justify-center'>
          <div>
            <img
              src={`${process.env.REACT_APP_URL_S3}${arrayUrlImages[image]}`}
              className='w-[529px] h-[446px] object-contain'
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

interface BannerDetailDTProps {
  product?: Product;
}

export default BannerDetailDT;

