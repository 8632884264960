import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExtraInfo from './ExtraInfo';

import { extraInfo } from '../../@fake/detailFake';
import { Product } from '../../interfaces/product';
import { tableCachorro, tableCachorroWhite } from '../../assets/images';
import { useAppContext } from '../../hooks';
import useScrolled from '../../hooks/useScrolled';

const ExtraInfoContainer = ({ product, paddingModal = false }: ExtraInfoContainerProps) => {
  // Hooks
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [nameTable, setNameTable] = useState(`${t('productsInfoPuppiesRange')}`);
  const [tableInfo, setTableInfo] = useState(extraInfo.infoConsumer.DOG.Cachorros);
  const { updateContext } = useAppContext();
  const { scrollTo } = useScrolled();

  useEffect(() => {
    const value = product.age.split(',')[0];
    if (product.kind_pet === 'DOG')
      switch (value.toLocaleLowerCase()) {
        case 'cachorros':
          setTableInfo(extraInfo.infoConsumer.DOG.Cachorros);
          setNameTable(`${t('productsInfoPuppiesRange')}`);
          break;
        case 'adultos':
          setTableInfo(extraInfo.infoConsumer.DOG.Adultos);
          setNameTable(`${t('productsInfoAdultsRange')}`);
          break;
        case 'senior':
          setTableInfo(extraInfo.infoConsumer.DOG.Senior);
          setNameTable(`${t('productsInfoSeniorRange')}`);
          break;

        default:
          break;
      }
    else
      switch (value) {
        case 'Cachorros':
          setTableInfo(extraInfo.infoConsumer.CAT.Cachorros);
          break;
        case 'Adultos':
          setTableInfo(extraInfo.infoConsumer.CAT.Adultos);
          break;
        case 'Senior':
          setTableInfo(extraInfo.infoConsumer.CAT.Senior);
          break;

        default:
          break;
      }
  }, [language]);

  const handleShowTable = (url: string) => {
    updateContext(old => ({ ...old, showPopupViewerImage: { show: true, url } }));
    scrollTo(0);
  };

  return (
    <div className={`hidden md:flex md:flex-col mt-1 w-full ${paddingModal ? 'pb-10' : 'mb-36'}`}>
      <div className='flex gap-4 mb-8'>
        <div className='rounded-lg w-1/2 text-sm pl-12 pr-16 bg-fifth'>
          <div className='py-7'>
            <h2 className='text-lg font-bold text-fourth text-left'>{t('productsInfoTitle')}</h2>
          </div>
          <h3 className='pb-8 pl-5 text-left'>
            <ExtraInfo
              type='list'
              infoList={language === 'es' ? product.benefits : (product.benefits_en ? product.benefits_en : [])}
            />
          </h3>
        </div>
        <div className='rounded-lg text-sm	w-1/2 px-16 bg-fifth'>
          <div className='pt-7 pb-1'>
            <h2 className='text-lg font-bold text-fourth text-left'>{t('productsInfoTable')}</h2>
          </div>
          <h3 className='pb-8 text-left'>
            {/* {(product.age.toLocaleLowerCase() === 'cachorros' && !product.dosis) ? ( */}
            {(product.age.toLocaleLowerCase() === 'cachorros' && !product.dosis) ? (
              <div
                className='mt-7 flex flex-col items-center gap-4 cursor-pointer'
                onClick={() => handleShowTable(tableCachorroWhite)}
              >
                <img src={tableCachorro} />
                <span className='text-sm font-sanzBold'>{t('productsInfoTableButton')}</span>
              </div>
            ) : (
              <>
                {product.dosis ?
                  <div
                    className='mt-7 flex flex-col items-center gap-4 cursor-pointer'
                    onClick={() => handleShowTable(`${process.env.REACT_APP_URL_S3}${language === 'es' ? product.dosis : (product.dosis_en ? product.dosis_en : product.dosis)}`)}
                  >
                    <img src={`${process.env.REACT_APP_URL_S3}${language === 'es' ? product.dosis : (product.dosis_en ? product.dosis_en : product.dosis)}`} />
                    <span className='text-sm font-sanzBold'>{t('productsInfoTableButton')}</span>
                  </div>
                  :
                  <ExtraInfo
                    type='table'
                    infoTable={tableInfo}
                    nameTable={nameTable}
                  />
                }
              </>
            )}
          </h3>
        </div>
      </div>
      <div className='flex justify-around rounded-lg px-16 bg-fifth'>
        <div className='w-2/5'>
          <div className='py-7'>
            <h2 className='text-lg font-bold text-fourth text-left'>{t('productsInfoComp')}</h2>
          </div>
          <h3 className='pb-8 pl-5 text-left'>
            <ExtraInfo
              type='list'
              infoList={language === 'es' ? product.nutrition_information : (product.nutrition_information_en ? product.nutrition_information_en : [])}
            />
          </h3>
        </div>
        <div className='w-3/5'>
          <div className='py-7'>
            <h2 className='text-lg font-bold text-fourth text-left'>{t('productsInfoCons')}</h2>
          </div>
          <h3 className='pb-8 text-left'>
            <ExtraInfo
              type='conservation'
              infoConservation={language === 'es' ? product.recommendation_for_use : (product.recommendation_for_use_en ? product.recommendation_for_use_en : '')}
            />
          </h3>
        </div>
      </div>
    </div>
  );
};

interface ExtraInfoContainerProps {
  product: Product;
  paddingModal?: boolean;
}

export default ExtraInfoContainer;
