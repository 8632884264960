import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../hooks';
import { useMemo } from 'react';
import ListInfo from '../../components/common/listInfo';

const dataFake = [
  {
    title: '¿Puedo modificar los sabores o cantidades en la suscripción?.',
    information: 'Claro que sí, comprendemos que en la variedad está el placer es por eso que puedes modificar los productos de tu suscripción antes de realizar el próximo débito. Así cada mes puedes brindar a tu peludito las dietas y productos que quieras'
  },
  {
    title: '¿Puedo modificar las fechas de entrega y/o débito de la suscripción?',
    information: 'Claro que sí, también si deseas modificar la fecha de entrega de uno de tus pedidos puedes ingresar a la página web con tu usuario y realizar el ajuste. Ten en cuenta que el modificar la fecha de un pedido puede modificar la fecha de corte para realizar el debito.'
  },
  {
    title: '¿El débito es automático?',
    information: 'Si, una vez realizada la última entrega del débito anterior se genera automáticamente el débito para continuar agendando tus entregas con la frecuencia seleccionada.'
  },
  {
    title: '¿Hay algún tiempo mínimo de permanencia? ',
    information: 'No, puedes realizar tu suscripción y cancelarla cuando lo desees. Ten en cuenta que cancelar tu suscripción no anula las entregas ya debitadas.'
  },
  {
    title: '¿Puedo cambiar los datos de entrega?',
    information: 'Claro que sí, ingresando con tu usuario en el campo iniciar sesión podrás ajustar la dirección de entrega de tu suscripción o de un pedido en particular '
  }
];

const dataFakeUSA = [
  {
    title: 'Can I Modify products or Quantities in My Subscription?',
    information: 'Absolutely! We understand that variety is the spice of life. That\'s why you can adjust the items in your subscription before the next debit. This way, you can provide your furry friend with the recipes and products he likes the most.'
  },
  {
    title: 'Can I Adjust Subscription Delivery and/or Debit Dates?',
    information: 'Absolutely! If you wish to change the delivery date of one of your orders, you can easily log into our website with your user account and make the adjustment. Please note that modifying the date of an order might also affect the cutoff date for debit processing.'
  },
  {
    title: 'Is the Debit Automated?',
    information: 'Yes, indeed! Once the final delivery of the previous debit is completed, the next debit is automatically generated to keep scheduling your deliveries as per your selected frequency'
  },
  {
    title: 'Is There a Minimum Commitment Period?',
    information: 'No, there isn\'t. You can subscribe and cancel at your convenience. Please note that canceling your subscription doesn\'t affect deliveries that have already been debited.'
  },
  {
    title: 'Can I Change My Delivery Information?',
    information: 'Absolutely! By logging in with your user account and heading to the  section, you can easily adjust the delivery address for your subscription or a specific order.'
  }
];

const DoubtsSection = () => {
  const { t } = useTranslation();
  const { location } = useAppContext();

  const dataQuestions = useMemo(() => {
    if (location === 'USA')
      return dataFakeUSA;
    return dataFake;
  }, [location]);

  return (
    <div className='bg-[#D9EEEF] w-full lg:flex lg:justify-center lg:items-center'>
      <div className='max-w-[1500px] py-10 z-20 w-full mt-10 px-6 flex flex-col gap-5 lg:flex-row lg:pt-20 lg:pb-16 lg:mt-2'>
        <div className='flex flex-col gap-3 lg:w-1/2 lg:gap-8 lg:px-28'>
          <h3 className='text-xl px-20 lg:text-[40px] lg:leading-[3.5rem]'>{t('appointmentTitleQuestions')}</h3>
          <p className='font-sanzSemiBold text-xs text-center lg:text-lg'>
            {t('appointmentSubtitleQuestions')}
          </p>
        </div>
        <ul className='flex flex-col gap-5 lg:w-1/2 lg:gap-12'>
          {dataQuestions.map((item, index) =>
            <ListInfo
              text={item.title}
              textClassName='font-sanzSemiBold text-sm lg:text-xl'
              key={item.title + index}
              textInfo={item.information}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default DoubtsSection;
