import { useState } from 'react';
import { capitalize } from '../../../helpers/capitalize';

const TabComponent = ({ tabOptions }: TabComponentProps) => {
  const [tabSelected, setTabSelected] = useState<tabOptioninfo>(tabOptions[0]);
  return (
    <div className='font-subTitles'>
      <div className='flex font-sanzBold'>
        {tabOptions.map((tab, index) => (
          <div
            className={` py-1 w-full max-w-[120px] text-pixieLightBlue flex justify-center items-center text-sm md:text-lg cursor-pointer
              ${tabSelected.value === tab.value ? 'bg-[#D9EEEF]' : 'bg-white'}
              ${index === 0 ? 'rounded-tl-[20px]' : ''}
            `}
            key={tab.value}
            onClick={() => setTabSelected(tab)}
          >
            <span>{capitalize(tab.title)}</span>
          </div>
        ))}
      </div>
      <div className='bg-white rounded-b-[20px]'>
        {tabSelected.children}
      </div>
    </div>
  );
};

interface TabComponentProps {
  tabOptions: tabOptioninfo[]
}

type tabOptioninfo = {
  title: string,
  value: string
  children: JSX.Element
}

export default TabComponent;
