export const createSubscription = {
  createSubTitle: 'Arma tu suscripción',
  stepFrequency: 'Frecuencia',
  stepShipping: 'Envios',
  stepQuantity: 'Cantidades',
  paymentFrequency: 'Elije la frecuencia de pago de tu suscripción',
  selectOption: 'Seleccionar',
  giftMessageStart: 'Con cualquier opción recibirás mensualmente un obsequio y tu envío será gratis si el valor de tu suscripción supera los',
  giftMessageEnd: 'al mes. Ten en cuenta que el próximo débito se realizará el día de tu última entrega programada',
  deliveryFrequency: '¿Cada cuánto quieres recibir tu pedido?',
  deliveryMessageStart: '*Recibirás',
  deliveryMessageEnd: 'rollos cada entrega (Si necesitas ajustar las cantidades en el siguiente paso lo puedes hacer)',
  choiceOfDayOne: 'Elije el día en el que quieres recibir tu primer pedido',
  choiceOfDayTwo: 'Elije el día de la semana en el quieres que se hagan todas tus entregas',
  choiceDayPlaceholder: 'Escoge el día',
  deliveryMessageOnlyDate: '*Tu primera entrega será el',
  deliveryMessageTwoDateStart: '*Tus entregas serán los',
  deliveryMessageTwoDateEnd: 'empezando desde el',
  quantityOfProductToReceive: 'Estos son los productos que recibirás en cada entrega:',
  productWithDifferentDelivery: 'Elije los productos que quieres recibir con una periodicidad diferente:',
  resumeSeeDetails: 'Ver detalles',
  resumePaymentFrequency: 'Frecuencia de pago',
  resumePaymentFrequencyOne: 'Cada',
  resumePaymentFrequencyMonth: 'mes',
  resumePaymentFrequencyMonths: 'meses',
  resumeDeliveryFrequency: 'Frecuencia de entrega',
  resumeDeliveryDay: 'Día de entrega',
  resumneShippingCostPerDelivery: 'Envío por entrega',
  resumeTotalDeliveries: 'Total de entregas',
  resumenTotalPaymentNextStep: 'En el siguiente paso conocerás el total a pagar de acuerdo a la frecuencia de entrega seleccionada',
  questionSectionTitle: '¿Te hizo falta agregar algún producto?',
  questionNoProblemAdd: 'No hay problema, agrega desde aquí lo que hace falta',
  questionSeeShop: 'Ver tienda',
  createSubBack: 'Atrás',
  createSubNext: 'Siguiente',
  prePay: 'Prepaga',
  errorSelectDay: 'Debes seleccionar un día de la semana y la frecuencia de envío'
};
