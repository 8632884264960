import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../../../hooks';

import { colombiaFlag, mexicoFlag, usaFlag } from '../../../assets/vectors';
import { selectCountry } from '../../../@fake/countrySelects';

const paths = ['/suscripcion/crear', '/pago'];

const PopupChancePage = () => {
  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { updateContext } = useAppContext();

  const switchLang = (lang: 'es' | 'en' = 'es') => {
    i18n.changeLanguage(lang);
    localStorage.setItem('languaje', lang);
  };

  // Methods
  const handleClosePopup = () => {
    updateContext(old => ({ ...old, showPopupGotoSite: false }));
  };

  const handleChooseMexico = () => {
    const oldCountry = localStorage.getItem('currentCountry');
    localStorage.setItem('currentCountry', 'MEX');
    const currentUrl = window.location.href;
    if (`${process.env.REACT_APP_REDIRECT_PROD}` === 'true' && `${process.env.REACT_APP_REDIRECT_PROD_MX}` !== currentUrl)
      // Redirect to the domain immediately
      window.location.href = `${process.env.REACT_APP_REDIRECT_PROD_MX}`;
    else {
      if (oldCountry && selectCountry[0].name === oldCountry) {
        const isSavedCellar = localStorage.getItem('cellarSelected');
        const workCities = localStorage.getItem('workCities');

        if (isSavedCellar && workCities)
          updateContext(old => ({
            ...old,
            countrySelected: selectCountry[0],
            location: 'MEX',
            cellarSelected: JSON.parse(isSavedCellar),
            workCities: JSON.parse(workCities)
          }));

        updateContext(old => ({
          ...old,
          countrySelected: selectCountry[0],
          location: 'MEX',
          showPopupShippingCity: (true)
          /// showPopupShippingCity: (!isSavedCellar && !workCities)
        }));
      } else {
        updateContext(old => ({
          ...old,
          countrySelected: selectCountry[0],
          location: 'MEX',
          showPopupShippingCity: true
        }));
        localStorage.removeItem('cellarSelected');
        localStorage.removeItem('workCities');
        localStorage.removeItem('shippingLocation');
        localStorage.removeItem('shippingLocationCity');
        if (paths.includes(location.pathname)) {
          localStorage.removeItem('shoppingCart');
          navigate('/catalogo');
        }
      }

      switchLang('es');
      handleClosePopup();
    }
  };

  const handleChooseUSA = () => {
    const oldCountry = localStorage.getItem('currentCountry');
    localStorage.setItem('currentCountry', 'USA');
    const currentUrl = window.location.href;

    if (`${process.env.REACT_APP_REDIRECT_PROD}` === 'true' && `${process.env.REACT_APP_REDIRECT_PROD_US}` !== currentUrl)
      // Redirect to the domain immediately
      window.location.href = `${process.env.REACT_APP_REDIRECT_PROD_US}`;
    else {
      i18n.changeLanguage('en');
      if (oldCountry && selectCountry[1].name === oldCountry) {
        const isSavedCellar = localStorage.getItem('cellarSelected');
        const workCities = localStorage.getItem('workCities');

        if (isSavedCellar && workCities)
          updateContext(old => ({
            ...old,
            countrySelected: selectCountry[1],
            location: 'USA',
            cellarSelected: JSON.parse(isSavedCellar),
            workCities: JSON.parse(workCities)
          }));

        updateContext(old => ({
          ...old,
          countrySelected: selectCountry[1],
          location: 'USA',
          showPopupShippingCity: (true)
        }));
      } else {
        updateContext(old => ({
          ...old,
          countrySelected: selectCountry[1],
          location: 'USA',
          showPopupShippingCity: true
        }));
        localStorage.removeItem('cellarSelected');
        localStorage.removeItem('workCities');
        localStorage.removeItem('shippingLocation');
        localStorage.removeItem('shippingLocationCity');
        if (paths.includes(location.pathname)) {
          localStorage.removeItem('shoppingCart');
          navigate('/catalogo');
        }
      }

      switchLang('en');
      handleClosePopup();
      /// if (isSavedCellar && workCities)
      //   getDataWorkCities(workCities);
    }
  };

  /// const getDataWorkCities = (workCities: string) => {
  //   const workData: workCities = JSON.parse(workCities);
  //   storeService.getOneWorkCity(`${workData.city_id}`).then(res => {
  //     updateContext(old => ({ ...old, cellarSelected: res.cellar, workCities: res }));
  //   });
  // };

  return (
    // <div className='absolute z-1000 flex items-start justify-center -top-[10px] -left-1 -right-1 -bottom-[10px] bg-[#000000b6] lg:-left-[10px] lg:-right-[10px]'>
    <div className='w-full h-screen absolute z-1000 flex items-center justify-center top-0 left-0 bg-[#000000b6] lg:items-center'>
      <div className='bg-sixth w-full flex flex-col justify-center items-center rounded-3xl pb-9 lg:w-auto lg:rounded-3xl'>
        {/* <div className='w-full flex justify-end px-8 pt-5'>
          <IconButton
            name='close'
            size='3xl'
            className='absolute text-grayText'
            onClick={() => handleClosePopup()}
            shadow={false}
            sizeContainer='w-5 h-5'
          />
        </div> */}
        <div className='flex flex-col lg:px-36 py-4 text-center text-sm'>
          <span className='text-xl md:text-[25px]'>Escoge un país</span>
          <div className='flex flex-col pt-9 md:flex-row gap-10 md:gap-16 md:pt-4'>
            <div className='flex gap-4 items-center md:flex-col md:justify-center md:items-center cursor-pointer' onClick={handleChooseMexico}>
              <img src={mexicoFlag} alt='mexicoFlag' className='w-12 h-12 md:w-24 md:h-24' />
              <p>México</p>
            </div>
            <div className='flex gap-4 items-center md:flex-col md:justify-center md:items-center'>
              <a href='https://pixie.net.co/'>
                <img src={colombiaFlag} alt='colombiaFlag' className='w-12 h-12 md:w-24 md:h-24'/>
              </a>
              <p>Colombia</p>
            </div>
            <div className='flex gap-4 items-center md:flex-col md:justify-center md:items-center' onClick={handleChooseUSA}>
              <img src={usaFlag} alt='usaFlag' className='w-12 h-12 md:w-24 md:h-24'/>
              <div className='leading-none'>
                <p>Estados</p>
                <p>Unidos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupChancePage;
