interface Props {
  children: JSX.Element
}

const NewModal = ({ children }: Props) => (
  <div className='w-full h-screen absolute z-1000 flex items-center justify-center top-0 left-0 p-4 bg-[#000000b6]'>
    {children}
  </div>
);

export default NewModal;
