type DiscountTagProps = {
  className?: string;
  discount: number;
};
const DiscountTag = ({ className, discount }: DiscountTagProps) => (
  <div
    className={`h-[38px] w-full max-w-[6.5625rem]  rounded-[7px] py-[7px] px-[13px] bg-primary text-white flex justify-center items-center ${className}`}
  >
    <span className='text-[16px] font-sanzBold leading-6'>{discount}% OFF</span>
  </div>
);

export default DiscountTag;

