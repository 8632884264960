import { products, shoppingCar } from './products';
import { calculator } from './calculator';
import { catalogue } from './catalogue';
import { questions } from './questions';
import { privacy } from './privacy';
import { navBar } from './navbar';
import { footer } from './footer';
import { terms } from './terms';
import { home } from './home';
import { basket } from './basket';
import { checkSubmissionForm } from './checkSubmissionForm';
import { coupon } from './coupon';
import { shipping } from './shipping';
import { payment } from './payment';
import { createCombo } from './createCombo';
import { login } from './auth';
import { register, recovery, verifyCode } from './auth';
import { userMenu } from './user/userMenu';
import { myProfile } from './user/myProfile';
import { myPets } from './user/myPets';
import { orderHistory } from './user/orderHistory';
import { appointments } from './user/appointments';
import { createSubscription } from './createSubscription';
import { basketTotalSection } from './basketTotalSection';
import { shippingCity } from './ShippingCity';
import { subscriptions } from './subscriptions';
import { appointmentsLanding } from './appointments';

export const es = {
  translation: {
    ...navBar,
    ...footer,
    ...home,
    ...catalogue,
    ...products,
    ...shoppingCar,
    ...calculator,
    ...questions,
    ...terms,
    ...privacy,
    ...basket,
    ...checkSubmissionForm,
    ...coupon,
    ...shipping,
    ...payment,
    ...createCombo,
    ...login,
    ...register,
    ...recovery,
    ...verifyCode,
    ...userMenu,
    ...myProfile,
    ...myPets,
    ...orderHistory,
    ...createSubscription,
    ...basketTotalSection,
    ...shippingCity,
    ...subscriptions,
    ...appointmentsLanding,
    ...appointments
  },
};

export default es;
