import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { calculateTotal } from '../../../helpers/productHelper';
import { useAppContext } from '../../../hooks';
import { couponComplete } from '../../../interfaces/coupon';
import { ReferenceCode } from '../../../interfaces/user/references';
import checkOutService from '../../../services/checkOutService';
import profileService from '../../../services/userService/profileService';
import TextField from '../../form/textField';
import Button from '../button';
import { shippingDiscountCoupon } from '../../../interfaces/payment';

const CouponApply = ({
  couponState,
  setCouponState,
  email,
  setConfigurationReferred,
  referred,
  disabledCoupon = true,
  isSubscriptionEdit,
  setValueDiscountShipping,
}: CouponApplyProps) => {
  const { coupon, location, products, referred: referreCode, isSubscriptionSelected } = useAppContext();
  const [subscriptionSelected, setSubscriptionSelected] = useState(isSubscriptionSelected);
  const [couponCode, setCouponCode] = useState({
    status: true,
    messageError: '',
    code: referreCode
  });
  const [isLoadingFinish, setIsLoadingFinish] = useState(0);

  // Translate
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (isSubscriptionEdit)
      setSubscriptionSelected(isSubscriptionEdit);
  }, [isSubscriptionEdit]);

  useEffect(() => {
    setCouponCode(old => ({ ...old, status: true, messageError: '' }));
  }, [couponCode.code]);

  useEffect(() => {
    if (couponState)
      validateHistoryCoupon();
    if (referreCode)
      getCodeInfoReferred(referreCode);
    return () => {};
  }, [couponState, referreCode]);

  const removeCoupon = () => {
    setCouponState(undefined);
    setConfigurationReferred(undefined);
    setCouponCode(old => ({ ...old, status: true }));
    if (setValueDiscountShipping)
      setValueDiscountShipping({ finalPrice: 0, originalValueShipping: 0, percentDiscount: 0, valueDiscount: 0 });
  };

  // Methods
  const handleValid = async () => {
    if (email)
      await getCodeInfoReferred(couponCode.code);
    else
      setCouponCode(old => ({ ...old, status: false, messageError: t('couponError5') }));
  };

  const getCoupon = async () => {
    /// await checkOutService.getCoupon(couponCode.code, location).then(res => {
    //   switch (res.purchaseType.key) {
    //     case 'subscription':
    //       if (subscriptionSelected)
    //         validateCoupon(res);
    //       else
    //         setCouponCode(old => ({ ...old, status: false, messageError: t('couponError9') }));
    //       break;
    //     case 'normal':
    //       if (subscriptionSelected)
    //         setCouponCode(old => ({ ...old, status: false, messageError: t('couponError10') }));
    //       else
    //         validateCoupon(res);
    //       break;
    //     case 'all':
    //       validateCoupon(res);
    //       break;
    //     default:
    //       validateCoupon(res);
    //       break;
    //   }

    //   setIsLoadingFinish(old => old + 1);
    // }).catch(err => {
    //   if (err.code === 401)
    //     setCouponCode(old => ({ ...old, status: false, messageError: t('couponError4') }));
    //   else
    //     setCouponCode(old => ({ ...old, status: false, messageError: t('couponError1') }));

    //   setIsLoadingFinish(old => old + 1);
    // });

    await checkOutService.getCouponComplete(
      couponCode.code,
      location,
      subscriptionSelected ? 'subscription' : 'normal',
      email ? email : '',
      products
    ).then(res => {
      validateCoupon(res);
    }).catch(err => {
      setCouponCode(old => ({ ...old, status: false, messageError: language === 'en' ? err.message_en : err.message_es }));
      setIsLoadingFinish(old => old + 1);
    });
  };

  const validateCoupon = (couponData: couponComplete) => {
    // Validate uses
    if ((couponData.totalUses >= couponData.maxUses) || couponData.status === 0)
      setCouponCode(old => ({ ...old, status: false, messageError: t('couponError2') }));
    else {
      // Validate current date
      const date = new Date(couponData.expireDate.split('T')[0]);
      date.setDate(date.getDate() + 1);
      const today = new Date();
      if (today > date)
        setCouponCode(old => ({ ...old, status: false, messageError: t('couponError3') }));
      else {
        setCouponState(couponData);
        setCouponCode(old => ({ ...old, status: true }));
      }
    }
  };

  const validateHistoryCoupon = () => {
    if (email)
      checkOutService.getCouponHistory(email, couponState).then(res => {
        const couponUsesbyUser = couponState?.usesByUser ? couponState.usesByUser : 0;
        if (res.length >= couponUsesbyUser) {
          setCouponCode(old => ({ ...old, status: false, messageError: t('couponError6') }));
          setCouponState(undefined);
        }
      }).catch(error => {
        console.log(error);
      });
    else
      setCouponCode(old => ({ ...old, status: false, messageError: t('couponError5') }));
  };

  // Code Referred
  const getCodeInfoReferred = (code: string) => {
    setIsLoadingFinish(0);
    profileService.getCodeReference(code)
      .then(res => {
        if (res.email === email)
          setCouponCode(old => ({ ...old,
            status: false,
            messageError: t('couponError8')
          }));
        else
          validateHistoryReferred(res);

        setIsLoadingFinish(old => old + 1);
      })
      .catch(() => {
        setCouponCode(old => ({ ...old,
          status: false,
          messageError: t('couponError1')
        }));
        setIsLoadingFinish(old => old + 1);
        // Get data Coupon
        getCoupon();
      });
  };

  const validateHistoryReferred = (dataReferred: ReferenceCode) => {
    profileService.getHistoryCode(couponCode.code, email ? email : '')
      .then(res => {
        if (res.length === 0) {
          setConfigurationReferred(dataReferred);
          validateMinPriceReferred(dataReferred);
        } else
          setCouponCode(old => ({ ...old,
            status: false,
            messageError: t('couponError7')
          }));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const validateMinPriceReferred = (dataReferred: ReferenceCode) => {
    const value = calculateTotal(products, false);
    if (dataReferred.reference_data.min_value_purchase > value)
      setCouponCode(old => ({ ...old,
        status: false,
        messageError: `El precio mínimo de compra debe ser mayor o igual a $${dataReferred.reference_data.min_value_purchase}`
      }));
    else
      setCouponCode(old => ({
        ...old,
        status: true,
        messageError: ''
      }));
  };

  return (
    <div className='flex-col flex mb-5'>
      <div className='relative flex'>
        <TextField
          handler={e => setCouponCode(old => ({ ...old, code: e.target.value }))}
          name='couponCode'
          value={coupon ? coupon.claimCode : couponCode.code}
          className={`w-full placeholder-slate-100 ${!(couponState === undefined) && 'opacity-40'}`}
          placeholder={t('couponCodePlaceholder')}
          disabled={(couponState !== undefined) || disabledCoupon === true || referred !== undefined}
          fieldClassName='smaller:text-[12px] font-subTitles py-[0.50rem] placeholder-pixieLightBlue'
          border={`ring-1 rounded-[10px] transform transition-all border-0 ${(!couponCode.status && isLoadingFinish === 2) ? 'ring-primary' : ' ring-pixieLightBlue'}`}
        />
        {((couponState !== undefined) || disabledCoupon === true || referred !== undefined)
          ?
          <Button
            className={`bg-pixieLightBlue
              text-sm w-[30%] tracking-normal text-white rounded-r-[10px] rounded-l-[0px] absolute right-0
            `}
            padding='py-[0.67rem]'
            onClick={removeCoupon}
          >
            {t('couponRemove')}
          </Button>
          :
          <Button
            className={`${(!couponCode.status && isLoadingFinish === 2) ? 'bg-primary' : 'bg-pixieLightBlue'} 
          text-sm w-[30%] smaller:w-[20%] smaller:text-[12px] tracking-normal text-white rounded-r-[10px] rounded-l-[0px] absolute right-0 h-full
          ${!(couponState === undefined) && 'cursor-not-allowed'}`}
            onClick={handleValid}
            disable={(couponState !== undefined) || disabledCoupon}
          >
            {t('couponApply')}
          </Button>
        }
      </div>
      {(!couponCode.status && isLoadingFinish === 2) &&
        <span className='text-primary text-xs font-subTitles'>{couponCode.messageError}</span>
      }
    </div>
  );
};

interface CouponApplyProps {
  couponState: couponComplete | undefined,
  setCouponState: Dispatch<SetStateAction<couponComplete | undefined>>,
  email?: string,
  disabledCoupon?: boolean,
  setConfigurationReferred: Dispatch<SetStateAction<ReferenceCode | undefined>>,
  referred: ReferenceCode | undefined,
  isSubscriptionEdit?: boolean,
  setValueDiscountShipping?: Dispatch<SetStateAction<shippingDiscountCoupon>>
}

export default CouponApply;
