/* eslint-disable complexity */
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../hooks';
import { useShoppingCar } from '../../../hooks/useShoppingCar';

import AddRemoveItem from '../addRemoveItem/AddRemoveItem';
import IconButton from '../iconButton';
import Tag from './tag';

import { Product } from '../../../interfaces/product';
import { formatter, roundToXDigitsString, transformAge } from '../../../helpers/productHelper';

import { basket, tagCatGray, tagDogGray, notImage, favoriteIcon } from '../../../assets/vectors/index';
import Tooltiped from '../tooltiped';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import copy from 'copy-to-clipboard';

import { anHoverBasket } from '../../../assets/json';
import { useTranslation } from 'react-i18next';
import useScrolled from '../../../hooks/useScrolled';
import favoriteService from '../../../services/userService/favoriteService';
import { sendDataLayerAddToCar } from '../../../helpers/datalayerHelper';
import { PixieContries } from '../../../helpers/pixieApp';
import DiscountTag from './DiscountTag';

export const ProductCard = ({
  product,
  showControls = true,
  className,
  isCarrousel,
  selected,
  showHeader = true,
  showDetailInPopup = false,
  ubication = 'MEX',
  reloadData,
  isRecomendation = false,
  isCalculator = false
}: ProductCardProps) => {
  // Hooks
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const ages = transformAge(product);
  const { pathname } = useLocation();
  const { updateContext, isAuthenticated, toast } = useAppContext();
  const { addRemoveProduct } = useShoppingCar();
  const [isMobile, setIsMobile] = useState(false);
  const [counter, setCounter] = useState(product.quantity > 0 ? 1 : 0);
  const [clickBasket, setClickBasket] = useState(false);
  const [isViewFavorite, setIsViewFavorite] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showHoverComingSoon, setShowHoverComingSoon] = useState(false);
  const location = useLocation();
  const { scrollTo, scrolledData: { scrolled } } = useScrolled();

  useEffect(() => {
    if (screen.width < 800) setIsMobile(true);
  }, [screen.width]);

  useEffect(() => {
    if (pathname.includes('/usuario/productos/favoritos')) setIsViewFavorite(true);
  }, []);

  // Handle
  const handleSubmit = () => {
    updateContext(old => ({ ...old,
      productView: product,
      lastLocationAndScroll: {
        path: location.pathname,
        scroll: scrolled
      }
    }));
    if (showDetailInPopup) updateContext(old => ({ ...old, showPopup: true }));
    else if (location.pathname.includes('catalogo')) navigate(`${location.pathname}/detalle/` + product.key);
    else navigate('/producto/detalle/' + product.key);
  };

  const copyToClipboard = () => {
    copy(`${process.env.REACT_APP_URL_BASE}producto/detalle/` + product.key);

    toast.fire({
      icon: 'success',
      title: t('productsCopy'),
    });
  };

  const handleChange = () => {
    if (product.quantity > 0) {
      addRemoveProduct(product, counter);
      setCounter(1);
      setClickBasket(true);
      setTimeout(() => {
        setClickBasket(false);
      }, 1000);
      // Send datalayer
      if (pathname.includes('catalogo'))
        sendDataLayerAddToCar({ product, quantity: counter }, ubication === 'USA' ? 'USD' : 'MXN', 'catalogo');
      else sendDataLayerAddToCar({ product, quantity: counter }, ubication === 'USA' ? 'USD' : 'MXN', 'home');
    }
  };

  const addCounter = (value: number) => {
    const newCount = counter + value;

    if (newCount < 1 || newCount > product.quantity) return;

    setCounter(counter => counter + value);
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newCount = parseInt(e.target.value, 10);
    if (newCount > product.quantity) newCount = product.quantity;
    setCounter(counter => newCount);
  };

  const isEs = language === 'es';
  const productName = isEs ? product.name : product.name_en;
  const shouldValidaStatus = ubication === 'USA';
  const isActiveProduct = shouldValidaStatus ? product.status !== '2' : true;
  const saveFavorite = () => {
    if (isAuthenticated)
      if (product.isFavorite === true) deleteFavorite();
      else
        favoriteService
          .saveFavorite(product)
          .then(() => {
            product.isFavorite = true;
            toast.fire({
              icon: 'success',
              title: t('productsAddFavorite'),
            });
          })
          .catch(err => {
            console.log(err);
          });
    else {
      const isUnDesktop = window.innerWidth > 800;
      if (isUnDesktop) {
        scrollTo(0);
        updateContext(old => ({
          ...old,
          showPopupAuth: {
            status: true,
            view: 1,
          },
          isLoginSubscription: false
        }));
      } else
        navigate('/autenticacion/ingresar');
    }
  };

  const deleteFavorite = () => {
    favoriteService
      .deleteFavorite(product)
      .then(() => {
        product.isFavorite = false;
        toast.fire({
          icon: 'success',
          title: t('productRemoveFavorite'),
        });
        if (reloadData) reloadData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const productIsNotAvailable = product.quantity === 0 || product.status === '0';
  // Component
  return (
    <div
      className={`
          relative flex flex-col flex-shrink-0 justify-between items-center
          h-[17.5rem] smaller:w-48  md:w-[16.815rem] md:h-[380px]
          ${isRecomendation ? 'lg:w-[15.815rem] 2xl1:w-[17.815rem]' : '2xl1:w-[19.815rem]'}
           2xl1:h-[414px] gt-product-cart
          ${className} ${isViewFavorite ? 'w-full' : 'w-52'}
          ${isCarrousel && (selected ? 'scale-125 lg:scale-100' : 'scale-100 lg:scale-90')}
        `}
    >
      <div
        className={`
          relative flex flex-col flex-shrink-0 justify-between items-center 
          cursor-pointer p-[0.60rem] pb-8 rounded-2xl bg-white
          ring-0 ring-pixieLightBlue transform transition-all 
          w-full h-full lg:pb-11 hover:ring-1 
          ${(product.quantity === 0 || product.status === '0') && 'opacity-60'}
          ${className}
        `}
        onClick={() => (isViewFavorite ? setShowOptions(!showOptions) : handleSubmit())}
        onMouseOver={() => {
          setShowHoverComingSoon(true);
          setShowOptions(true);
        }}
        onMouseLeave={() => {
          setShowHoverComingSoon(false);
          setShowOptions(false);
        }}
      >
        {/* Hover option with the user is in favoriteList */}
        {isViewFavorite && showOptions && (
          <div className='absolute bg-[#00000081] z-50 w-full h-full rounded-2xl top-0 flex justify-center items-center'>
            <IconButton name='visibility' onClick={handleSubmit} className='text-white' size='3xl' shadow={false} />
            <div style={{ pointerEvents: 'none' }}>
              <IconButton name='favorite' onClick={deleteFavorite} className='text-white' size='3xl' shadow={false} />
            </div>
            <IconButton name='share' onClick={copyToClipboard} className='text-white' size='3xl' shadow={false} />
          </div>
        )}
        <div className={`${!showHeader && ''} flex justify-between w-full mb-8 relative z-30`}>
          <div
            className={`grid ${ages.length > 1 ? 'grid-cols-2  w-[50%] lg:w-[60%]' : ''} items-start justify-center gap-1 absolute left-1`}
          >
            {ages.map(age => (
              <Tag key={`${product.id}-age-${age}`} name={age} className='w-full' sizeTags={ages.length} />
            ))}
            {
              !productIsNotAvailable && product.productHasDiscount ? (
                <DiscountTag discount={product.discount || 0} />
              ) : null
            }
          </div>
          <IconButton.mini
            img={product.kind_pet === 'CAT' ? tagCatGray : tagDogGray}
            name={productName + '-tag-' + product.kind_pet}
            className={`text-pixieLightBlue p-1 shadow-none md:mr-1 absolute right-6 md:right-9 
            ${product.kind_pet === 'CAT' ? 'bg-[#FFB88A]' : 'bg-[#F7EBA8]'}`}
            imgClassName='brightness-[0.30] opacity-80'
            onClick={() => console.log(product.kind_pet)}
          />
        </div>
        <div className='flex-grow overflow-hidden rounded-md'>
          {product.url_image === '' ? (
            <img src={notImage} className='w-24 h-24 md:w-36 md:h-36' />
          ) : (
            <img
              src={`${process.env.REACT_APP_URL_S3}${product.url_image}`}
              className='w-full h-36 md:w-[15.7rem] md:h-[15.1rem] top-[0.75rem] object-cover right-0 transform transition-all duration-200 hover:scale-[2.2]'
            />
          )}
        </div>
        <div className={'text-center text-xs md:text-lg w-full'}>
          {productName && productName.length > 22 && !isMobile ? (
            <div className='flex flex-col gap-0 mb-3 md:mb-0'>
              <Tooltiped label={productName}>
                <h4 className='text-pixieLightBlue m-0 p-0'>{productName.slice(0, 22) + '...'}</h4>
              </Tooltiped>
              {ubication === 'USA' && (
                <span className='font-sanzBold text-sm text-pixieLightBlue'>
                  {(product.kind_pet.toLocaleLowerCase() === 'cat' || product.category === 'Snacks') ? (
                    <>({t('productBoxInfoX40')})</>
                  ) : (
                    <>({t('productBoxInfoX20')})</>
                  )}
                </span>
              )}
            </div>
          ) : (
            <div className='flex flex-col gap-0 mb-3 md:mb-0'>
              <h4
                className={`${productName && productName?.length > 22 && 'leading-none'} text-pixieLightBlue m-0 p-0`}
              >
                {productName ? productName : ''}
              </h4>
              {ubication === 'USA' && (
                <span className='font-sanzBold text-sm text-pixieLightBlue'>
                  {(product.kind_pet.toLocaleLowerCase() === 'cat' || product.category === 'Snacks') ? (
                    <>({t('productBoxInfoX40')})</>
                  ) : (
                    <>({t('productBoxInfoX20')})</>
                  )}
                </span>
              )}
            </div>
          )}
          { isActiveProduct && (
            <div className='flex items-center justify-between px-4'>
              <p className='font-sanzBold text-base md:text-xl flex flex-col'>
                <span className={`${product.productHasDiscount ? 'text-primary' : ''}`}>
                  ${ubication === 'USA' ? formatter(product.price, 'USD') : roundToXDigitsString(product.price, 2)}
                </span>
                {
                  product.productHasDiscount ? (
                    <span className='text-sm font-medium line-through text-left'>${
                      ubication === 'USA' ? formatter(product.originalPrice!, 'USD') : roundToXDigitsString(product.originalPrice!, 2)
                    } </span>
                  ) : null
                }
                {/* {ubication === 'USA' ? (
                  <span>${product.price} </span>
                  // <span>${formatter(product.price, 'USD') } </span>
                ) : (
                  <span>${roundToXDigitsString(product.price, 2)} </span>
                )} */}
                <span className='text-xs font-semibold hidden lg:inline-flex '>
                  {product.presentation.toLocaleLowerCase()}
                </span>
              </p>
              {showControls && (
                <div className='w-16 h-6 md:w-20 md:h-8'>
                  <AddRemoveItem
                    handleChance={addCounter}
                    counter={counter}
                    onhandleChangeInput={onChangeValue}
                    showAdd={counter < product.quantity}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {showControls && isActiveProduct && (
        <div
          className={`${
            clickBasket
              ? 'bottom-[-3.9rem] md:bottom-[-4.3rem]'
              : 'bg-primary md:-bottom-4 shadow-[0_2px_10px_0_rgba(65,65,65,0.4)]'
          } absolute -bottom-[1rem] text-white z-30 rounded-full`}
        >
          {clickBasket ? (
            <Lottie animationData={anHoverBasket} loop={true} className='w-32 h-32 md:w-36 md:h-36' />
          ) : (
            <IconButton.mini
              imgClassName='w-6 h-6 md:w-7 md:h-7'
              sizeContainer='w-[35px] h-[35px] md:w-[43px] md:h-[43px] tg-basket-product-card'
              img={basket}
              name='basket'
              type='outlined'
              size='xs'
              onClick={() => handleChange()}
            />
          )}
        </div>
      )}
      {(product.quantity === 0 || product.status === '0') && (
        <div
          className={`
            absolute -bottom-[1rem] text-primary z-40 rounded-full bg-[#FFF6EC] shadow-md
            text-[10px] md:text-xs px-3 py-2 md:px-5 md:md:py-4 
            ${showHoverComingSoon ? 'ring-1 ring-pixieLightBlue' : 'ring-0'}
          `}
        >
          <span>{t('productsSoonButton')}</span>
        </div>
      )}
      <IconButton.mini
        img={product.isFavorite ? (product.isFavorite ? undefined : favoriteIcon) : favoriteIcon}
        name='favorite'
        className={`
          p-2 shadow-none md:mr-1 absolute right-[0.1rem] md:right-[0.1rem] bg-primary text-white 
          ${isCalculator ? 'top-[2.8rem] lg:top-[1.3rem]' : 'top-[0.6rem]'}
        `}
        size='2xl'
        shadow={false}
        type='outlined'
        onClick={saveFavorite}
      />
    </div>
  );
};

interface ProductCardProps {
  product: Product;
  showControls?: boolean;
  className?: string;
  isCarrousel?: boolean;
  selected?: boolean;
  showHeader?: boolean;
  showDetailInPopup?: boolean;
  ubication?: PixieContries;
  reloadData?: () => void;
  isRecomendation?: boolean,
  isCalculator?: boolean
}

export default ProductCard;
