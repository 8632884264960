import { Dispatch, SetStateAction } from 'react';
import { veterinaryAppointment } from '../../assets/images';
import Button from '../../components/common/button';

const BannerAppointmentSection = ({ setShowCreateAppointment }: BannerSectionProps) => (
  <div className='min-h-[20rem] w-full flex flex-col gap-4 md:gap-0 md:flex-row lg:min-h-[30rem] max-w-[1500px]'>
    <div className='z-20 pt-16 flex flex-col gap-4 items-center px-5 md:px-0 lg:w-1/2 lg:pl-32 lg:pr-16'>
      <h1 className='text-[30px] lg:text-left lg:text-[40px]'>
        Lorem ipsum dolor sit amet, consectetur
      </h1>
      <h2 className='text-sm lg:text-left lg:text-lg font-sanzBold'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
      </h2>
      <div className='flex justify-center items-center lg:justify-start lg:items-start w-full mt-3'>
        <Button
          className='lg:rounded-[20px] bg-primary text-white font-sanzBold text-base md:text-sm lg:text-lg'
          padding='px-3 py-1.5 md:px-7 md:py-2'
          onClick={() => setShowCreateAppointment(true)}
        >
          Solicita cita gratis
        </Button>
      </div>
    </div>
    <div className='lg:block md:mt-20 lg:mt-32 xl:mt-0 lg:w-1/2'>
      <img src={veterinaryAppointment}/>
    </div>
  </div>
);

interface BannerSectionProps {
  setShowCreateAppointment: Dispatch<SetStateAction<boolean>>

}

export default BannerAppointmentSection;
