import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/common/button';
import CheckField from '../../../components/form/checkField';
import TextField from '../../../components/form/textField';

import { useAppContext, useLogin } from '../../../hooks';
import IconButton from '../../../components/common/iconButton';
import { HTMLInputTypeAttribute, useState } from 'react';

const FormLoginSection = () => {
  const navigate = useNavigate();
  const { showPopupAuth, updateContext, isLoginSubscription } = useAppContext();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<HTMLInputTypeAttribute>('password');

  const {
    form,
    handleFormChange,
    onSubmit,
    validatorBody
  } = useLogin({ email: '', password: '' });

  const handleChangeView = () => {
    if (showPopupAuth.status)
      updateContext(old => ({ ...old, showPopupAuth: { status: old.showPopupAuth.status, view: 3 } }));
    else
      navigate('/autenticacion/recuperar');
  };

  const handleShowPassword = (show: boolean) => {
    if (show)
      setShowPassword('text');
    else
      setShowPassword('password');
  };

  return (
    <form onSubmit={onSubmit} className='flex flex-col w-full gap-3 lg:gap-5'>
      <h1 className='text-pixieLightBlue flex flex-col gap-3 mt-3'>
        {isLoginSubscription
          ?
          <>
            <span className='text-xl'>{t('loginTitle')}</span>
            <span className='text-[10px]'>Para continuar con la suscripción debes registrarte o iniciar sesión</span>
          </>
          :
          <>
            <span className='text-2xl'>{t('loginTitle')}</span>
            <span className='text-sm'>{t('LoginsignIn')}</span>
          </>
        }
      </h1>

      <div className='font-subTitles flex flex-col gap-3 border-b pb-8 mb-8 border-[#4A4A4A]'>
        <TextField
          handler={handleFormChange}
          name='email'
          value={form.email}
          className='w-full'
          fieldClassName='rounded-[16px] py-3 text-sm'
          placeholder={t('loginEmail')}
          messageError={validatorBody.email.message}
          required
        />
        <div className='relative'>
          <TextField
            handler={handleFormChange}
            name='password'
            value={form.password}
            className='w-full'
            fieldClassName='rounded-[16px] py-3 text-sm'
            placeholder={t('loginPassword')}
            type={showPassword}
            required
            messageError={validatorBody.password.message}
          />
          <IconButton
            name={showPassword === 'password' ? 'visibility' : 'visibility_off'}
            onClick={() => showPassword === 'password' ? handleShowPassword(true) : handleShowPassword(false)}
            className='absolute right-0 top-1/2 -translate-y-1/2 text-pixieLightBlue'
            shadow={false}
            typeButton='button'
          />
        </div>
        <div className='flex justify-between mt-2'>
          <CheckField
            onClick={() => {}}
            label={t('loginRemember')}
            sizeContainer='w-[21px] h-[21px]'
            labelClassName='font-subTitles text-xs text-[#616161]'
            border='border border-pixieLightBlue rounded-none'
          />
          <span className='border-b border-gray-500 font-subTitles text-xs text-[#616161] pb-0 cursor-pointer' onClick={() => handleChangeView()}>
            {t('loginRecoverPassword')}
          </span>
        </div>
        <div className='flex justify-center items-center mt-4'>
          <Button className='bg-primary text-[#FAD7B1] font-sanzBold text-xs rounded-[8px]' padding='py-2 px-10' type='submit'>
            {t('login')}
          </Button>
        </div>
      </div>

    </form>
  );
};

export default FormLoginSection;
