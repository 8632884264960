import { useNavigate } from 'react-router-dom';

import { useAppContext, useForm } from './';

import { LoginForm, LoginValidate } from '../interfaces/auth';
import validator from 'validator';
import useValidator from './useValidator';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IWholesaler } from '../interfaces/wholesaler';
import wholesalerAuthServices from '../services/wholesalerServices';
import { StoredAuthTokens } from '../constants/authTokens';

export const useLoginWholesaler = (initialState: LoginForm) => {
  // Hooks
  const { updateContext, toast } = useAppContext();
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(true);
  const {
    i18n: { language },
  } = useTranslation();

  const { handlePutMessageError, validatorBody, resetValidator } = useValidator<LoginValidate>({
    email: { message: '' },
    password: { message: '' },
  });

  useEffect(() => {
    changeResize();
    window.addEventListener('resize', changeResize);
  }, []);

  const changeResize = () => {
    if (screen.width < 800)
      setIsDesktop(false);
    else
      setIsDesktop(true);
  };

  // Validate
  const handleValidate = () => {
    // Clear all errors
    resetValidator();
    let error = false;
    if (!validator.isEmail(form.email)) {
      handlePutMessageError('email', 'El texto debe ser un email');
      error = true;
    }

    if (!validator.isLength(form.password, { min: 4 })) {
      handlePutMessageError('password', 'La contraseña debe tener mas de 4 digitos');
      error = true;
    }

    if (!error)
      handleLogin();
  };

  // Login Handler
  const handleLogin = async () => {
    wholesalerAuthServices.login(form, language)
      .then(res => {
        setData(res.data, res.token);
        toast.fire({
          icon: 'success',
          title: 'Logeado correctamente',
        });
        navigate('/mayoristas/catalogo');
      }).catch(err =>
        toast.fire({
          icon: 'error',
          timer: 10000,
          title: `${language === 'es' ?
            'Usuario o contraseña incorrectos por favor intente nuevamente o intente por otro metodo'
            :
            'Wrong username or password please try again or try another method'
          }`,
        })
      );
  };

  const setData = (user: IWholesaler, token: string) => {
    console.log(user);

    localStorage.setItem(StoredAuthTokens.WHOLESALER, token);
    updateContext(old => ({
      ...old,
      wholesaler: user,
      wholesalerAuthenticated: true,
      isLoadingPreData: true,
      showPopupAuth: {
        status: false,
        view: 1
      } }));

    if (!isDesktop)
      navigate('/mayoristas/login');
  };

  // Form
  const { form, clear, handleFormChange, onSubmit } = useForm<LoginForm>(initialState, handleValidate);

  return { form, handleFormChange, clear, onSubmit, validatorBody, setData };
};

export default useLoginWholesaler;
