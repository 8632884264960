import { isValidNumber } from '../../helpers/numbers';
import { formatter, getPriceWithDiscount, isFavorite, pricex20 } from '../../helpers/productHelper';
import { GetOneProductInCellarResponse, infoSelectSPandEn, Product, ProductInCellar, ProductListResponse, ProductsFavoriteResponse, ProductsInCellarResponse } from '../../interfaces/product';
import api from '../axios';
import favoriteService from '../userService/favoriteService';

type getAllProductsInCellarByIdParams = {
  cellarId: string;
  limit?: number;
  convertx20?: boolean;
  country?: number;
  textSearch?: string;
};
export class ProductService {
  init() {}

  async getAllProductsInCellarById({ cellarId: id, limit = 1000, textSearch = '', convertx20 = false }: getAllProductsInCellarByIdParams) {
    const token = window.localStorage.getItem('token');
    let favoritesUser: ProductsFavoriteResponse[] = [];
    if (token !== null) {
      const favirteResponse = await favoriteService.getFavoriteByUser();
      favoritesUser = favirteResponse;
    }

    const productResponse = await api.get<ProductsInCellarResponse>(`pixie-payments/api/cellars-products/cellar_id/${id}?limit=${limit}&filter=${textSearch}`);
    const allProductsIncellar = productResponse?.data?.data?.map((pInCellar: ProductInCellar) => {
      const { price: priceInCellar, quantity, discount_promotion = '0' } = pInCellar;
      const price = priceInCellar?.replaceAll(',', '');

      const discountValue = Number(discount_promotion);
      const productHasDiscount = discount_promotion && isValidNumber(discount_promotion) && discountValue > 0;

      if (convertx20) {
        const productResult = {
          ...pInCellar,
          product: {
            ...pInCellar.product,
            productHasDiscount: Boolean(productHasDiscount),
            discount: discountValue,
            originalPrice: Number(price),
            /// price: productHasDiscount ? getPriceWithDiscount(Number(price), discountValue) : Number(price),
            price: Number(price),
            quantity: Number(quantity),
            isFavorite: favoritesUser.some(({ product: fav }) => fav.id === pInCellar.product.id),
          }
        };

        let finalPrice = productResult.product.price;
        let finalOriginalPrice = productResult.product.price;

        if (productResult.product.kind_pet.toLocaleLowerCase() === 'cat' || productResult.product.category === 'Snacks') {
          finalPrice *= 40;
          finalOriginalPrice *= 40;
          /// productResult.product.price = formatter(Number(productResult.product.price) * 40, 'USD')as unknown as number;number;
        } else {
          finalPrice *= 20;
          finalOriginalPrice *= 20;
          /// productResult.product.price = formatter(Number(productResult.product.price) * 20, 'USD')as unknown as number;
        }

        finalPrice = productHasDiscount ? getPriceWithDiscount(finalPrice, discountValue) : finalPrice;
        /// productResult.product.price = formatter(finalPrice, 'USD') as unknown as number;
        /// productResult.product.originalPrice = formatter(finalOriginalPrice, 'USD') as unknown as number;
        productResult.product.price = finalPrice;
        productResult.product.originalPrice = finalOriginalPrice;
        return productResult;
      }

      return {
        ...pInCellar,
        product: {
          ...pInCellar.product,
          productHasDiscount: Boolean(productHasDiscount),
          discount: discountValue,
          originalPrice: Number(price),
          price: productHasDiscount ? getPriceWithDiscount(Number(price), discountValue) : Number(price),
          quantity: Number(quantity),
          isFavorite: favoritesUser.some(({ product: fav }) => fav.id === pInCellar.product.id),
        }
      };
    });

    const products = allProductsIncellar.map(p => p.product);

    return {
      status: productResponse.status,
      products,
      allProductsIncellar,
    };
  }

  getAllProducts = async (country: number, convertx20:boolean): Promise<ProductListResponse> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      let favoritesUser: ProductsFavoriteResponse[] = [];
      const token = window.localStorage.getItem('token');
      if (token !== null)
        await favoriteService.getFavoriteByUser().then(res => {
          favoritesUser = res;
        }).catch(err => {
          console.log(err);
        });
      api.get(`pixie-payments/api/products?showInactive=false&limit=10000&order_by=created_at&country=${country}`).then(res => {
        // Knows if the product is user favorite
        res.data.products.map((product:Product) => isFavorite(product, favoritesUser));
        // If is USA the price is x20
        if (country === 2 && convertx20) {
          const newDataProduct = res.data.products.map((product:Product) => pricex20(product));
          const data = { ...res.data, products: newDataProduct };
          resolve(data);
        }

        resolve(res.data);
      }).catch(error => {
        reject(error);
      });
    });

  getQueryProducts = async (textSearch: string | null): Promise<ProductListResponse> => {
    const { data } = await api.get(`pixie-payments/api/products?filter=${textSearch}`);
    return data;
  };

  getOneProductById = async (id: number, convertx20:boolean, country: number): Promise <Product> => new Promise((resolve, reject) => {
    api.get(`pixie-payments/api/products/${id}`)
      .then(response => {
        // If is USA the price is x20
        if (country === 2 && convertx20) {
          const newDataProduct = pricex20(response.data);
          resolve(newDataProduct);
        }

        resolve(response.data);
      }).catch(error => {
        reject(error?.response?.data?.message);
      }
      );
  });

  getOneProductByKeyInCellar = async (productKey: string, cellarId: number, convertx20:boolean) => {
    const { data: dataResponse } = await api.get<GetOneProductInCellarResponse>(`pixie-payments/api/cellars-products/product-datails/cellard_id/${cellarId}/key_product/${productKey}`);

    const { quantity, price: priceInCellar, discount_promotion = '0', product } = dataResponse.data;
    const price = priceInCellar?.replaceAll(',', '');
    const discountValue = Number(discount_promotion);
    const productHasDiscount = discount_promotion && isValidNumber(discount_promotion) && discountValue > 0;

    const productResult: Product = {
      ...product,
      quantity: Number(quantity),
      productHasDiscount: Boolean(productHasDiscount),
      discount: discountValue,
      originalPrice: Number(price),
      price: productHasDiscount ? getPriceWithDiscount(Number(price), discountValue) : Number(price),
    };
    // If is USA the price is x20
    if (convertx20)
      return pricex20(productResult);

    return productResult;
  };

  getOneProductByKey = async (key: string | undefined, convertx20:boolean): Promise <Product> => new Promise((resolve, reject) => {
    api.get(`pixie-payments/api/products/key/${key}`)
      .then(response => {
        // If is USA the price is x20
        if (convertx20) {
          const newDataProduct = pricex20(response.data);
          resolve(newDataProduct);
        }

        resolve(response.data);
      }).catch(error => {
        reject(error?.response?.data?.message);
      }
      );
  });

  getIngredients = (): Promise<infoSelectSPandEn[]> => new Promise((resolve, reject) => {
    api.get('pixie-payments/api/ingredients?limit=100')
      .then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error?.response?.data?.message);
      }
      );
  }
  );

  getAttributes = (): Promise<infoSelectSPandEn[]> => new Promise((resolve, reject) => {
    api.get('pixie-payments/api/attributes?limit=100')
      .then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error.response.data.message);
      }
      );
  }
  );
}

export default new ProductService();

