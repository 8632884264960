export const userMenu = {
  userMenuMyProfile: 'My profile',
  userMenuMyPets: 'My pets',
  userMenuSubscription: 'Subscription',
  userMenuOrderHistory: 'Orders history',
  userMenuFavorites: 'Favorites',
  userMenuTestimonials: 'Testimonials',
  userMenuAppointments: 'Appointments',
  userMenuLogOut: 'log out'
};
