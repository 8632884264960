import { useEffect, useState } from 'react';

import { useAppContext } from '../../../hooks';
import IconButton from '../iconButton';

import { dog, dogEn } from '../../../assets/vectors';
import { useTranslation } from 'react-i18next';

const ButtonWhatsap = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const [hover, setHover] = useState(false);
  const { marginWhatsApp, location } = useAppContext();

  const isSpanish = language === 'es';

  useEffect(() => {
    if (location === 'USA')
      handleShowhubspotButton();
    else {
      const element = document.getElementById('hs-script-loader');
      setTimeout(() => {
        /// const hubspotButton = document.getElementById('hubspot-conversations-iframe');
        const element = document.getElementById('hubspot-messages-iframe-container') as HTMLElement;
        element?.classList?.remove('show-iframe');
        element?.classList?.add('hidden-iframe');
      }, 1000);

      if (element !== null)
        element.remove();
    }
  }, [location]);

  const handleShowhubspotButton = () => {
    const element = document.getElementById('hubspot-messages-iframe-container') as HTMLElement;
    element?.classList?.remove('hidden-iframe');

    element?.classList?.add('show-iframe');
    /// const script = document.createElement('script');
    // script.src = '//js-na1.hs-scripts.com/23739124.js';
    // script.id = 'hs-script-loader';
    // script.type = 'text/javascript';
    // script.async = true;
    // script.defer = true;
    // document.head.appendChild(script);
  };

  return (
    <>
      {location !== 'USA' &&
        <a href='https://wa.me/5215513070792' target='_blank' rel='noopener noreferrer'>
          <IconButton
            className={`
            fixed bottom-3 left-[43%] items-center z-50 p-1 text-white md:right-0 md:left-auto md:bottom-[53%]
            ${marginWhatsApp ? 'animation-buttonWhatsapp md:right-[25rem]'
      : 'md:right-6 animate__animated animate__bounceInRight'
    } ${hover && 'animation-shake'}`}
            color='#DF2F44'
            name='DogButton'
            onClick={() => {}}
            img={isSpanish ? dog : dogEn}
            sizeContainer={'w-[67px] h-[67px] md:w-[75px] md:h-[75px]'}
            onMouseLeave={() => setHover(false)}
            onMouseOver={() => setHover(true)}
          />
        </a>
      }
    </>
  );
};

export default ButtonWhatsap;
