import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconButton from '../../common/iconButton';
import SelectCountry from './SelectCountry';
import ShoppingCar from '../shoppingCar';
import Icon from '../../common/icon';
import { NavItem } from './navItem';
import Menu from './menu';

import { useAppContext } from '../../../hooks';

import { logoFooter, iconGlass, basketWhite, basketBlue, iconGlassWhite } from '../../../assets/vectors';
import { logo } from '../../../assets/images';
import PopupMenuUser from '../popupMenuUser';
import PopupMenuUserWholesaler from '../popupMenuUserWholesaler';
import Search from '../../common/search';
import '../../../styles/navbar.css';
import SelectStore from './SelectStore';

// eslint-disable-next-line complexity
export const NavBar = ({ backgroundColor }: NavBarProps) => {
  const { pathname } = useLocation();
  const itemsBlack = ['/detalle', '/canasta', '/pago', 'preguntas-frecuentes', '/terminos', '/politica-privacidad', '/combo', 'catalogo', '/suscripcion', '/pago/resultado', '/donde-comprar', '/citas'];
  const [showItemsBlack, setShowItemsBlack] = useState<boolean[]>(itemsBlack.map(rute => pathname.includes(rute)));
  const itemsLogoBlack: string[] = [];
  const showLogoBlack = itemsLogoBlack.map(rute => pathname.includes(rute));
  const pixie = showLogoBlack.includes(true) ? logoFooter : logo;

  // Hooks
  const navigate = useNavigate();
  const {
    products,
    showNavbar,
    updateContext,
    showMinimalNavbar,
    isAuthenticated,
    user,
    countrySelected: { name: countryName },
    isMobile,
    location
  } = useAppContext();
  const { t, i18n } = useTranslation();
  /// const [isDesktop, setIsDesktop] = useState(true);

  const [showShoppingCar, setShowShoppingCar] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean | null>(null);
  const [openSearch, setOpenSearch] = useState(false);
  const [openMenuUser, setOpenMenuUser] = useState(false);
  const [openMenuUserWholesaler, setOpenMenuUserWholesaler] = useState(false);
  const [bgIconColor, setBgIconColor] = useState('white');
  const [color, setColor] = useState((showItemsBlack.includes(true) || isMobile ? 'grayText' : 'white') || 'grayText');

  // Methods
  const toHome = () => navigate('/');
  /// useEffect(() => {
  //   changeResize();
  //   window.addEventListener('resize', changeResize);
  // }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setOpenMenuUser(false);
      setOpenMenuUserWholesaler(false);
    }

    return () => {};
  }, [isAuthenticated]);

  useEffect(() => {
    setShowItemsBlack(itemsBlack.map(rute => pathname.includes(rute)));
    setColor((itemsBlack.map(rute => pathname.includes(rute)) || isMobile ? 'grayText' : 'white') || 'grayText');
    setBgIconColor((itemsBlack.map(rute => pathname.includes(rute)) || isMobile ? 'grayText' : 'white'));
  }, [pathname, isMobile]);

  useEffect(() => {
    if (showMinimalNavbar) {
      setColor('grayText');
      setBgIconColor('grayText');
    } else {
      setColor((showItemsBlack.includes(true) || isMobile ? 'grayText' : 'white') || 'grayText');
      setBgIconColor(((showItemsBlack.includes(true) || isMobile) ? 'grayText' : 'white'));
    }

    return () => {};
  }, [showMinimalNavbar]);

  /// const changeResize = () => {
  //   if (screen.width < 800) setIsDesktop(false);
  //   else setIsDesktop(true);
  // };

  const toggleCollapsed = () => setCollapsed(old => !old);
  const toogleShoppingCar = () => {
    updateContext(old => ({ ...old, marginWhatsApp: !showShoppingCar }));
    setShowShoppingCar(!showShoppingCar);
  };

  const handleOpenLoginOrMenu = () => {
    // /const isNopMobile = !window.matchMedia('(max-width: 800px)').matches;
    const isUnDesktop = window.innerWidth > 800;

    if (isAuthenticated) setOpenMenuUser(!openMenuUser);
    else if (isUnDesktop)
      updateContext(old => ({
        ...old,
        showPopupAuth: {
          status: true,
          view: 1,
        },
        isLoginSubscription: false
      }));
    else navigate('/autenticacion/ingresar');
  };

  const handleOpenLoginOrMenuWholesaler = () => {
    // /const isNopMobile = !window.matchMedia('(max-width: 800px)').matches;
    const isUnDesktop = window.innerWidth > 800;

    if (isAuthenticated) setOpenMenuUserWholesaler(!openMenuUserWholesaler);
    else if (isUnDesktop)
      updateContext(old => ({
        ...old,
        showPopupAuthWholesaler: {
          status: true,
          view: 1,
        },
        isLoginSubscription: false
      }));
    else navigate('/mayoristas/login');
  };

  const switchLang = (lang: 'es' | 'en' = 'es') => {
    i18n.changeLanguage(lang);
    localStorage.setItem('languaje', lang);
  };

  const isMEX = countryName === 'MEX';

  if (!showNavbar) return <></>;

  const handleShowPopupSelectStore = () => {
    updateContext(old => ({ ...old, showPopupShippingCity: true }));
  };

  const navBarStyle = showMinimalNavbar
    ? 'animate__fadeInDown w-full bg-white py-1 px-2 lg:px-12 md:py-3 md:items-center'
    : `animate__fadeIn 
       ${backgroundColor ? backgroundColor : 'bg-transparent'}
       py-4 px-2 lg:px-12 md:pt-5 md:items-start max-w-[1440px]
      `;

  // Component
  return (
    <div className={`${showMinimalNavbar ? '' : 'max-w-[1440px]'} w-full relative `}>
      {/* Mobile Menu */}
      <Menu collapsed={collapsed} toggle={toggleCollapsed} />
      {/* User Menu */}

      {openMenuUser && <PopupMenuUser showMinimalNavbar={showMinimalNavbar} />}
      {openMenuUserWholesaler && <PopupMenuUserWholesaler showMinimalNavbar={showMinimalNavbar} />}

      {/* Cart */}
      <ShoppingCar onClose={toogleShoppingCar} show={showShoppingCar} />

      <div
        className={`
          fixed top-0 z-40 flex w-full
          justify-between items-center 
          animate__animated animate__faster
          overflow-hidden ${navBarStyle}
          md:justify-start md:gap-10
          ${color === 'white' && 'text-shadown-navbar'}
        `}
      >
        {/* Menu Toggle */}
        <div className={`flex-shrink-0 md:hidden w-[12.3%] md:w-auto text-${color}`}>
          <IconButton
            name='menu'
            size='3xl'
            shadow={false}
            onClick={toggleCollapsed}
            className='-scale-x-125 -scale-y-125'
          />
        </div>

        {/* Logo */}
        <div className='w-1/3 flex justify-center items-center md:w-auto'>
          <div
            className={`${
              showMinimalNavbar ? 'w-14 md:w-[55px]' : 'w-14 md:w-[6.75rem] md:pt-2'
            } flex-shrink-0 flex justify-center items-center md:justify-start `}
          >
            <img src={pixie} className='w-full h-full cursor-pointer' onClick={toHome} />
          </div>
        </div>

        {/* Content */}
        <div className='flex flex-col flex-grow-0 md:flex-grow'>
          {/* Top */}
          <div
            className={`w-full hidden text-${color} gap-[22px] ${showMinimalNavbar ? 'md:hidden' : 'md:block'}
            md:flex md:flex-row md:justify-end md:items-center md:pt-[0.20rem]`}
          >
            <SelectStore/>
            {/* {location !== 'USA' && <SelectStore/>} */}
            <SelectCountry color={color}/>
            <span className='cursor-pointer font-subTitles' onClick = {handleOpenLoginOrMenuWholesaler}
            >{t('loginWholesale')}</span>
            <div className='flex items-center justify-center text-sm'>
              <span
                className={`font-subTitles cursor-pointer ${i18n.language === 'en' && 'font-black'}`}
                onClick={() => switchLang('en')}
              >
                EN
              </span>
              <span className='font-extrabold font-subTitles'> &nbsp;|&nbsp;</span>
              <span
                className={`font-subTitles cursor-pointer ${i18n.language === 'es' && 'font-black'}`}
                onClick={() => switchLang()}
              >
                ES
              </span>
            </div>
          </div>

          {/* Bottom */}
          <div
            className={`${
              showMinimalNavbar ? 'md:pt-px' : 'md:pt-4'
            } flex flex-grow-0 items-top w-full md:flex-grow text-${color}  `}
          >
            {/* Social */}
            <div
              className={`${
                showMinimalNavbar ? 'md:items-center' : 'md:pt-[0.40rem]'
              } hidden md:flex md:flex-row md:gap-6 md:w-2/3 md:ml-4 `}
            >
              <a
                href={
                  isMEX
                    ? 'https://www.facebook.com/Pixiemx-104482775594223'
                    : 'https://www.facebook.com/Pixieus-101708272541954'
                }
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <Icon.awesome
                  className={` hover:text-red-500 hover:bg-white w-10 h-10 flex justify-center items-center
                  ${showItemsBlack.includes(true) || showMinimalNavbar ? 'text-grayText' : 'text-white'}
                  `}
                  icon='fa-facebook-f'
                  size={showMinimalNavbar ? '2xl' : '3xl'}
                />
              </a>
              <a
                href={isMEX ? 'https://www.instagram.com/pixie.mex' : 'https://www.instagram.com/pixie.us'}
                target='_blank'
                rel='noopener noreferrer'
                className='cursor-pointer'
              >
                <Icon.awesome
                  className={` hover:text-red-500  hover:bg-white w-10 h-10 flex justify-center items-center
                  ${showItemsBlack.includes(true) || showMinimalNavbar ? 'text-grayText' : 'text-white'}
                  `}
                  icon='fa-instagram'
                  size={showMinimalNavbar ? '2xl' : '3xl'}
                />
              </a>
            </div>

            {/* Items */}
            <div
              className={`
                w-1/3 flex-grow justify-end gap-2 md:flex md:flex-row md:w-auto lg:gap-6 
                ${!showMinimalNavbar && 'md:pt-1'}
              `}
            >
              {/* Navs */}
              {!openSearch && (
                <div
                  className={`${
                    showMinimalNavbar && 'md:items-center'
                  } hidden md:flex md:flex-row justify-between md:gap-7 md:mr-6`}
                >
                  <NavItem name={t('navCatalogue')} path='/catalogo' color={color} />
                  <NavItem name={t('navCalculator')} path='/calculadora' color={color} />
                  <NavItem name={t('navSubscriptions')} path='/suscripcion' color={color} />
                  <NavItem name={t('navWhereBuy')} path='/donde-comprar' color={color} />
                  <NavItem name={t('navAppointments')} path='/citas' color={color} />
                  {/* {location !== 'USA' &&
                    <NavItem name={t('navSubscriptions')} path='/suscripcion' color={color} />
                  } */}
                </div>
              )}
              {/* Buttons */}
              {!openSearch && (
                <div className='hidden md:flex md:flex-row md:justify-end'>
                  <IconButton.mini
                    img={showMinimalNavbar || showItemsBlack.includes(true) || isMobile ? iconGlassWhite : iconGlass}
                    name={iconGlass}
                    type='outlined'
                    imgClassName={`${showItemsBlack.includes(true) && 'w-8 h-8'} w-7 h-7`}
                    sizeContainer={'w-11 h-11 md:w-[42px] md:h-[42px]'}
                    className={`mr-4 md:mr-0 bg-${bgIconColor} p-[10px]`}
                    size='xs'
                    shadow={false}
                    onClick={() => setOpenSearch(!openSearch)}
                    /// color={showItemsBlack.includes(true) ? bgIconColor : undefined }
                  />
                </div>
              )}
              {openSearch && <Search className='hidden md:block text-grayText' />}

              <div className='flex-shrink-0 flex justify-end lg:mr-1'>
                {location !== 'USA' && (
                  <IconButton.mini
                    /// img={user?.user?.img ? user.user.img : happyFace }
                    // img={user?.user?.img || undefined}
                    name='pin_drop'
                    type='outlined'
                    imgClassName={`${
                      user?.user.img
                        ? 'object-cover w-full h-full rounded-full'
                        : `${showItemsBlack.includes(true) && 'w-8 h-8'} w-7 h-7`
                    }`}
                    sizeContainer={'w-11 h-11 md:w-[42px] md:h-[42px]'}
                    className={`mr-4 md:mr-0 bg-${bgIconColor} ${showMinimalNavbar || showItemsBlack.includes(true) || isMobile ? 'text-white' : 'text-pixieLightBlue'} cursor-pointer md:hidden`}
                    size='3xl'
                    shadow={false}
                    onClick={handleShowPopupSelectStore}
                    /// color={showItemsBlack.includes(true) ? bgIconColor : undefined}
                  />
                )}
                <div className='md:flex md:flex-row md:justify-end md:mr-6'>
                  <IconButton.mini
                    /// img={user?.user?.img ? user.user.img : happyFace }
                    img={user?.user?.img || undefined}
                    name='person'
                    type='round'
                    imgClassName={`${
                      user?.user.img
                        ? 'object-cover w-full h-full rounded-full'
                        : `${showItemsBlack.includes(true) && 'w-8 h-8'} w-7 h-7`
                    }`}
                    sizeContainer={'w-11 h-11 md:w-[42px] md:h-[42px]'}
                    className={`mr-4 md:mr-0 bg-${bgIconColor} ${showMinimalNavbar || showItemsBlack.includes(true) || isMobile ? 'text-white' : 'text-pixieLightBlue'} cursor-pointer`}
                    size='3xl'
                    shadow={false}
                    onClick={() => handleOpenLoginOrMenu()}
                    /// color={showItemsBlack.includes(true) ? bgIconColor : undefined}
                  />
                </div>
                <IconButton.mini
                  img={showMinimalNavbar || showItemsBlack.includes(true) || isMobile ? basketWhite : basketBlue}
                  name={basketBlue}
                  type='outlined'
                  imgClassName={`${showItemsBlack.includes(true) && 'w-8 h-8'} w-7 h-7`}
                  sizeContainer={'w-11 h-11 md:w-[42px] md:h-[42px]'}
                  className={`mr-4 md:mr-0 bg-${bgIconColor}`}
                  size='md'
                  shadow={false}
                  onClick={() => toogleShoppingCar()}
                  /// color={showItemsBlack.includes(true) ? bgIconColor : undefined}
                />
                {products.length > 0 && (
                  <div className='relative'>
                    <div
                      className={`flex w-5 h-5 font-subTitles text-xs rounded-full
                        absolute justify-center items-center text-white top-[1.8rem] right-4
                        md:-right-1 animate__animated animate__bounceIn
                        ${showItemsBlack.includes(true) ? 'bg-primary' : 'bg-primary'}
                        `}
                    >
                      {products.length}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface NavBarProps {
  backgroundColor?: string;
}

export default NavBar;
