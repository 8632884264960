import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/common/button';
import Icon from '../../../components/common/icon';
import IconButton from '../../../components/common/iconButton';
import { useAppContext } from '../../../hooks';

const HeaderSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showPopupAuth, updateContext } = useAppContext();

  const handleChangeView = () => {
    if (showPopupAuth.status)
      updateContext(old => ({ ...old, showPopupAuth: { status: old.showPopupAuth.status, view: 2 } }));
    else
      navigate('/autenticacion/registro');
  };

  const handleCloseAuth = () => {
    updateContext(old => ({ ...old, showPopupAuth: { status: false, view: 1 }, showNavbar: true }));
  };

  return (
    <div className='w-full flex flex-col gap-3 mt-3 z-10'>
      <div className='flex justify-start lg:justify-end lg:mr-5'>
        <IconButton.mini
          name='chevron_left'
          onClick={() => navigate(-1)}
          className='text-primary lg:hidden '
          size='3xl'
        />
        <IconButton.mini
          name='close'
          onClick={handleCloseAuth}
          className='hidden lg:block text-black'
          size='2xl'
        />
      </div>
      <div className='flex font-sanzSemiBold px-7 gap-4 items-center lg:px-24 lg:gap-6'>
        <span className='text-sm lg:text-lg'>{t('loginHasAccount')}</span>
        <Button className='bg-pixieLightBlue text-white flex gap-3 rounded-[16px] shadow-xl lg:rounded-[20px]' onClick={() => handleChangeView()}>
          <Icon
            name='person'
            type='round'
            className='first-line:mr-4 md:mr-0 text-white cursor-pointer'
            size='3xl'
          />
          <span className='text-lg'>{t('loginSignUp')}</span>
        </Button>
      </div>
    </div>
  );
};

export default HeaderSection;
