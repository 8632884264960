import { useState } from 'react';
import Icon from '../icon';

const ListInfo = ({ text, textClassName, textInfo }: ListIconProps) => {
  const [showinfo, setShowinfo] = useState(false);

  return (
    <li className='flex flex-col gap-3'>
      <div className='flex gap-3 w-full cursor-pointer' onClick={() => setShowinfo(!showinfo)}>
        <div className='w-[1.35rem] h-[1.35rem] bg-white rounded-full flex justify-center items-center lg:w-[1.90rem] lg:h-[1.90rem] lg:p-1'>
          <Icon name={showinfo ? 'horizontal_rule' : 'add'} className='text-pixieLightBlue'/>
        </div>
        <p className={`${textClassName ? textClassName : 'font-sanzBold text-[13.5px] lg:text-lg'}`}>{text}</p>
      </div>
      {showinfo &&
        <div className='ml-12 font-sanzSemiBold text-sm lg:text-base'>
          {textInfo}
        </div>
      }
    </li>
  );
};

interface ListIconProps {
  text: string,
  isIconComponent?: boolean,
  textInfo: string,
  textClassName?: string,
}

export default ListInfo;

