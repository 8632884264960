import React, { useState } from 'react';

import { Swiper, SwiperSlide, SwiperProps, useSwiper } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';

import IconButton from '../iconButton';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import '../../../styles/carrousel.css';
import { arrow } from '../../../assets/vectors';

export const Carrousel = ({
  children,
  className,
  slidesPerView = 1.25,
  spaceBetween = 25,
  bulletsDirection = 'horizontal',
  breakpoints,
  centeredSlides = true,
  bulletClassName,
  navigation = false,
  classNameArrow,
  autoplay,
  navInMobile = false,
  bulletActiveClass = 'bg-grayText',
  classNameArrowLeft,
  classNameArrowRight,
  onSlideChange = () => { },
}: CarrouselProps) => {
  // Hooks
  const swiperType = useSwiper();
  const [swiper, setSwiper] = useState<typeof swiperType>();

  // Handlers
  const swipeTo = (index: number) => {
    if (!swiper) return;

    if (index < 0) index = 0;
    else if (index > swiper.slides.length - 1) index = swiper.slides.length - 1;

    swiper.slideTo(index);
  };

  // Component
  return (
    <div className='w-full relative rounded-lg'>
      {/* Slider */}
      <Swiper
        className={`w-full rounded-lg relative ${className}`}
        centeredSlides={centeredSlides}
        draggable
        onSwiper={setSwiper}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        autoplay={autoplay}
        onSlideChange={onSlideChange ? swiper => onSlideChange(swiper.activeIndex) : undefined}
        modules={[Pagination, Navigation, Autoplay]}
        breakpoints={breakpoints}
        pagination={{
          clickable: true,
          modifierClass: `swiper-pagination-${bulletsDirection}`,
          bulletClass: `transition-all transform swiper-pagination-bullet ${bulletClassName} `,
          bulletActiveClass: `${bulletActiveClass}`,
        }}
      >
        {React.Children.map(children, (item, i) => (
          <SwiperSlide
            onClick={() => swipeTo(i)}
            className='flex items-center justify-center'
          >
            {item}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation */}
      {
        navigation &&
        <div className={`absolute w-full h-full top-0 left-0 ${navInMobile ? 'block' : 'hidden md:block'} ${classNameArrow && classNameArrow}`}>
          <IconButton.mini
            img={arrow}
            name={arrow}
            onClick={() => swipeTo((swiper?.realIndex || 0) - 1)}
            shadow={false}
            className={`absolute top-1/2 -left-20  ${classNameArrowLeft && classNameArrowLeft}`}
          />
          <IconButton.mini
            img={arrow}
            name={arrow}
            onClick={() => swipeTo((swiper?.realIndex || 0) + 1)}
            shadow={false}
            className={`absolute top-1/2 -right-20 rotate-180 ${classNameArrowRight && classNameArrowRight}`}
          />
        </div>
      }
    </div>
  );
};

interface CarrouselProps {
  children: React.ReactNode;
  className?: string;
  slidesPerView?: number;
  spaceBetween?: number;
  bulletClassName?: string;
  bulletsDirection?: 'vertical' | 'horizontal' | 'horizontal-banner';
  breakpoints?: SwiperProps['breakpoints'];
  centeredSlides?: boolean;
  navigation?: boolean;
  navInMobile?: boolean;
  classNameArrow?:string,
  classNameArrowLeft?: string;
  classNameArrowRight?: string;
  autoplay?: { delay: number },
  onSlideChange?: (slide: number) => void;
  bulletActiveClass?: string;
}

export default Carrousel;
