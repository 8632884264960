import { useTranslation } from 'react-i18next';
import IconButton from '../iconButton';
import Tooltiped from '../tooltiped';

const AddRemoveItem = ({ handleChance, counter, onlyOneNumber = false, onhandleChangeInput = () => {}, showAdd }: AddRemoveItemProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center rounded-3xl text-white font-sanzBold w-full h-full' style={{ backgroundColor: '#929292' }}>
      <IconButton.mini
        name='remove'
        sizeContainer='w-1/3 h-full'
        onClick={() => handleChance(-1)}
        className='shadow-none transform scale-75'
      />
      {onlyOneNumber
        ?
        <span className='text-base w-1/3 text-center'>
          1
        </span>
        :
        <input
          value={counter}
          type='number'
          className='w-1/3 bg-transparent text-center inputWithoutArrow'
          onChange={onhandleChangeInput}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        />
      }
      {showAdd ? (
        <IconButton.mini name='add' sizeContainer='w-1/3 h-full' onClick={() => handleChance(+1)} className='shadow-none transform scale-75' />
      ) :
        <Tooltiped
          label={t('productsNoMore')}
        >
          <div className='w-1/3 h-full text-center'>
            <IconButton.mini name='add' sizeContainer='w-full h-full' onClick={() => handleChance(+1)} className='shadow-none transform scale-75 opacity-50' />
          </div>
        </Tooltiped>
      }
    </div>
  );
};

interface AddRemoveItemProps {
  handleChance: (type: number) => void;
  counter? : number,
  onlyOneNumber?: boolean,
  onhandleChangeInput? : (e: React.ChangeEvent<HTMLInputElement>) => void
  showAdd: boolean
}

export default AddRemoveItem;
