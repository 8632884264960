import { backgroundLogin } from '../../../assets/images';
import FormLoginSection from './formLoginSection';
import HeaderSection from './HeaderSection';
import ImageLoginSection from './imageLoginSection';
import FooterSection from './endSection';
import useAuthWholesaler from '../../../hooks/useAuthWholesaler';
import { Navigate } from 'react-router-dom';

interface Props {
  classModal?: boolean;
}

const LoginWholesaler = ({ classModal }: Props) => {
  const { old: wholesalerToken } = useAuthWholesaler();

  if (wholesalerToken) return <Navigate to='/mayoristas/catalogo' replace />;

  return (
    <div className={ `w-full h-full bg-sixth ${classModal ? 'scroll-gray overflow-y-scroll' : ''}`}>
      <div className={`w-full h-full flex ${classModal ? '' : 'lg:h-screen'}`}>
        <div className='hidden flex-col lg:block lg:w-1/2'>
          <ImageLoginSection classModal/>
        </div>
        <div className='flex flex-col w-full lg:w-1/2 h-full relative'>
          <img
            src={backgroundLogin}
            alt={backgroundLogin}
            className={`absolute top-0 z-10 hidden lg:block w-full ${classModal ? 'h-full' : 'h-screen'}`}
          />
          <HeaderSection />
          <div className='px-[1.20rem] w-full lg:px-24 z-10'>
            <FormLoginSection/>
            <FooterSection/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWholesaler;
