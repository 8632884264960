import { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { SelectItem } from '../selectField';

interface Props {
  name: string;
  value: SelectItem | null;
  options: SelectItem[];
  loading?: boolean;
  required?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  messageError?: string;
  onChange: (e: SingleValue<SelectItem>) => void;
  onCreate: (e: string) => void;
  dropdownIndicatorColor?: string;
  backgroundColorOption?: string;
  colorText?: string;
}

export const InputSelect = ({
  name,
  value,
  options,
  loading = false,
  required = false,
  placeholder = '',
  isClearable = false,
  messageError,
  onChange,
  onCreate,
  dropdownIndicatorColor,
  backgroundColorOption,
  colorText
}: Props) => (
  <div className='flex flex-col gap-1 w-full'>
    <CreatableSelect
      name={name}
      className='w-full'
      value={value}
      options={options}
      isLoading={loading}
      required={required}
      isDisabled={loading}
      placeholder={placeholder}
      isClearable={isClearable}
      onChange={onChange}
      onCreateOption={onCreate}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: dropdownIndicatorColor ? dropdownIndicatorColor : '#33B5A9',
          primary: dropdownIndicatorColor ? dropdownIndicatorColor : '#33B5A9'
        },
      })}
      styles={{
        control: base => ({
          ...base,
          height: '3.125rem',
          color: '#616161',
          border: 'none',
          borderRadius: '2rem',
          paddingLeft: '6px',
        }),
        menu: base => ({ // Options Styles
          ...base,
          borderRadius: '15px',
          overflow: 'hidden',
        }),
        dropdownIndicator: base => ({ // Arrow styles
          ...base,
          color: dropdownIndicatorColor ? dropdownIndicatorColor : '#33B5A9',
          paddingRight: '12px',
        }),
        indicatorSeparator: base => ({ // Separator styles
          ...base,
          display: 'none',
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? backgroundColorOption ? backgroundColorOption : dropdownIndicatorColor : '',
          color: state.isFocused ? 'white' : 'black',
          '&:hover': {
            backgroundColor: backgroundColorOption ? backgroundColorOption : dropdownIndicatorColor,
            color: 'white'
          },
        }),
        singleValue: base => ({
          ...base,
          color: colorText ? colorText : 'black',
        }),
      }}
    />
    {messageError && (
      <p className='text-primary pl-2'>{messageError}</p>
    )}
  </div>
);
