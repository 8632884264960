import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import FadeScreen from '../components/layout/fadeScreen';

const ProfileWholesaler = lazy(() => import('../views/wholesaler/wholesalerProfile'));
const Favorite = lazy(() => import('../views/wholesaler/favorites'));
const OrdersHistory = lazy(() => import('../views/wholesaler/ordersHistory'));

const WholesalerUserRoutes = () => (
  <>
    <Routes>
      <Route path='/perfil/miPerfil' element={
        <Suspense fallback={<FadeScreen />}>
          <ProfileWholesaler />
        </Suspense>}
      />
      <Route path='/perfil/favoritos' element={
        <Suspense fallback={<FadeScreen />}>
          <Favorite />
        </Suspense>}
      />
      <Route path='/perfil/historial' element={
        <Suspense fallback={<FadeScreen />}>
          <OrdersHistory />
        </Suspense>}
      />
    </Routes>
  </>
);

export default WholesalerUserRoutes;
