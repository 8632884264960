export const userMenu = {
  userMenuMyProfile: 'Mi perfil',
  userMenuMyPets: 'Mis mascotas',
  userMenuSubscription: 'Suscripción',
  userMenuOrderHistory: 'Historial pedidos',
  userMenuFavorites: 'Favoritos',
  userMenuTestimonials: 'Reseñas',
  userMenuAppointments: 'Citas',
  userMenuLogOut: 'Cerrar Sesión'
};
