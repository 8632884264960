import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/common/button';

import { basketWhite, iconBasketSubscription, iconDurationPrice, iconShippingFrecuency, weightIconSubscription } from '../../assets/vectors';
import { useAppContext } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { ItemsHowSubscription } from '../../interfaces';

const BASE_URL = process.env.REACT_APP_URL_S3;

const StepSection = () => {
  const [openOptions, setOpenOptions] = useState(false);
  const { contentManeger, updateContext } = useAppContext();
  const navigate = useNavigate();
  const { t, i18n: { language } } = useTranslation();

  const dataSubIcons = useMemo(() => {
    const data = {
      stepOne: {
        icon: '',
        title: '',
        description: ''
      },
      stepTwo: {
        icon: '',
        title: '',
        description: ''
      },
      stepThree: {
        icon: '',
        title: '',
        description: ''
      },
      callToAction: ''
    };

    const sectionSub = contentManeger.find(section => section.key === 'subscriptions');
    if (!sectionSub) return data;
    const stepsSub = sectionSub.subsections.find(sub => sub.key === 'how_to_subscribe');
    if (!stepsSub) return data;
    const stepData = stepsSub?.items as ItemsHowSubscription;

    if (language === 'es') {
      data.stepOne = {
        icon: stepData?.icon1,
        title: stepData?.titleEs1,
        description: stepData?.descriptionEs1
      };
      data.stepTwo = {
        icon: stepData?.icon2,
        title: stepData?.titleEs2,
        description: stepData?.descriptionEs2
      };
      data.stepThree = {
        icon: stepData?.icon3,
        title: stepData?.titleEs3,
        description: stepData?.descriptionEs3
      };
      data.callToAction = stepData.callToActionEs;
    } else {
      data.stepOne = {
        icon: stepData?.icon1,
        title: stepData?.titleEn1,
        description: stepData?.descriptionEn1
      };
      data.stepTwo = {
        icon: stepData?.icon2,
        title: stepData?.titleEn2,
        description: stepData?.descriptionEn2
      };
      data.stepThree = {
        icon: stepData?.icon3,
        title: stepData?.titleEn3,
        description: stepData?.descriptionEn3
      };
      data.callToAction = stepData.callToActionEn;
    }

    return data;
  }, [contentManeger, language]);

  // Methods
  const handleSeeBasket = () => {
    updateContext(old => ({ ...old, isSelectProductSubscripiton: true }));
    navigate('/canasta');
  };

  return (
    <div className='bg-sixth w-full lg:flex lg:justify-center lg:items-center'>
      <div className='rounded-[29px] -mt-6 z-30 py-10 max-w-[1500px]'>
        <h3 className='text-xl lg:text-[30px]'>{t('subTitleForSubscribe')}</h3>
        <div className='flex flex-col gap-9 px-8 mt-14 lg:flex-row lg:items-center lg:justify-center lg:gap-2 lg:px-40'>
          <StepSectionItem
            icon={dataSubIcons.stepOne.icon ?
              `${BASE_URL}${dataSubIcons.stepOne.icon}` :
              iconDurationPrice
            }
            title={<p>{dataSubIcons.stepOne.title}</p>}
            description={<p>{dataSubIcons.stepOne.description}</p>}
          />
          <StepSectionItem
            icon={dataSubIcons.stepTwo.icon ?
              `${BASE_URL}${dataSubIcons.stepTwo.icon}` :
              iconShippingFrecuency
            }
            title={
              <p>{dataSubIcons.stepTwo.title}</p>
              /// <p>{t('subTitleFrequencyOne')} <span className='text-pixieLightBlue'>{t('subTitleFrequencyTwo')}</span> {t('subTitleFrequencyThree')}</p>
            }
            description={
              <p>{dataSubIcons.stepTwo.description}</p>
              /// <p>{t('subTextFrequencyOne')} <span className='font-sanzBold'>{t('subTextFrequencyTwo')}</span> {t('subTextFrequencyThree')}</p>
            }
          />
          <StepSectionItem
            icon={dataSubIcons.stepThree.icon ?
              `${BASE_URL}${dataSubIcons.stepThree.icon}` :
              iconBasketSubscription
            }
            title={
              <p>{dataSubIcons.stepThree.title}</p>
              /// <p>{t('subTitleQuantityOne')} <span className='text-pixieLightBlue'>{t('subTitleQuantityTwo')}</span></p>
            }
            description={
              <p>{dataSubIcons.stepThree.description}</p>
              /// <p>{t('subTextQuantityOne')}<span className='font-sanzBold'>{t('subTextQuantityTwo')}</span> {t('subTextQuantityThree')}</p>
            }
          />
        </div>

        {!openOptions &&
        <div className='w-full lg:flex'>
          <div className='lg:w-1/2'></div>
          <div className='lg:flex lg:justify-start lg:items-start lg:mt-6 lg:w-1/2 px-8 lg:px-0'>
            <Button
              padding='py-2 lg:px-32 lg:py-3'
              className='font-sanzSemiBold bg-pixieLightBlue text-white mt-4 w-full lg:w-auto'
              onClick={() => setOpenOptions(!openOptions)}
            >
              {dataSubIcons.callToAction}
              {/* {t('subTextBotonSubscribe')} */}
            </Button>
          </div>
        </div>
        }

        {openOptions &&
        <div className='w-full lg:flex'>
          <div className='lg:w-1/2'></div>
          <div className='px-8  mt-16 mb-8 lg:w-1/2'>
            <div className='flex flex-col gap-5 w-full lg:w-1/2'>
              <p className='text-center lg:text-xl'>{t('subTitleOptionSubscribe')}</p>
              <div className='flex items-center justify-center gap-7'>
                <Button
                  className='bg-[#FE9F60] flex flex-col items-center py-2 w-1/2 rounded-[20px] lg:gap-2 transform transition-all duration-300 hover:scale-125 cursor-pointer'
                  onClick={() => navigate('/calculadora')}
                >
                  <img src={weightIconSubscription} className='w-[3.5rem] h-[3.5rem]'/>
                  <span>{t('subTextOptionSubscribeCalculator')}</span>
                </Button>
                <Button
                  className='bg-[#A7C694] flex flex-col items-center py-2 w-1/2 rounded-[20px] lg:gap-2 transform transition-all duration-300 hover:scale-125 cursor-pointer'
                  onClick={handleSeeBasket}
                >
                  <img src={basketWhite} className='w-[3.5rem] h-[3.5rem] brightness-[0.31]'/>
                  <span>{t('subTextOptionSubscribeBasket')}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export const StepSectionItem = ({ description, icon, title, size, classNameTitle }: StepSectionItemProps) => (
  <div className='flex gap-3 lg:flex-col lg:justify-center lg:items-center w-full lg:w-1/3'>
    {/* circle */}
    <div className='w-2/6 flex items-center lg:justify-center'>
      <div className={`bg-[#D9EEEF] rounded-full flex justify-center items-center ${size ? size : 'w-[5.8rem] h-[5.8rem] p-4'}`}>
        <img src={icon}/>
      </div>
    </div>

    <div className={`flex flex-col font-subTitles w-4/6 gap-2 ${description ? '' : 'justify-center items-start'} lg:justify-center lg:items-center lg:gap-6`}>
      <div className='font-titles text-base lg:text-center lg:text-xl'>
        {title}
      </div>
      <div className='text-xs text-left lg:text-center lg:text-base font-sanzSemiBold'>
        {description}
      </div>
    </div>

  </div>
);

interface StepSectionItemProps {
  title: JSX.Element
  icon: string,
  description?: JSX.Element,
  size?: string,
  classNameTitle?: string
}

export default StepSection;
