import { SelectItem } from '../components/form/selectField';
import { FormCreateSubscription } from '../interfaces/subscription';

export const daysWeekSelects: SelectItem[] = [
  { label: 'Lunes', value: '1' },
  // { label: 'Martes', value: '2' },
  { label: 'Miercoles', value: '3' },
  // { label: 'Jueves', value: '4' },
  { label: 'Viernes', value: '5' }
];

export const initSendDataSubscription: FormCreateSubscription = {
  first_shipping_day: '',
  subscriptions_id: '',
  cellar_id: '',
  payment_exist_card: false,
  order_detail: {
    items: [],
    payer: {
      address: {
        street_name: '',
        street_number: 0,
        zip_code: ''
      },
      email: '',
      name: '',
      phone: {
        area_code: '',
        number: ''
      },
      surname: ''
    },
    auto_return: '',
    shipments: {
      cost: 0,
      mode: '',
      receiver_address: {
        apartment: '',
        city_name: '',
        country_name: '',
        floor: '',
        state_name: '',
        street_name: '',
        street_number: '',
        zip_code: ''
      }
    },
    metadata: {
      shippingDetails: {
        name: '',
        phone: '',
        address1: '',
        city: '',
        region: '',
        country: '',
        cityValue: '',
        regionvalue: '',
        countryValue: ''
      },
      customer_id: '',
      delivery_note: '',
      billingDetails: {
        address1: '',
        city: '',
        cityValue: '',
        country: '',
        countryValue: '',
        name: '',
        phone: '',
        region: '',
        regionvalue: ''
      },
      contactDetails: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      details_payments: {
        subTotalNoIva: 0,
        deliveryPrice: 0,
        onlyIva: 0,
        totalPayment: 0
      },
      products: [],
      cellar_id: ''
    },
    cellar_id: '',
    total_amount: 0,
    city_id: '',
    country_id: '',
    back_urls: {
      failure: `${process.env.REACT_APP_URL_BASE}pago/resultado`,
      pending: `${process.env.REACT_APP_URL_BASE}pago/resultado`,
      success: `${process.env.REACT_APP_URL_BASE}pago/resultado`,
    },
    payment_methods: {
      excluded_payment_types: [
        { id: 'ticket' },
        { id: 'bank_transfer' },
        { id: 'atm' }
      ],
    },
  },
  payer: {
    id: '',
    type: ''
  },
  shipping_frequencies_id: '',
  subscription: {
    created_at: '',
    days_subscriptions: 0,
    free_delivery: 0,
    free_delivery_us: 0,
    id: '',
    monts: 0,
    percen_discount: 0,
    status: 0,
    title: '',
    title_en: '',
    updated_at: ''
  },
  token: '',
  transaction_amount: 0,
  shippingPrice: { price: 0, type: 'estandar' },
  shippingNumbers: 1,
  minPriceShipping: 0,
  dayWeekSelect: { label: '', value: '' },
  shippingType: {
    description: '',
    description_en: '',
    id: '',
    title: '',
    title_en: '',
    value_days: 0,
  },
  card_data: {
    number: 4242424242424242,
    exp_month: 12,
    exp_year: 2024,
    cvc: 314
  },
};

