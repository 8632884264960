export const calculator = {
  calcHeader: 'Calculator, ideal ration for your pet',
  calcDescription: 'Pixie Calculator: Discover the ideal ration for your pet. Personalized nutrition for dogs and cats, tailored to their unique needs.',
  calcBannerFrag1: 'Change the way',
  calcBannerFrag2: 'your pet eats',
  calcBannerFrag3: '',
  calcBannerFrag4: 'Start here',

  calcBannerFrag1Mobile: 'Change',
  calcBannerFrag2Mobile: 'the way',
  calcBannerFrag3Mobile: 'your pet eats',
  calcBannerFrag4Mobile: '',
  calcBannerFrag5Mobile: 'Start here',

  calcBanner2Frag1: 'Your 4-legged friend',
  calcBanner2Frag2: 'is unique!',
  calcBanner2Frag3: 'That is why we offer 12 different options',
  calcBanner2Frag4: 'that satisfy every nutritional need',

  calcRecommended: 'Recommended:',
  calcNoRecomended: 'No recommendations',
  calcAgainButton: 'Calculate again',
  calcAddPet: 'Add pet',
  calcGetAppointment: 'Get free appointment',

  calcRecommendedFragUSA1: 'Pixies',
  calcRecommendedFragUSA2: 'will last ',
  calcRecommendedFragUSA3: 'Days ',

  calcRecommendedFrag1: 'Recommended diet for 4 weeks',
  calcRecommendedFrag2: 'Pixies',
  calcRecommendedFrag3: 'Combine them as you wish',
  calcRecommendedFrag4: 'Select and then add to cart.',

  calcSummary: 'Summary',
  calcSummaryButton: 'Add',
  calcSummaryButtonDT: 'Add to the Cart',
  calcSummaryButtonDTSubscribe: 'Subscribe and Save',

  calcFormError: 'There was an error in the calculation, please check the data.',
  calcFormErrorAdult: 'Wow, your friend has grown! If your pet is over 12 months old, he is already an adult.',
  calcFormErrorPuppy: 'Ouch! Pixie is designed for puppies from 2 months.',
  calcFormErrorSenior:
    'Woow, your friend has already reached his senior stage! If your dog is over 7 years old, he is already considered a senior',
  calcFormErrorSeniorCat:
    'Woow, your friend has already reached his senior stage! If your cat is over 7 years old, he is already considered a senior',

  calcFormErrorName: 'You must write a name',
  calcFormErrorType: 'You must select a pet type',
  calcFormErrorAge: 'You must specify an age',
  calcFormErrorExactAge: 'You must specify a valid age',
  calcFormErrorWeight: 'The weight must be grater than 0',
  calcFormErrorEx: 'You must select a physical activity',

  calcFormDog: 'dog',
  calcFormCat: 'cat',

  calcFormTitleFrag1: 'Pamper your',
  calcFormTitleFrag2: 'with our diet rich in protein, satisfying his nutritional needs.',

  calcFormMonths: 'months',
  calcFormYears: 'years',
  calcFormWeightTitle: 'Do you know your pets ideal weight?',
  calcFormWeightSubtitle: 'Take a look at our guide',
  calcFormTableCat: 'Cat weight chart',
  calcFormTableDog: 'Dog weight chart',

  // eslint-disable-next-line quotes
  calcFormLabelName: "What is your pet's name? *",
  calcFormLabelType: 'Your pet is a... *',
  calcFormLabelAge: 'Age',
  calcFormLabelExactAge: 'Exact age in',
  calcFormLabelWeight: 'Ideal weight in pounds  *',
  calcFormLabelEx: 'Physical activity *',
  calcFormLabelAlergiesPart1: 'Does',
  calcFormLabelAlergiesPart2: 'suffers with allergies or diseases? ',
  calcFormLabelYes: ' Yes',
  calcFormLabelHepatic: 'Hepatic',
  calcFormLabelRenal: 'Renal',
  calcFormLabelObesity: 'Obesity',
  calcFormLabelAlergy: 'Allergy',
  calcFormLabelStomach: 'Sensitive Stomach',
  calcFormLabelOther: 'Others',

  calcFormNextButton: 'Next',
  calcFormBackButton: 'Back',
  calcFormConsultButton: 'Calculate',
  calcFormPlaceholderSelec: 'Select',
  calcFormPlaceholderExactAge: 'Select',

  calcResultTitleFrag1: 'should eat a total of ',
  calcResultTitleFrag2: 'grams',
  calcResultTitleFrag3: 'a day divided into',
  calcResultTitleFrag4: 'portions!',
  calcResultErrorFrag1: 'You need',
  calcResultErrorFrag2: 'portions to complete 4 weeks',
  calcResultErrorUsa1: 'For ',
  calcResultErrorUsa2box: 'box ',
  calcResultErrorUsa2boxes: 'boxes ',
  calcResultErrorUsa3: 'of pixie will last ',
  calcResultErrorUsa4: 'days',
  calcResultMsgFrag1: 'Well you have selected',
  calcResultMsgFrag2: 'portions',
  calcResultPixieFor: 'Pixie box',
  calcResultPixieFor2: 'Pixie boxes',
  calcResultBox20: 'Build your box. Choose up to five different recipes (Box x20 rolls)',

  calcResultSelectToast: 'Select the desired quantity and add to the basket.',
  calcResultObesityToast:
    'These are the diets for obesity but we recommend taking nutritional advice with our veterinarians to evaluate your case.',
  calcResultOnePurchase: 'Total Purchase (One time only)',
  calcResultSubscription: 'Total Purchase (Suscription Plan)',
  calcProductAdded: 'Products added successfully',
  calcProductQuantityIncomplete: 'The warehouse does not have the required quantity, some products were added with the incomplete quantity',

};
