import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import appointmentService from '../../../../services/appointmentsService';
import PageUser from '../../../../components/layout/pageUser';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../components/common/iconButton';
import GeneralInfoAppointmentSection from './generalInfoAppointmentSection';
import { Appointment } from '../../../../interfaces/appointment';
import TabComponent from '../../../../components/common/tabComponent';
import PetAppointmentInfo from './petAppointmentInfo';

const AppointmentDocument = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useState<Appointment>();

  useEffect(() => {
    if (id)
      handleLoadData(id);
  }, [id]);

  const handleLoadData = (id: string) => {
    appointmentService.getOneAppointmentById(id).then(res => {
      setAppointmentData(res);
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <PageUser
      className='bg-[#D9EEEF] lg:bg-sixth'
      classNameChildren=' relative flex flex-col justify-center items-center pt-5 relative lg:pr-[5rem] lg:pl-[6rem] lg:bg-sixth'
      showBackgroundImage={false}
    >
      <div className='flex justify-between w-full items-center mb-5 lg:mb-0'>
        <div className='flex gap-4 items-center'>
          <IconButton name='arrow_back_ios' onClick={() => navigate(-1)} className='text-pixieLightBlue' shadow={false}/>
          <p className='font-titles text-pixieLightBlue text-2xl'>
            {t('myAppoInform')}
          </p>
        </div>
        <div className='hidden lg:flex border border-primary bg-white rounded-[20px] text-base lg:w-36 h-7 justify-center items-center text-primary font-subTitles'>
          <span>#{appointmentData?.short_id}</span>
        </div>
      </div>

      <div className='bg-sixth w-full rounded-t-[20px] py-7 px-5 flex flex-col gap-3 lg:gap-6 lg:py-5 '>
        <div className='flex justify-end'>
          <div className='flex lg:hidden border border-primary bg-white rounded-[20px] text-base w-36 h-7 justify-center items-center text-primary font-subTitles'>
            <span>#{appointmentData?.short_id}</span>
          </div>
        </div>
        {appointmentData && (
          <div className='flex flex-col gap-6'>
            <GeneralInfoAppointmentSection appointment={appointmentData}/>
            {appointmentData.pets.data.length > 1 ? (
              <TabComponent
                tabOptions={
                  appointmentData.pets.data.map(pet => ({ title: pet.name, value: pet.name, children: <PetAppointmentInfo appointment={appointmentData} petData={pet}/> }))
                }
              />
            ) : (
              <div className='font-subTitles'>
                <PetAppointmentInfo appointment={appointmentData} isOnePet petData={appointmentData.pets.data[0]}/>
              </div>
            )}
          </div>
        )}
      </div>
    </PageUser>
  );
};

export default AppointmentDocument;
