import { useTranslation } from 'react-i18next';
import { backgroundRegister, imageDogRegister, logo } from '../../../assets/images';

const ImageRegisterSection = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full flex flex-col items-center lg:pt-32 lg:gap-24 relative'>
      <img
        src={backgroundRegister} alt={backgroundRegister}
        className='absolute top-5 z-0 hidden lg:block'
      />
      <h2 className='hidden text-[#1D6226] lg:block lg2:text-[35px] lg:text-[30px] lg:px-14 lg2:px-28'>
        {t('LoginImagenText')}
      </h2>
      <div className='w-full h-full relative'>
        <img
          src={imageDogRegister}
          className='h-full w-full sm:w-[571px] lg:h-auto lg:w-full object-cover'
        />
      </div>
      <img
        src={logo}
        className='w-16 absolute top-[11rem] sm:top-[16.5rem] lg:w-20 lg:top-[47rem] z-20'
      />
    </div>
  );
};

export default ImageRegisterSection;
